import { Injectable, NgZone, Renderer2, RendererFactory2 } from '@angular/core';

import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';

import { PlatformService } from '../ssr/platform.service';
import { WindowService } from '../ssr/window.service';

// Export/restore if needed but we should try to rely on Ionic's
// default keyboard handling helpers to avoid having issues every
// time there's a new release.
const KEYBOARD_WILL_SHOW_EVENT = 'keyboardWillShow';
const KEYBOARD_WILL_HIDE_EVENT = 'keyboardWillHide';
// const KEYBOARD_DID_SHOW_EVENT = 'keyboardDidShow';
// const KEYBOARD_DID_HIDE_EVENT = 'keyboardDidHide';

export const KEYBOARD_SAFE_AREA_CLASS_NAME = 'without-safe-area-inset-bottom';
export const KEYBOARD_VIEW_SIZE_CLASS_NAME = 'ignore-keyboard-view-size-change';

@Injectable({ providedIn: 'root' })
export class KeyboardPlugin {
  private renderer: Renderer2;

  constructor(
    private ngZone: NgZone,
    private keyboard: Keyboard,
    private windowService: WindowService,
    private platformService: PlatformService,
    private rendererFactory: RendererFactory2,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  public initializeKeyboard(): void {
    if (this.platformService.isIos) {
      void this.keyboard.hideFormAccessoryBar(false);
    }

    if (this.windowService.isWindowDefined) {
      this.windowService.window.addEventListener(KEYBOARD_WILL_SHOW_EVENT, () => {
        this.ngZone.run(() => this.renderer.addClass(this.windowService.window.document.body, KEYBOARD_SAFE_AREA_CLASS_NAME));
      });

      this.windowService.window.addEventListener(KEYBOARD_WILL_HIDE_EVENT, () => {
        this.ngZone.run(() => this.renderer.removeClass(this.windowService.window.document.body, KEYBOARD_SAFE_AREA_CLASS_NAME));
      });
    }
  }

  public ignoreKeyboardViewChanges(): void {
    if (this.windowService.isWindowDefined) {
      this.windowService.window.document.getElementsByTagName('app-root')[0].classList.add(KEYBOARD_VIEW_SIZE_CLASS_NAME);
    }
  }

  public restoreKeyboardViewChanges(): void {
    if (this.windowService.isWindowDefined) {
      this.windowService.window.document.getElementsByTagName('app-root')[0].classList.remove(KEYBOARD_VIEW_SIZE_CLASS_NAME);
    }
  }

  public hideKeyboard(): void {
    this.keyboard.hide();
  }
}
