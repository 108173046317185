<ng-template #menuItemImage>
  <div #menuItemImageContainer class="item-image-container">
    <div class="item-image" [class.disabled]="isLoading">
      <swiper
        #swiper
        class="item-image-slider"
        (swiper)="onSwiperLoaded($event)"
        [pagination]="{ clickable: true }"
        [dir]="currentLanguageDirection"
      >
        <ng-template swiperSlide *ngFor="let imageUrl of vendorMenuItem.imageUrls">
          <app-animated-image tappable class="image" [imageUrl]="imageUrl" (click)="onOpenImagesPreviewModalPage()"></app-animated-image>
        </ng-template>
      </swiper>
    </div>

    <ng-container *ngIf="isBrowser">
      <div class="header-buttons-end">
        <ion-button
          tappable
          @animatedWishlistButton
          class="wishlist-button"
          *ngIf="!isLoading && !isUpdatingWishlistItem && isLoggedIn"
          (click)="onShowWishlists($event)"
        >
          <ion-icon src="/assets/custom-icons/shared/heart.svg"></ion-icon>
        </ion-button>
        <ion-button tappable *ngIf="!isLoading" @animatedShareButton class="share-button" (click)="onShare()">
          <ion-icon src="/assets/custom-icons/shared/share.svg"></ion-icon>
        </ion-button>
      </div>

      <ion-button tappable class="dismiss-button" (click)="onDismiss()">
        <ion-icon src="/assets/custom-icons/b-times.svg"></ion-icon>
      </ion-button>
    </ng-container>
  </div>

  <div
    tappable
    @animatedContent
    class="item-availability-status error"
    (click)="vendorMenuItem.supportNextAvailable && checkAvailability()"
    *ngIf="!isLoading && vendorMenuItem && vendorMenuItem.availability !== Availability.Available"
  >
    <p class="item-availability-status-message">
      <ng-container
        *ngIf="
          selectedDateTime &&
            (vendorMenuItem.availability === Availability.UnavailableFullyBooked ||
              vendorMenuItem.availability === Availability.UnavailableInsufficientNotice ||
              vendorMenuItem.availability === Availability.UnavailableServiceTime ||
              vendorMenuItem.availability === Availability.UnavailableUnspecified);
          else itemAvailabilityTextMessage
        "
      >
        {{
          "MENU_ITEM_DETAILS_PAGE.NOT_AVAILABLE_FOR_DATE_TIME"
            | translate
              : {
                  date: selectedDateTime | formatDateTime : { format: "ddd, MMM D", useRelativeDate: true },
                  time: selectedDateTime | formatDateTime : { format: "h:mm A", useRelativeDate: true }
                }
        }}
      </ng-container>

      <ng-template #itemAvailabilityTextMessage>
        {{ vendorMenuItem.availabilityText || ("UNAVAILABLE" | translate) }}
      </ng-template>

      <ng-container *ngIf="vendorMenuItem.supportNextAvailable">
        <br />
        <span [innerHtml]="'MENU_ITEM_DETAILS_PAGE.CHECK_AVAILABILITY_FOR_SUITABLE_DATE_TIME' | translate"> </span>
      </ng-container>
    </p>
  </div>

  <div
    @animatedContent
    class="item-availability-status success"
    *ngIf="!isLoading && vendorMenuItem && showAvailabilityMessage && vendorMenuItem.availability === Availability.Available"
  >
    <p class="item-availability-status-message">
      <ng-container>
        {{
          "MENU_ITEM_DETAILS_PAGE.AVAILABLE_FOR_DATE_TIME"
            | translate
              : {
                  date: selectedDateTime | formatDateTime : { format: "ddd, MMM D", useRelativeDate: true },
                  time: selectedDateTime | formatDateTime : { format: "h:mm A", useRelativeDate: true }
                }
        }}
      </ng-container>
    </p>
    <ion-icon class="item-availability-status-icon" src="/assets/custom-icons/b-check-circle-solid.svg"></ion-icon>
  </div>
</ng-template>

<ng-template #menuItemRequirements>
  <div class="item-header">
    <p class="item-name">{{ menuItemDetails.name }}</p>
    <p class="item-description">{{ menuItemDetails.briefDescription }}</p>

    <div class="item-requirements" *ngIf="!!requirements?.length">
      <div class="requirements">
        <div
          tappable
          class="requirement"
          (click)="!!requirement.callToAction && requirement.callToAction()"
          *ngFor="let requirement of requirements"
        >
          <ion-icon class="requirement-icon" [src]="'/assets/custom-icons/item-details-modal/' + requirement.iconName + '.svg'"></ion-icon>
          <div class="requirement-details">
            <div class="requirement-text">
              {{ requirement.text }}
              <span *ngIf="!!requirement.callToAction" class="requirement-text-view-more">({{ "VIEW_MORE" | translate | lowercase }})</span>
            </div>
            <div class="requirement-secondary-text">{{ requirement.secondaryText }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="section-separator"></div>
  </div>
</ng-template>

<ng-template #menuItemDescription>
  <div class="item-content-section item-description" *ngIf="fullDescriptionFormatted" data-cy="item-description">
    <span [innerHtml]="fullDescriptionFormatted"></span>
  </div>
</ng-template>

<ng-template #menuItemOptions let-optionsGroups="optionsGroups" let-collapseOptions="collapseOptions">
  <div class="item-content-section item-options" [class.collapsable]="collapseOptions" *ngIf="!!optionsGroups && !!optionsGroups.length">
    <div
      *ngFor="let menuItemOptionGroup of optionsGroups; trackBy: identifyOptionGroup"
      class="item-options-group"
      [class.max-selected]="
        menuItemOptionGroup.maximumSelection !== 0 &&
        menuItemOptionGroup.selectedOptionsCount ===
          (menuItemOptionGroup.maximumSelection
            | adjustBasedOnQuantity : currentQuantity : menuItemDetails.pricingMethod : menuItemOptionGroup.input)
      "
    >
      <!-- Option group title-->
      <div
        tappable
        class="item-options-title-wrapper"
        [class.with-icon]="collapseOptions"
        (click)="collapseOptions && onToggleAnimatedObject(menuItemOptionGroup)"
      >
        <div>
          <div data-cy="item-options-title" class="item-options-title">{{ menuItemOptionGroup.name }}</div>

          <div *ngIf="!collapseOptions && currentQuantity !== 0" data-cy="item-options-description" class="item-options-description">
            <!--
              Important: Since we may want to update these translations AFTER releasing this feature, let's
              send as many info as we can to the translation (selectedQuantity, minQuantity, maxQuantity, etc)
              even if it's not used right now to be as flexible as possible.

              Possible scenarios:
              01) MIN === 0 and MAX === 0 => MIN_ZERO_MAX_ZERO
              02) MIN === 0 and MAX > 0 && SELECTED === 0 => MIN_ZERO_MAX_X_NOT_FULFILLED_OPTION or MIN_ZERO_MAX_X_NOT_FULFILLED_OPTIONS
              03) MIN === 0 and MAX > 0 && SELECTED > 0 && SELECTED < MAX => MIN_ZERO_MAX_X_NOT_FULFILLED_MORE_OPTION or MIN_ZERO_MAX_X_NOT_FULFILLED_MORE_OPTIONS
              04) MIN === 0 and MAX > 0 && SELECTED > 0 && SELECTED === MAX => MIN_ZERO_MAX_X_FULFILLED_OPTION or MIN_ZERO_MAX_X_FULFILLED_OPTIONS                          
              05) MIN > 0 and MAX === 0 && SELECTED === 0 => MIN_X_MAX_ZERO_NOT_FULFILLED_OPTION or MIN_X_MAX_ZERO_NOT_FULFILLED_OPTIONS
              06) MIN > 0 and MAX === 0 && SELECTED > 0 && SELECTED < MIN => MIN_X_MAX_ZERO_NOT_FULFILLED_MORE_OPTION or MIN_X_MAX_ZERO_NOT_FULFILLED_MORE_OPTIONS
              07) MIN > 0 and MAX === 0 && SELECTED > 0 && SELECTED >= MIN => MIN_X_MAX_ZERO_FULFILLED_OPTION or MIN_X_MAX_ZERO_FULFILLED_OPTIONS            
              08) MIN > 0 && MAX > 0 && MIN === MAX && SELECTED === 0 => MIN_X_MAX_X_NOT_FULFILLED_OPTION or MIN_X_MAX_X_NOT_FULFILLED_OPTIONS
              09) MIN > 0 && MAX > 0 && MIN === MAX && SELECTED > 0 && SELECTED < MIN => MIN_X_MAX_X_NOT_FULFILLED_MORE_OPTION or MIN_X_MAX_X_NOT_FULFILLED_MORE_OPTIONS
              10) MIN > 0 && MAX > 0 && MIN === MAX && SELECTED > 0 && SELECTED === MIN => MIN_X_MAX_X_FULFILLED_OPTION or MIN_X_MAX_X_FULFILLED_OPTIONS
              11) MIN > 0 && MAX > 0 && MIN !== MAX && SELECTED === 0 => MIN_X_MAX_Y_MIN_NOT_FULFILLED_OPTIONS
              12) MIN > 0 && MAX > 0 && MIN !== MAX && SELECTED > 0 && SELECTED < MIN => MIN_X_MAX_Y_MIN_NOT_FULFILLED_MORE_OPTION or MIN_X_MAX_Y_MIN_NOT_FULFILLED_MORE_OPTIONS
              13) MIN > 0 && MAX > 0 && MIN !== MAX && SELECTED > 0 && SELECTED >= MIN && SELECTED < MAX => MIN_X_MAX_Y_MIN_FULFILLED_MAX_NOT_FULFILLED_OPTION or MIN_X_MAX_Y_MIN_FULFILLED_MAX_NOT_FULFILLED_OPTIONS
              14) MIN > 0 && MAX > 0 && MIN !== MAX && SELECTED > 0 && SELECTED >= MIN && SELECTED === MAX => MIN_X_MAX_Y_MIN_AND_MAX_FULFILLED_OPTIONS
            -->

            <ng-container *ngIf="!menuItemOptionGroup.minimumSelection">
              <!-- 01) MIN === 0 and MAX === 0 => MIN_ZERO_MAX_ZERO -->
              <span *ngIf="!menuItemOptionGroup.maximumSelection">
                {{
                  "MENU_ITEM_DETAILS_PAGE.MIN_ZERO_MAX_ZERO" | translate : { selectedQuantity: menuItemOptionGroup.selectedOptionsCount }
                }}
              </span>

              <ng-container
                *ngIf="
                  !!menuItemOptionGroup.maximumSelection &&
                  (menuItemOptionGroup.maximumSelection
                    | adjustBasedOnQuantity
                      : currentQuantity
                      : menuItemDetails.pricingMethod
                      : menuItemOptionGroup.input) as maximumSelectionAdjusted
                "
              >
                <!-- 02) MIN === 0 and MAX > 0 && SELECTED === 0 => MIN_ZERO_MAX_X_NOT_FULFILLED_OPTION or MIN_ZERO_MAX_X_NOT_FULFILLED_OPTIONS -->
                <span *ngIf="!menuItemOptionGroup.selectedOptionsCount">
                  {{
                    (maximumSelectionAdjusted === 1
                      ? "MENU_ITEM_DETAILS_PAGE.MIN_ZERO_MAX_X_NOT_FULFILLED_OPTION"
                      : "MENU_ITEM_DETAILS_PAGE.MIN_ZERO_MAX_X_NOT_FULFILLED_OPTIONS"
                    ) | translate : { maxQuantity: maximumSelectionAdjusted }
                  }}
                </span>

                <ng-container *ngIf="!!menuItemOptionGroup.selectedOptionsCount">
                  <!-- 03) MIN === 0 and MAX > 0 && SELECTED > 0 && SELECTED < MAX => MIN_ZERO_MAX_X_NOT_FULFILLED_MORE_OPTION or MIN_ZERO_MAX_X_NOT_FULFILLED_MORE_OPTIONS -->
                  <span *ngIf="menuItemOptionGroup.selectedOptionsCount < maximumSelectionAdjusted">
                    {{
                      (maximumSelectionAdjusted - menuItemOptionGroup.selectedOptionsCount === 1
                        ? "MENU_ITEM_DETAILS_PAGE.MIN_ZERO_MAX_X_NOT_FULFILLED_MORE_OPTION"
                        : "MENU_ITEM_DETAILS_PAGE.MIN_ZERO_MAX_X_NOT_FULFILLED_MORE_OPTIONS"
                      )
                        | translate
                          : {
                              selectedQuantity: menuItemOptionGroup.selectedOptionsCount,
                              maxQuantity: maximumSelectionAdjusted,
                              remainingToMaxQuantity: maximumSelectionAdjusted - menuItemOptionGroup.selectedOptionsCount
                            }
                    }}
                  </span>

                  <!-- 04) MIN === 0 and MAX > 0 && SELECTED > 0 && SELECTED === MAX => MIN_ZERO_MAX_X_FULFILLED_OPTION or MIN_ZERO_MAX_X_FULFILLED_OPTIONS -->
                  <span *ngIf="menuItemOptionGroup.selectedOptionsCount === maximumSelectionAdjusted">
                    {{
                      (maximumSelectionAdjusted === 1
                        ? "MENU_ITEM_DETAILS_PAGE.MIN_ZERO_MAX_X_FULFILLED_OPTION"
                        : "MENU_ITEM_DETAILS_PAGE.MIN_ZERO_MAX_X_FULFILLED_OPTIONS"
                      ) | translate : { maxQuantity: maximumSelectionAdjusted }
                    }}
                  </span>
                </ng-container>
              </ng-container>
            </ng-container>

            <ng-container
              *ngIf="
                !!menuItemOptionGroup.minimumSelection &&
                (menuItemOptionGroup.minimumSelection
                  | adjustBasedOnQuantity
                    : currentQuantity
                    : menuItemDetails.pricingMethod
                    : menuItemOptionGroup.input) as minimumSelectionAdjusted
              "
            >
              <ng-container *ngIf="!menuItemOptionGroup.maximumSelection">
                <!-- 05) MIN > 0 and MAX === 0 && SELECTED === 0 => MIN_X_MAX_ZERO_NOT_FULFILLED_OPTION or MIN_X_MAX_ZERO_NOT_FULFILLED_OPTIONS -->
                <span *ngIf="!menuItemOptionGroup.selectedOptionsCount">
                  {{
                    (minimumSelectionAdjusted === 1
                      ? "MENU_ITEM_DETAILS_PAGE.MIN_X_MAX_ZERO_NOT_FULFILLED_OPTION"
                      : "MENU_ITEM_DETAILS_PAGE.MIN_X_MAX_ZERO_NOT_FULFILLED_OPTIONS"
                    ) | translate : { minQuantity: minimumSelectionAdjusted }
                  }}
                </span>

                <ng-container *ngIf="!!menuItemOptionGroup.selectedOptionsCount">
                  <!-- 06) MIN > 0 and MAX === 0 && SELECTED > 0 && SELECTED < MIN => MIN_X_MAX_ZERO_NOT_FULFILLED_MORE_OPTION or MIN_X_MAX_ZERO_NOT_FULFILLED_MORE_OPTIONS -->
                  <span *ngIf="menuItemOptionGroup.selectedOptionsCount < minimumSelectionAdjusted">
                    {{
                      (minimumSelectionAdjusted - menuItemOptionGroup.selectedOptionsCount === 1
                        ? "MENU_ITEM_DETAILS_PAGE.MIN_X_MAX_ZERO_NOT_FULFILLED_MORE_OPTION"
                        : "MENU_ITEM_DETAILS_PAGE.MIN_X_MAX_ZERO_NOT_FULFILLED_MORE_OPTIONS"
                      )
                        | translate
                          : {
                              selectedQuantity: menuItemOptionGroup.selectedOptionsCount,
                              minQuantity: minimumSelectionAdjusted,
                              remainingToMinQuantity: minimumSelectionAdjusted - menuItemOptionGroup.selectedOptionsCount
                            }
                    }}
                  </span>

                  <!-- 07) MIN > 0 and MAX === 0 && SELECTED > 0 && SELECTED >= MIN => MIN_X_MAX_ZERO_FULFILLED_OPTION or MIN_X_MAX_ZERO_FULFILLED_OPTIONS -->
                  <span *ngIf="menuItemOptionGroup.selectedOptionsCount >= minimumSelectionAdjusted">
                    {{
                      (minimumSelectionAdjusted - menuItemOptionGroup.selectedOptionsCount === 1
                        ? "MENU_ITEM_DETAILS_PAGE.MIN_X_MAX_ZERO_FULFILLED_OPTION"
                        : "MENU_ITEM_DETAILS_PAGE.MIN_X_MAX_ZERO_FULFILLED_OPTIONS"
                      )
                        | translate
                          : {
                              selectedQuantity: menuItemOptionGroup.selectedOptionsCount,
                              minQuantity: minimumSelectionAdjusted,
                            }
                    }}
                  </span>
                </ng-container>
              </ng-container>

              <ng-container
                *ngIf="
                  !!menuItemOptionGroup.maximumSelection &&
                  (menuItemOptionGroup.maximumSelection
                    | adjustBasedOnQuantity
                      : currentQuantity
                      : menuItemDetails.pricingMethod
                      : menuItemOptionGroup.input) as maximumSelectionAdjusted
                "
              >
                <ng-container *ngIf="minimumSelectionAdjusted === maximumSelectionAdjusted">
                  <!-- 08) MIN > 0 && MAX > 0 && MIN === MAX && SELECTED === 0 => MIN_X_MAX_X_NOT_FULFILLED_OPTION or MIN_X_MAX_X_NOT_FULFILLED_OPTIONS -->
                  <span *ngIf="!menuItemOptionGroup.selectedOptionsCount">
                    {{
                      (minimumSelectionAdjusted === 1
                        ? "MENU_ITEM_DETAILS_PAGE.MIN_X_MAX_X_NOT_FULFILLED_OPTION"
                        : "MENU_ITEM_DETAILS_PAGE.MIN_X_MAX_X_NOT_FULFILLED_OPTIONS"
                      )
                        | translate
                          : {
                              minQuantity: minimumSelectionAdjusted,
                              maxQuantity: maximumSelectionAdjusted
                            }
                    }}
                  </span>

                  <ng-container *ngIf="!!menuItemOptionGroup.selectedOptionsCount">
                    <!-- 09) MIN > 0 && MAX > 0 && MIN === MAX && SELECTED > 0 && SELECTED < MIN => MIN_X_MAX_X_NOT_FULFILLED_MORE_OPTION or MIN_X_MAX_X_NOT_FULFILLED_MORE_OPTIONS -->
                    <span *ngIf="menuItemOptionGroup.selectedOptionsCount < minimumSelectionAdjusted">
                      {{
                        (minimumSelectionAdjusted - menuItemOptionGroup.selectedOptionsCount === 1
                          ? "MENU_ITEM_DETAILS_PAGE.MIN_X_MAX_X_NOT_FULFILLED_MORE_OPTION"
                          : "MENU_ITEM_DETAILS_PAGE.MIN_X_MAX_X_NOT_FULFILLED_MORE_OPTIONS"
                        )
                          | translate
                            : {
                                minQuantity: minimumSelectionAdjusted,
                                maxQuantity: maximumSelectionAdjusted,
                                selectedQuantity: menuItemOptionGroup.selectedOptionsCount,
                                remainingToMinQuantity: minimumSelectionAdjusted - menuItemOptionGroup.selectedOptionsCount,
                                remainingToMaxQuantity: maximumSelectionAdjusted - menuItemOptionGroup.selectedOptionsCount
                              }
                      }}
                    </span>

                    <!-- 10) MIN > 0 && MAX > 0 && MIN === MAX && SELECTED > 0 && SELECTED === MIN => MIN_X_MAX_X_FULFILLED_OPTION or MIN_X_MAX_X_FULFILLED_OPTIONS -->
                    <span *ngIf="menuItemOptionGroup.selectedOptionsCount === minimumSelectionAdjusted">
                      {{
                        (menuItemOptionGroup.selectedOptionsCount === 1
                          ? "MENU_ITEM_DETAILS_PAGE.MIN_X_MAX_X_FULFILLED_OPTION"
                          : "MENU_ITEM_DETAILS_PAGE.MIN_X_MAX_X_FULFILLED_OPTIONS"
                        )
                          | translate
                            : {
                                minQuantity: minimumSelectionAdjusted,
                                maxQuantity: maximumSelectionAdjusted,
                                selectedQuantity: menuItemOptionGroup.selectedOptionsCount
                              }
                      }}
                    </span>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="minimumSelectionAdjusted !== maximumSelectionAdjusted">
                  <!-- 11) MIN > 0 && MAX > 0 && MIN !== MAX && SELECTED === 0 => MIN_X_MAX_Y_MIN_NOT_FULFILLED_OPTIONS -->
                  <span *ngIf="!menuItemOptionGroup.selectedOptionsCount">
                    {{
                      "MENU_ITEM_DETAILS_PAGE.MIN_X_MAX_Y_MIN_NOT_FULFILLED_OPTIONS"
                        | translate
                          : {
                              minQuantity: minimumSelectionAdjusted,
                              maxQuantity: maximumSelectionAdjusted
                            }
                    }}
                  </span>

                  <ng-container *ngIf="!!menuItemOptionGroup.selectedOptionsCount">
                    <!-- 12) MIN > 0 && MAX > 0 && MIN !== MAX && SELECTED > 0 && SELECTED < MIN => MIN_X_MAX_Y_MIN_NOT_FULFILLED_MORE_OPTION or MIN_X_MAX_Y_MIN_NOT_FULFILLED_MORE_OPTIONS -->
                    <span *ngIf="menuItemOptionGroup.selectedOptionsCount < minimumSelectionAdjusted">
                      {{
                        (minimumSelectionAdjusted - menuItemOptionGroup.selectedOptionsCount === 1
                          ? "MENU_ITEM_DETAILS_PAGE.MIN_X_MAX_Y_MIN_NOT_FULFILLED_MORE_OPTION"
                          : "MENU_ITEM_DETAILS_PAGE.MIN_X_MAX_Y_MIN_NOT_FULFILLED_MORE_OPTIONS"
                        )
                          | translate
                            : {
                                minQuantity: minimumSelectionAdjusted,
                                maxQuantity: maximumSelectionAdjusted,
                                selectedQuantity: menuItemOptionGroup.selectedOptionsCount,
                                remainingToMinQuantity: minimumSelectionAdjusted - menuItemOptionGroup.selectedOptionsCount,
                                remainingToMaxQuantity: maximumSelectionAdjusted - menuItemOptionGroup.selectedOptionsCount
                              }
                      }}
                    </span>

                    <!-- 13) MIN > 0 && MAX > 0 && MIN !== MAX && SELECTED > 0 && SELECTED >= MIN && SELECTED < MAX => MIN_X_MAX_Y_MIN_FULFILLED_MAX_NOT_FULFILLED_OPTION or MIN_X_MAX_Y_MIN_FULFILLED_MAX_NOT_FULFILLED_OPTIONS -->
                    <span
                      *ngIf="
                        menuItemOptionGroup.selectedOptionsCount >= minimumSelectionAdjusted &&
                        menuItemOptionGroup.selectedOptionsCount < maximumSelectionAdjusted
                      "
                    >
                      {{
                        (maximumSelectionAdjusted - menuItemOptionGroup.selectedOptionsCount === 1
                          ? "MENU_ITEM_DETAILS_PAGE.MIN_X_MAX_Y_MIN_FULFILLED_MAX_NOT_FULFILLED_OPTION"
                          : "MENU_ITEM_DETAILS_PAGE.MIN_X_MAX_Y_MIN_FULFILLED_MAX_NOT_FULFILLED_OPTIONS"
                        )
                          | translate
                            : {
                                minQuantity: minimumSelectionAdjusted,
                                maxQuantity: maximumSelectionAdjusted,
                                selectedQuantity: menuItemOptionGroup.selectedOptionsCount,
                                remainingToMaxQuantity: maximumSelectionAdjusted - menuItemOptionGroup.selectedOptionsCount
                              }
                      }}
                    </span>

                    <!-- 14) MIN > 0 && MAX > 0 && MIN !== MAX && SELECTED > 0 && SELECTED >= MIN && SELECTED === MAX => MIN_X_MAX_Y_MIN_AND_MAX_FULFILLED_OPTIONS -->
                    <span
                      *ngIf="
                        menuItemOptionGroup.selectedOptionsCount >= minimumSelectionAdjusted &&
                        menuItemOptionGroup.selectedOptionsCount === maximumSelectionAdjusted
                      "
                    >
                      {{
                        "MENU_ITEM_DETAILS_PAGE.MIN_X_MAX_Y_MIN_AND_MAX_FULFILLED_OPTIONS"
                          | translate
                            : {
                                minQuantity: minimumSelectionAdjusted,
                                maxQuantity: maximumSelectionAdjusted,
                                selectedQuantity: menuItemOptionGroup.selectedOptionsCount
                              }
                      }}
                    </span>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>

        <ion-icon
          *ngIf="collapseOptions"
          class="item-addons-icon"
          src="/assets/custom-icons/b-angle-right.svg"
          [class.icon-rotate]="menuItemOptionGroup.animationState === AnimationState.Expanded"
        ></ion-icon>

        <div
          class="item-options-badge"
          [class.optional]="!menuItemOptionGroup.minimumSelection"
          [class.required]="!!menuItemOptionGroup.minimumSelection"
          *ngIf="!collapseOptions"
        >
          <div class="item-options-badge-text" data-cy="item-options-badge-text">
            {{
              (!!menuItemOptionGroup.minimumSelection
                ? "MENU_ITEM_DETAILS_PAGE.GROUP_BADGE_REQUIRED"
                : "MENU_ITEM_DETAILS_PAGE.GROUP_BADGE_OPTIONAL"
              ) | translate
            }}
          </div>
          <div
            @animagedGroupBadgeIcon
            class="item-options-badge-icon-container"
            data-cy="item-options-badge-icon"
            *ngIf="
              !!menuItemOptionGroup.minimumSelection
                ? menuItemOptionGroup.selectedOptionsCount >=
                  (menuItemOptionGroup.minimumSelection
                    | adjustBasedOnQuantity : currentQuantity : menuItemDetails.pricingMethod : menuItemOptionGroup.input)
                : !!menuItemOptionGroup.selectedOptionsCount
            "
          >
            <ion-icon class="item-options-badge-icon" name="checkmark-circle-outline"></ion-icon>
          </div>
        </div>
      </div>
      <div class="item-options-content" [@animatedOptionGroupContent]="menuItemOptionGroup.animationState" style="overflow: hidden">
        <!-- Options are quantity selectors-->
        <ng-container *ngIf="menuItemOptionGroup.input === SelectionInput.PlusMinusSelector">
          <ng-container *ngFor="let option of menuItemOptionGroup.options; trackBy: identifyOption; let optionIndex = index">
            <!-- The option is actually a title -->
            <div *ngIf="option.isTitle" class="item-options-subtitle" data-cy="option-header">{{ option.name }}</div>

            <!-- The option is a real option and not a title -->
            <div
              *ngIf="!option.isTitle"
              data-cy="option-plus-minor-selector"
              class="item-option plus-minus-selector"
              [class.first-option]="optionIndex === 0"
              [class.last-option]="optionIndex === menuItemOptionGroup.options.length - 1"
            >
              <div class="option-name-wrapper">
                <div class="option-name" data-cy="option-name">{{ option.name }}</div>

                <div *ngIf="option.price" class="option-price-wrapper">
                  <div class="option-price">+{{ option.price | localCurrency }}</div>
                  <div *ngIf="option.onSale" class="option-retail-price">{{ option.retailPrice | localCurrency }}</div>
                </div>
              </div>

              <div class="option-quantity-selector">
                <ion-button
                  class="option-quantity-selector-button decrement-button"
                  [class.disabled]="option.quantity === 0"
                  data-cy="decrement-option-quantity-button"
                  (click)="onDecrementMenuItemOption(menuItemOptionGroup, option)"
                >
                  <ion-icon
                    slot="icon-only"
                    class="option-quantity-selector-button-icon"
                    src="/assets/custom-icons/item-details-modal/decrement-secondary.svg"
                  ></ion-icon>
                </ion-button>

                <span class="option-quantity-selector-text" data-cy="option-quantity">{{ option.quantity }}</span>

                <ion-button
                  class="option-quantity-selector-button increment-button"
                  data-cy="increment-option-quantity-button"
                  (click)="onIncrementMenuItemOption(menuItemOptionGroup, option)"
                >
                  <ion-icon
                    slot="icon-only"
                    class="option-quantity-selector-button-icon"
                    src="/assets/custom-icons/item-details-modal/increment-secondary.svg"
                  ></ion-icon>
                </ion-button>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <!-- Options are checkboxes -->
        <ng-container
          *ngIf="menuItemOptionGroup.input === SelectionInput.CheckBox || menuItemOptionGroup.input === SelectionInput.RadioButton"
        >
          <ng-container *ngFor="let option of menuItemOptionGroup.options; trackBy: identifyOption; let optionIndex = index">
            <!-- The option is actually a title -->
            <div *ngIf="option.isTitle" class="item-options-subtitle" data-cy="option-header">{{ option.name }}</div>

            <!-- The option is a real option and not a title -->
            <div
              *ngIf="!option.isTitle"
              data-cy="option-checkbox"
              class="item-option checkbox"
              [class.first-option]="optionIndex === 0"
              [class.last-option]="optionIndex === menuItemOptionGroup.options.length - 1"
              (click)="onToggleOption(menuItemOptionGroup, option)"
            >
              <div class="option-name-wrapper">
                <div class="option-name" data-cy="option-name">{{ option.name }}</div>

                <div *ngIf="option.price" class="option-price-wrapper">
                  <div class="option-price">+{{ option.price | localCurrency }}</div>
                  <div *ngIf="option.onSale" class="option-retail-price">{{ option.retailPrice | localCurrency }}</div>
                </div>
              </div>

              <div tappable class="option-toggle">
                <!-- If min and max is 1, we should use radio buttons -->
                <ng-container *ngIf="menuItemOptionGroup.minimumSelection === 1 && menuItemOptionGroup.maximumSelection === 1">
                  <ion-icon class="radio-button-back" [class.selected]="option.quantity === 1" name="ellipse-outline"></ion-icon>
                  <ion-icon
                    class="radio-button-front"
                    data-cy="option-checkbox-icon"
                    [class.empty]="option.quantity !== 1"
                    [class.selected]="option.quantity === 1"
                    name="checkmark-circle"
                  ></ion-icon>
                </ng-container>

                <!-- Otherwise we should use checkboxes -->
                <ng-container *ngIf="menuItemOptionGroup.minimumSelection !== 1 || menuItemOptionGroup.maximumSelection !== 1">
                  <ion-icon class="checkbox-back" [class.selected]="option.quantity === 1" name="square-outline"></ion-icon>
                  <ion-icon
                    class="checkbox-front"
                    data-cy="option-checkbox-icon"
                    [class.empty]="option.quantity !== 1"
                    [class.selected]="option.quantity === 1"
                    name="checkbox"
                  ></ion-icon>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #menuItemAddOns let-addOnGroups="addOnGroups" let-collapseAddOns="collapseAddOns">
  <div class="item-content-section item-addons" [class.collapsable]="collapseAddOns" *ngIf="!!addOnGroups && !!addOnGroups.length">
    <div
      *ngFor="let menuItemAddonGroup of addOnGroups; trackBy: identifyAddonGroup"
      class="item-addons-group"
      [class.max-selected]="
        menuItemAddonGroup.maximumSelection > 0 &&
        menuItemAddonGroup.selectedAddOnsCount ===
          (menuItemAddonGroup.maximumSelection | adjustBasedOnQuantity : currentQuantity : menuItemDetails.pricingMethod)
      "
      [class.min-selected]="
        (menuItemAddonGroup.minimumSelection | adjustBasedOnQuantity : currentQuantity : menuItemDetails.pricingMethod) -
          menuItemAddonGroup.selectedAddOnsCount <=
        0
      "
    >
      <!-- Addon group title-->
      <div
        tappable
        class="item-addons-title-wrapper"
        [class.with-icon]="collapseAddOns"
        (click)="collapseAddOns && onToggleAnimatedObject(menuItemAddonGroup)"
      >
        <div>
          <div data-cy="item-addons-title" class="item-addons-title">{{ menuItemAddonGroup.name }}</div>

          <div *ngIf="!collapseAddOns && currentQuantity !== 0" data-cy="item-addons-description" class="item-addons-description">
            <!--
              Important: Since we may want to update these translations AFTER releasing this feature, let's
              send as many info as we can to the translation (selectedQuantity, minQuantity, maxQuantity, etc)
              even if it's not used right now to be as flexible as possible.

              Possible scenarios:
              01) MIN === 0 and MAX === 0 => MIN_ZERO_MAX_ZERO
              02) MIN === 0 and MAX > 0 && SELECTED === 0 => MIN_ZERO_MAX_X_NOT_FULFILLED_ADDON or MIN_ZERO_MAX_X_NOT_FULFILLED_ADDONS
              03) MIN === 0 and MAX > 0 && SELECTED > 0 && SELECTED < MAX => MIN_ZERO_MAX_X_NOT_FULFILLED_MORE_ADDON or MIN_ZERO_MAX_X_NOT_FULFILLED_MORE_ADDONS
              04) MIN === 0 and MAX > 0 && SELECTED > 0 && SELECTED === MAX => MIN_ZERO_MAX_X_FULFILLED_ADDON or MIN_ZERO_MAX_X_FULFILLED_ADDONS                          
              05) MIN > 0 and MAX === 0 && SELECTED === 0 => MIN_X_MAX_ZERO_NOT_FULFILLED_ADDON or MIN_X_MAX_ZERO_NOT_FULFILLED_ADDONS
              06) MIN > 0 and MAX === 0 && SELECTED > 0 && SELECTED < MIN => MIN_X_MAX_ZERO_NOT_FULFILLED_MORE_ADDON or MIN_X_MAX_ZERO_NOT_FULFILLED_MORE_ADDONS
              07) MIN > 0 and MAX === 0 && SELECTED > 0 && SELECTED >= MIN => MIN_X_MAX_ZERO_FULFILLED_ADDON or MIN_X_MAX_ZERO_FULFILLED_ADDONS            
              08) MIN > 0 && MAX > 0 && MIN === MAX && SELECTED === 0 => MIN_X_MAX_X_NOT_FULFILLED_ADDON or MIN_X_MAX_X_NOT_FULFILLED_ADDONS
              09) MIN > 0 && MAX > 0 && MIN === MAX && SELECTED > 0 && SELECTED < MIN => MIN_X_MAX_X_NOT_FULFILLED_MORE_ADDON or MIN_X_MAX_X_NOT_FULFILLED_MORE_ADDONS
              10) MIN > 0 && MAX > 0 && MIN === MAX && SELECTED > 0 && SELECTED === MIN => MIN_X_MAX_X_FULFILLED_ADDON or MIN_X_MAX_X_FULFILLED_ADDONS
              11) MIN > 0 && MAX > 0 && MIN !== MAX && SELECTED === 0 => MIN_X_MAX_Y_MIN_NOT_FULFILLED_ADDONS
              12) MIN > 0 && MAX > 0 && MIN !== MAX && SELECTED > 0 && SELECTED < MIN => MIN_X_MAX_Y_MIN_NOT_FULFILLED_MORE_ADDON or MIN_X_MAX_Y_MIN_NOT_FULFILLED_MORE_ADDONS
              13) MIN > 0 && MAX > 0 && MIN !== MAX && SELECTED > 0 && SELECTED >= MIN && SELECTED < MAX => MIN_X_MAX_Y_MIN_FULFILLED_MAX_NOT_FULFILLED_ADDON or MIN_X_MAX_Y_MIN_FULFILLED_MAX_NOT_FULFILLED_ADDONS
              14) MIN > 0 && MAX > 0 && MIN !== MAX && SELECTED > 0 && SELECTED >= MIN && SELECTED === MAX => MIN_X_MAX_Y_MIN_AND_MAX_FULFILLED_ADDONS
            -->

            <ng-container *ngIf="!menuItemAddonGroup.minimumSelection">
              <!-- 01) MIN === 0 and MAX === 0 => MIN_ZERO_MAX_ZERO -->
              <span *ngIf="!menuItemAddonGroup.maximumSelection">
                {{ "MENU_ITEM_DETAILS_PAGE.MIN_ZERO_MAX_ZERO" | translate : { selectedQuantity: menuItemAddonGroup.selectedAddOnsCount } }}
              </span>

              <ng-container
                *ngIf="
                  !!menuItemAddonGroup.maximumSelection &&
                  (menuItemAddonGroup.maximumSelection
                    | adjustBasedOnQuantity : currentQuantity : menuItemDetails.pricingMethod) as maximumSelectionAdjusted
                "
              >
                <!-- 02) MIN === 0 and MAX > 0 && SELECTED === 0 => MIN_ZERO_MAX_X_NOT_FULFILLED_ADDON or MIN_ZERO_MAX_X_NOT_FULFILLED_ADDONS -->
                <span *ngIf="!menuItemAddonGroup.selectedAddOnsCount">
                  {{
                    (maximumSelectionAdjusted === 1
                      ? "MENU_ITEM_DETAILS_PAGE.MIN_ZERO_MAX_X_NOT_FULFILLED_ADDON"
                      : "MENU_ITEM_DETAILS_PAGE.MIN_ZERO_MAX_X_NOT_FULFILLED_ADDONS"
                    ) | translate : { maxQuantity: maximumSelectionAdjusted }
                  }}
                </span>

                <ng-container *ngIf="!!menuItemAddonGroup.selectedAddOnsCount">
                  <!-- 03) MIN === 0 and MAX > 0 && SELECTED > 0 && SELECTED < MAX => MIN_ZERO_MAX_X_NOT_FULFILLED_MORE_ADDON or MIN_ZERO_MAX_X_NOT_FULFILLED_MORE_ADDONS -->
                  <span *ngIf="menuItemAddonGroup.selectedAddOnsCount < maximumSelectionAdjusted">
                    {{
                      (maximumSelectionAdjusted - menuItemAddonGroup.selectedAddOnsCount === 1
                        ? "MENU_ITEM_DETAILS_PAGE.MIN_ZERO_MAX_X_NOT_FULFILLED_MORE_ADDON"
                        : "MENU_ITEM_DETAILS_PAGE.MIN_ZERO_MAX_X_NOT_FULFILLED_MORE_ADDONS"
                      )
                        | translate
                          : {
                              selectedQuantity: menuItemAddonGroup.selectedAddOnsCount,
                              maxQuantity: maximumSelectionAdjusted,
                              remainingToMaxQuantity: maximumSelectionAdjusted - menuItemAddonGroup.selectedAddOnsCount
                            }
                    }}
                  </span>

                  <!-- 04) MIN === 0 and MAX > 0 && SELECTED > 0 && SELECTED === MAX => MIN_ZERO_MAX_X_FULFILLED_ADDON or MIN_ZERO_MAX_X_FULFILLED_ADDONS -->
                  <span *ngIf="menuItemAddonGroup.selectedAddOnsCount === maximumSelectionAdjusted">
                    {{
                      (maximumSelectionAdjusted === 1
                        ? "MENU_ITEM_DETAILS_PAGE.MIN_ZERO_MAX_X_FULFILLED_ADDON"
                        : "MENU_ITEM_DETAILS_PAGE.MIN_ZERO_MAX_X_FULFILLED_ADDONS"
                      ) | translate : { maxQuantity: maximumSelectionAdjusted }
                    }}
                  </span>
                </ng-container>
              </ng-container>
            </ng-container>

            <ng-container
              *ngIf="
                !!menuItemAddonGroup.minimumSelection &&
                (menuItemAddonGroup.minimumSelection
                  | adjustBasedOnQuantity : currentQuantity : menuItemDetails.pricingMethod) as minimumSelectionAdjusted
              "
            >
              <ng-container *ngIf="!menuItemAddonGroup.maximumSelection">
                <!-- 05) MIN > 0 and MAX === 0 && SELECTED === 0 => MIN_X_MAX_ZERO_NOT_FULFILLED_ADDON or MIN_X_MAX_ZERO_NOT_FULFILLED_ADDONS -->
                <span *ngIf="!menuItemAddonGroup.selectedAddOnsCount">
                  {{
                    (minimumSelectionAdjusted === 1
                      ? "MENU_ITEM_DETAILS_PAGE.MIN_X_MAX_ZERO_NOT_FULFILLED_ADDON"
                      : "MENU_ITEM_DETAILS_PAGE.MIN_X_MAX_ZERO_NOT_FULFILLED_ADDONS"
                    ) | translate : { minQuantity: minimumSelectionAdjusted }
                  }}
                </span>

                <ng-container *ngIf="!!menuItemAddonGroup.selectedAddOnsCount">
                  <!-- 06) MIN > 0 and MAX === 0 && SELECTED > 0 && SELECTED < MIN => MIN_X_MAX_ZERO_NOT_FULFILLED_MORE_ADDON or MIN_X_MAX_ZERO_NOT_FULFILLED_MORE_ADDONS -->
                  <span *ngIf="menuItemAddonGroup.selectedAddOnsCount < minimumSelectionAdjusted">
                    {{
                      (minimumSelectionAdjusted - menuItemAddonGroup.selectedAddOnsCount === 1
                        ? "MENU_ITEM_DETAILS_PAGE.MIN_X_MAX_ZERO_NOT_FULFILLED_MORE_ADDON"
                        : "MENU_ITEM_DETAILS_PAGE.MIN_X_MAX_ZERO_NOT_FULFILLED_MORE_ADDONS"
                      )
                        | translate
                          : {
                              selectedQuantity: menuItemAddonGroup.selectedAddOnsCount,
                              minQuantity: minimumSelectionAdjusted,
                              remainingToMinQuantity: minimumSelectionAdjusted - menuItemAddonGroup.selectedAddOnsCount
                            }
                    }}
                  </span>

                  <!-- 07) MIN > 0 and MAX === 0 && SELECTED > 0 && SELECTED >= MIN => MIN_X_MAX_ZERO_FULFILLED_ADDON or MIN_X_MAX_ZERO_FULFILLED_ADDONS -->
                  <span *ngIf="menuItemAddonGroup.selectedAddOnsCount >= minimumSelectionAdjusted">
                    {{
                      (minimumSelectionAdjusted - menuItemAddonGroup.selectedAddOnsCount === 1
                        ? "MENU_ITEM_DETAILS_PAGE.MIN_X_MAX_ZERO_FULFILLED_ADDON"
                        : "MENU_ITEM_DETAILS_PAGE.MIN_X_MAX_ZERO_FULFILLED_ADDONS"
                      )
                        | translate
                          : {
                              selectedQuantity: menuItemAddonGroup.selectedAddOnsCount,
                              minQuantity: minimumSelectionAdjusted,
                            }
                    }}
                  </span>
                </ng-container>
              </ng-container>

              <ng-container
                *ngIf="
                  !!menuItemAddonGroup.maximumSelection &&
                  (menuItemAddonGroup.maximumSelection
                    | adjustBasedOnQuantity : currentQuantity : menuItemDetails.pricingMethod) as maximumSelectionAdjusted
                "
              >
                <ng-container *ngIf="minimumSelectionAdjusted === maximumSelectionAdjusted">
                  <!-- 08) MIN > 0 && MAX > 0 && MIN === MAX && SELECTED === 0 => MIN_X_MAX_X_NOT_FULFILLED_ADDON or MIN_X_MAX_X_NOT_FULFILLED_ADDONS -->
                  <span *ngIf="!menuItemAddonGroup.selectedAddOnsCount">
                    {{
                      (minimumSelectionAdjusted === 1
                        ? "MENU_ITEM_DETAILS_PAGE.MIN_X_MAX_X_NOT_FULFILLED_ADDON"
                        : "MENU_ITEM_DETAILS_PAGE.MIN_X_MAX_X_NOT_FULFILLED_ADDONS"
                      )
                        | translate
                          : {
                              minQuantity: minimumSelectionAdjusted,
                              maxQuantity: maximumSelectionAdjusted
                            }
                    }}
                  </span>

                  <ng-container *ngIf="!!menuItemAddonGroup.selectedAddOnsCount">
                    <!-- 09) MIN > 0 && MAX > 0 && MIN === MAX && SELECTED > 0 && SELECTED < MIN => MIN_X_MAX_X_NOT_FULFILLED_MORE_ADDON or MIN_X_MAX_X_NOT_FULFILLED_MORE_ADDONS -->
                    <span *ngIf="menuItemAddonGroup.selectedAddOnsCount < minimumSelectionAdjusted">
                      {{
                        (minimumSelectionAdjusted - menuItemAddonGroup.selectedAddOnsCount === 1
                          ? "MENU_ITEM_DETAILS_PAGE.MIN_X_MAX_X_NOT_FULFILLED_MORE_ADDON"
                          : "MENU_ITEM_DETAILS_PAGE.MIN_X_MAX_X_NOT_FULFILLED_MORE_ADDONS"
                        )
                          | translate
                            : {
                                minQuantity: minimumSelectionAdjusted,
                                maxQuantity: maximumSelectionAdjusted,
                                selectedQuantity: menuItemAddonGroup.selectedAddOnsCount,
                                remainingToMinQuantity: minimumSelectionAdjusted - menuItemAddonGroup.selectedAddOnsCount,
                                remainingToMaxQuantity: maximumSelectionAdjusted - menuItemAddonGroup.selectedAddOnsCount
                              }
                      }}
                    </span>

                    <!-- 10) MIN > 0 && MAX > 0 && MIN === MAX && SELECTED > 0 && SELECTED === MIN => MIN_X_MAX_X_FULFILLED_ADDON or MIN_X_MAX_X_FULFILLED_ADDONS -->
                    <span *ngIf="menuItemAddonGroup.selectedAddOnsCount === minimumSelectionAdjusted">
                      {{
                        (menuItemAddonGroup.selectedAddOnsCount === 1
                          ? "MENU_ITEM_DETAILS_PAGE.MIN_X_MAX_X_FULFILLED_ADDON"
                          : "MENU_ITEM_DETAILS_PAGE.MIN_X_MAX_X_FULFILLED_ADDONS"
                        )
                          | translate
                            : {
                                minQuantity: minimumSelectionAdjusted,
                                maxQuantity: maximumSelectionAdjusted,
                                selectedQuantity: menuItemAddonGroup.selectedAddOnsCount
                              }
                      }}
                    </span>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="minimumSelectionAdjusted !== maximumSelectionAdjusted">
                  <!-- 11) MIN > 0 && MAX > 0 && MIN !== MAX && SELECTED === 0 => MIN_X_MAX_Y_MIN_NOT_FULFILLED_ADDONS -->
                  <span *ngIf="!menuItemAddonGroup.selectedAddOnsCount">
                    {{
                      "MENU_ITEM_DETAILS_PAGE.MIN_X_MAX_Y_MIN_NOT_FULFILLED_ADDONS"
                        | translate
                          : {
                              minQuantity: minimumSelectionAdjusted,
                              maxQuantity: maximumSelectionAdjusted
                            }
                    }}
                  </span>

                  <ng-container *ngIf="!!menuItemAddonGroup.selectedAddOnsCount">
                    <!-- 12) MIN > 0 && MAX > 0 && MIN !== MAX && SELECTED > 0 && SELECTED < MIN => MIN_X_MAX_Y_MIN_NOT_FULFILLED_MORE_ADDON or MIN_X_MAX_Y_MIN_NOT_FULFILLED_MORE_ADDONS -->
                    <span *ngIf="menuItemAddonGroup.selectedAddOnsCount < minimumSelectionAdjusted">
                      {{
                        (minimumSelectionAdjusted - menuItemAddonGroup.selectedAddOnsCount === 1
                          ? "MENU_ITEM_DETAILS_PAGE.MIN_X_MAX_Y_MIN_NOT_FULFILLED_MORE_ADDON"
                          : "MENU_ITEM_DETAILS_PAGE.MIN_X_MAX_Y_MIN_NOT_FULFILLED_MORE_ADDONS"
                        )
                          | translate
                            : {
                                minQuantity: minimumSelectionAdjusted,
                                maxQuantity: maximumSelectionAdjusted,
                                selectedQuantity: menuItemAddonGroup.selectedAddOnsCount,
                                remainingToMinQuantity: minimumSelectionAdjusted - menuItemAddonGroup.selectedAddOnsCount,
                                remainingToMaxQuantity: maximumSelectionAdjusted - menuItemAddonGroup.selectedAddOnsCount
                              }
                      }}
                    </span>

                    <!-- 13) MIN > 0 && MAX > 0 && MIN !== MAX && SELECTED > 0 && SELECTED >= MIN && SELECTED < MAX => MIN_X_MAX_Y_MIN_FULFILLED_MAX_NOT_FULFILLED_ADDON or MIN_X_MAX_Y_MIN_FULFILLED_MAX_NOT_FULFILLED_ADDONS -->
                    <span
                      *ngIf="
                        menuItemAddonGroup.selectedAddOnsCount >= minimumSelectionAdjusted &&
                        menuItemAddonGroup.selectedAddOnsCount < maximumSelectionAdjusted
                      "
                    >
                      {{
                        (maximumSelectionAdjusted - menuItemAddonGroup.selectedAddOnsCount === 1
                          ? "MENU_ITEM_DETAILS_PAGE.MIN_X_MAX_Y_MIN_FULFILLED_MAX_NOT_FULFILLED_ADDON"
                          : "MENU_ITEM_DETAILS_PAGE.MIN_X_MAX_Y_MIN_FULFILLED_MAX_NOT_FULFILLED_ADDONS"
                        )
                          | translate
                            : {
                                minQuantity: minimumSelectionAdjusted,
                                maxQuantity: maximumSelectionAdjusted,
                                selectedQuantity: menuItemAddonGroup.selectedAddOnsCount,
                                remainingToMaxQuantity: maximumSelectionAdjusted - menuItemAddonGroup.selectedAddOnsCount
                              }
                      }}
                    </span>

                    <!-- 14) MIN > 0 && MAX > 0 && MIN !== MAX && SELECTED > 0 && SELECTED >= MIN && SELECTED === MAX => MIN_X_MAX_Y_MIN_AND_MAX_FULFILLED_ADDONS -->
                    <span
                      *ngIf="
                        menuItemAddonGroup.selectedAddOnsCount >= minimumSelectionAdjusted &&
                        menuItemAddonGroup.selectedAddOnsCount === maximumSelectionAdjusted
                      "
                    >
                      {{
                        "MENU_ITEM_DETAILS_PAGE.MIN_X_MAX_Y_MIN_AND_MAX_FULFILLED_ADDONS"
                          | translate
                            : {
                                minQuantity: minimumSelectionAdjusted,
                                maxQuantity: maximumSelectionAdjusted,
                                selectedQuantity: menuItemAddonGroup.selectedAddOnsCount
                              }
                      }}
                    </span>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>

        <ion-icon
          *ngIf="collapseAddOns"
          class="item-addons-icon"
          src="/assets/custom-icons/b-angle-right.svg"
          [class.icon-rotate]="menuItemAddonGroup.animationState === AnimationState.Expanded"
        ></ion-icon>

        <div
          class="item-addons-badge"
          [class.optional]="!menuItemAddonGroup.minimumSelection"
          [class.required]="!!menuItemAddonGroup.minimumSelection"
          *ngIf="!collapseAddOns"
        >
          <div class="item-addons-badge-text" data-cy="item-addons-badge-text">
            {{
              (!!menuItemAddonGroup.minimumSelection
                ? "MENU_ITEM_DETAILS_PAGE.GROUP_BADGE_REQUIRED"
                : "MENU_ITEM_DETAILS_PAGE.GROUP_BADGE_OPTIONAL"
              ) | translate
            }}
          </div>
          <div
            @animagedGroupBadgeIcon
            class="item-addons-badge-icon-container"
            data-cy="item-addons-badge-icon"
            *ngIf="
              !!menuItemAddonGroup.minimumSelection
                ? menuItemAddonGroup.selectedAddOnsCount >=
                  (menuItemAddonGroup.minimumSelection | adjustBasedOnQuantity : currentQuantity : menuItemDetails.pricingMethod)
                : !!menuItemAddonGroup.selectedAddOnsCount
            "
          >
            <ion-icon class="item-addons-badge-icon" name="checkmark-circle-outline"></ion-icon>
          </div>
        </div>
      </div>

      <div class="item-addons-content" [@animatedAddonGroupContent]="menuItemAddonGroup.animationState" style="overflow: hidden">
        <ng-container *ngFor="let addon of menuItemAddonGroup.addons; trackBy: identifyAddon; let addonIndex = index">
          <!-- The add-on is actually a title -->
          <div *ngIf="addon.isTitle" class="item-addons-subtitle" data-cy="addon-header">{{ addon.name }}</div>

          <!-- The add-on is a real add-on and not a title -->
          <div
            *ngIf="!addon.isTitle"
            data-cy="addon"
            class="item-addon"
            [class.first-addon]="addonIndex === 0"
            [class.last-addon]="addonIndex === menuItemAddonGroup.addons.length - 1"
          >
            <div class="addon-name-wrapper">
              <div class="addon-name" data-cy="addon-name">{{ addon.name }}</div>

              <div *ngIf="addon.price" class="addon-price-wrapper">
                <div class="addon-price">+{{ addon.price | localCurrency }}</div>
                <div *ngIf="addon.onSale" class="addon-retail-price">{{ addon.retailPrice | localCurrency }}</div>
              </div>
            </div>

            <div class="addon-quantity-selector">
              <ion-button
                class="addon-quantity-selector-button decrement-button"
                [class.disabled]="addon.quantity === 0"
                data-cy="decrement-addon-quantity-button"
                (click)="onDecrementMenuItemAddOn(menuItemAddonGroup, addon)"
              >
                <ion-icon
                  slot="icon-only"
                  class="addon-quantity-selector-button-icon"
                  src="/assets/custom-icons/item-details-modal/decrement-secondary.svg"
                ></ion-icon>
              </ion-button>

              <span class="addon-quantity-selector-text" data-cy="addon-quantity">{{ addon.quantity }}</span>

              <ion-button
                class="addon-quantity-selector-button increment-button"
                data-cy="increment-addon-quantity-button"
                (click)="onIncrementMenuItemAddOn(menuItemAddonGroup, addon)"
              >
                <ion-icon
                  slot="icon-only"
                  class="addon-quantity-selector-button-icon"
                  src="/assets/custom-icons/item-details-modal/increment-secondary.svg"
                ></ion-icon>
              </ion-button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #menuItemSpecialRequests>
  <div class="item-content-section additional-requests">
    <div class="request-title">
      {{ (isAdditionalCardSupported ? "MENU_ITEM_DETAILS_PAGE.OTHER" : "SPECIAL_REQUESTS") | translate }}
    </div>

    <div class="additional-request-option" *ngIf="isAdditionalCardSupported">
      <div>
        <div class="additional-request-option-title">{{ "MENU_ITEM_DETAILS_PAGE.ADD_CARD" | translate }}</div>
        <div class="additional-request-option-description">{{ "MENU_ITEM_DETAILS_PAGE.ADD_CARD_DESCRIPTION" | translate }}</div>
      </div>

      <ion-toggle
        mode="ios"
        color="green"
        class="ion-no-padding"
        [checked]="isAddCardSelected"
        (ionChange)="onToggleAddCard()"
      ></ion-toggle>
    </div>

    <div
      class="additional-request-option"
      data-cy="female-service-section"
      *ngIf="vendorMenuItem.isFemaleServeAvailable || isFemaleServicePreSelected"
    >
      <div>
        <div class="additional-request-option-title" data-cy="female-service-section-title">
          {{ "MENU_ITEM_DETAILS_PAGE.REQUEST_FEMALE_SERVICE" | translate }}
        </div>

        <ng-container *ngIf="vendorMenuItem.isFemaleServeAvailable && !vendorMenuItem.isFemaleServeBlocked">
          <div class="additional-request-option-description" data-cy="female-service-section-description">
            {{ "MENU_ITEM_DETAILS_PAGE.REQUEST_FEMALE_SERVICE_AVAILABLE" | translate }}
          </div>
          <div class="additional-request-option-price" data-cy="female-service-section-price" *ngIf="menuItemDetails.femaleServiceCost > 0">
            +{{ menuItemDetails.femaleServiceCost | localCurrency }}
          </div>
        </ng-container>

        <!-- It's available but blocked for the selected date -->
        <div
          class="additional-request-option-description"
          data-cy="female-service-section-description"
          *ngIf="vendorMenuItem.isFemaleServeAvailable && vendorMenuItem.isFemaleServeBlocked"
        >
          {{ "MENU_ITEM_DETAILS_PAGE.REQUEST_FEMALE_SERVICE_BLOCKED" | translate }}
        </div>

        <!-- It's not available anymore but was added to the cart before -->
        <div
          class="additional-request-option-description"
          data-cy="female-service-section-description"
          *ngIf="!vendorMenuItem.isFemaleServeAvailable && isFemaleServicePreSelected"
        >
          {{ "MENU_ITEM_DETAILS_PAGE.REQUEST_FEMALE_SERVICE_NOT_AVAILABLE" | translate }}
        </div>
      </div>

      <div
        tappable
        class="female-service-toggle"
        (click)="onToggleFemaleService()"
        [class.disabled]="
          (vendorMenuItem.isFemaleServeAvailable && vendorMenuItem.isFemaleServeBlocked) ||
          (!vendorMenuItem.isFemaleServeAvailable && isFemaleServicePreSelected)
        "
        data-cy="female-service-section-toggle"
      >
        <ion-toggle disabled mode="ios" color="green" class="ion-no-padding" [ngModel]="isFemaleServiceSelected"></ion-toggle>
      </div>
    </div>

    <ion-textarea
      class="custom-form-item-textarea request-textarea"
      *ngIf="!isAdditionalCardSupported || isAddCardSelected"
      [(ngModel)]="specialRequests"
      [attr.rows]="isAdditionalCardSupported ? 5 : 3"
      [placeholder]="
        (isAdditionalCardSupported
          ? 'MENU_ITEM_DETAILS_PAGE.ADD_CARD_PLACEHOLDER'
          : 'MENU_ITEM_DETAILS_PAGE.SPECIAL_REQUIREMENTS_PLACEHOLDER'
        ) | translate
      "
      data-cy="special-requirement-textarea"
    ></ion-textarea>
  </div>
</ng-template>

<ng-template #menuItemFooter>
  <div
    *ngIf="vendorMenuItem && menuItemDetails"
    class="item-footer"
    [class.unavailable]="vendorMenuItem.availability !== Availability.Available && !vendorMenuItem.supportNextAvailable"
  >
    <div class="item-footer-row">
      <p class="name">{{ menuItemDetails.name }}</p>
      <p class="total" *ngIf="currentQuantity !== 0">{{ currentCost || 0 | localCurrency }}</p>
    </div>

    <div *ngIf="loyaltyProgramNextRewardDetails" class="item-footer-row">
      <div class="loyalty-program-reward">
        <app-animated-image class="loyalty-program-reward-icon" [imageUrl]="loyaltyProgramNextRewardDetails.imageUrl"></app-animated-image>
        <p class="loyalty-program-reward-text" [innerHTML]="loyaltyProgramNextRewardDetails.description"></p>
      </div>
    </div>

    <div class="item-footer-row">
      <div class="quantity-selector" *ngIf="menuItemDetails.pricingMethod !== PricingMethod.AddOns">
        <ion-button class="quantity-selector-button" [class.disabled]="isMinQuantity" (click)="onDecrementQuantity()">
          <ion-icon
            slot="icon-only"
            class="quantity-selector-button-icon"
            src="/assets/custom-icons/item-details-modal/decrement.svg"
          ></ion-icon>
        </ion-button>

        <p class="quantity-selector-text" data-cy="item-quantity">{{ currentQuantity }}</p>

        <ion-button
          class="quantity-selector-button"
          [class.disabled]="isMaxQuantity"
          data-cy="increment-quantity-button"
          (click)="onIncrementQuantity()"
        >
          <ion-icon
            slot="icon-only"
            class="quantity-selector-button-icon"
            src="/assets/custom-icons/item-details-modal/increment.svg"
          ></ion-icon>
        </ion-button>
      </div>

      <ng-container *ngIf="!isUpdatingWishlistItem">
        <ion-button
          *ngIf="!isUpdatingCartItem || currentQuantity !== 0"
          color="green"
          expand="block"
          class="add-to-cart-button"
          data-cy="add-to-cart-button"
          (click)="vendorMenuItem.availability !== Availability.Available ? checkAvailability() : onAddMenuItemToCart()"
        >
          <div class="add-to-cart-button-content">
            {{
              (vendorMenuItem.availability === Availability.Available
                ? isUpdatingCartItem
                  ? "MENU_ITEM_DETAILS_PAGE.UPDATE_CART"
                  : "MENU_ITEM_DETAILS_PAGE.ADD_TO_CART"
                : vendorMenuItem.supportNextAvailable
                ? "CHECK_AVAILABILITY"
                : "UNAVAILABLE"
              ) | translate
            }}
          </div>
        </ion-button>

        <ion-button
          *ngIf="isUpdatingCartItem && currentQuantity === 0"
          color="red"
          expand="block"
          class="remove-from-cart-button"
          data-cy="remove-from-cart-button"
          (click)="onRemoveFromCart()"
        >
          <span>{{ "MENU_ITEM_DETAILS_PAGE.REMOVE_FROM_CART" | translate }}</span>
        </ion-button>
      </ng-container>

      <ng-container *ngIf="isUpdatingWishlistItem">
        <ion-button
          *ngIf="currentQuantity !== 0"
          color="green"
          expand="block"
          class="add-to-cart-button"
          (click)="onUpdateExistingWishlistItem()"
        >
          <div class="add-to-cart-button-content">
            <span>{{ "MENU_ITEM_DETAILS_PAGE.UPDATE_WISHLIST" | translate }}</span>
          </div>
        </ion-button>

        <ion-button
          *ngIf="currentQuantity === 0"
          color="red"
          expand="block"
          class="remove-from-wishlist-button"
          (click)="onRemoveFromExistingWishlistItem()"
        >
          <span>{{ "MENU_ITEM_DETAILS_PAGE.REMOVE_FROM_WISHLIST" | translate }}</span>
        </ion-button>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-container *ngIf="!isBrowser">
  <div class="main-header" [class.with-navbar-background]="showNavbarBackground">
    <div #navbar class="navbar" [class.with-padding-top]="showNavbarPaddingTop">
      <div class="navbar-start">
        <ion-button @animatedBackButton class="navbar-button" (click)="onDismiss()">
          <ion-icon class="navbar-button-icon back-icon" src="/assets/custom-icons/shared/back-icon.svg"></ion-icon>
        </ion-button>
      </div>
      <div class="navbar-center">
        <div class="navbar-title">{{ vendorMenuItem?.name }}</div>
      </div>
      <div class="navbar-end">
        <ion-button
          @animatedWishlistButton
          color="white"
          class="navbar-button wishlist-button"
          *ngIf="!isLoading && !isUpdatingWishlistItem && isLoggedIn"
          (click)="onShowWishlists($event)"
        >
          <ion-icon class="navbar-button-icon wishlist-icon" src="/assets/custom-icons/shared/heart.svg"></ion-icon>
        </ion-button>
        <ion-button color="white" class="navbar-button share-button" *ngIf="!isLoading" @animatedShareButton (click)="onShare()">
          <ion-icon class="navbar-button-icon share-icon" src="/assets/custom-icons/shared/share.svg"></ion-icon>
        </ion-button>
      </div>
    </div>
  </div>

  <ion-content [scrollEvents]="true" (ionScroll)="onScroll($event)">
    <div class="item-details-container">
      <div class="item-details menu-item-details">
        <ng-container *ngIf="!!vendorMenuItem?.imageUrls?.length">
          <ng-template [ngTemplateOutlet]="menuItemImage"></ng-template>
        </ng-container>

        <div
          class="item-content"
          [class.with-padding-top]="!!vendorMenuItem && (!vendorMenuItem.imageUrls || vendorMenuItem.imageUrls.length === 0)"
        >
          <div @animatedContent class="loading-container" *ngIf="isLoading">
            <ion-spinner name="dots"></ion-spinner>
          </div>

          <div @animatedContent *ngIf="!isLoading && menuItemDetails">
            <ng-template [ngTemplateOutlet]="menuItemRequirements"></ng-template>
            <ng-template [ngTemplateOutlet]="menuItemDescription"></ng-template>
            <ng-template *ngTemplateOutlet="menuItemOptions; context: menuItemDetails"></ng-template>
            <ng-template *ngTemplateOutlet="menuItemAddOns; context: menuItemDetails"></ng-template>
            <ng-template [ngTemplateOutlet]="menuItemSpecialRequests"></ng-template>
          </div>
        </div>
      </div>
    </div>
  </ion-content>

  <ion-footer *ngIf="!shouldHideFooter && menuItemDetails">
    <ion-toolbar>
      <ng-template [ngTemplateOutlet]="menuItemFooter"></ng-template>
    </ion-toolbar>
  </ion-footer>
</ng-container>

<ng-container *ngIf="isBrowser">
  <div class="inner-content">
    <div class="item-details-container">
      <div class="item-details menu-item-details" [class.with-loyalty-program-reward]="!!loyaltyProgramNextRewardDetails">
        <div class="item-details-start">
          <ng-container *ngIf="!!vendorMenuItem?.imageUrls?.length">
            <ng-template [ngTemplateOutlet]="menuItemImage"></ng-template>
          </ng-container>

          <div @animatedContent class="loading-container" *ngIf="isLoading">
            <ion-spinner name="dots"></ion-spinner>
          </div>

          <div @animatedContent *ngIf="!isLoading && menuItemDetails">
            <ng-template [ngTemplateOutlet]="menuItemRequirements"></ng-template>
            <ng-template [ngTemplateOutlet]="menuItemDescription"></ng-template>

            <div class="item-details-start-footer">
              <ng-template [ngTemplateOutlet]="menuItemFooter"></ng-template>
            </div>
          </div>
        </div>

        <div class="item-details-end">
          <div @animatedContent class="loading-container" *ngIf="isLoading">
            <ion-spinner name="dots"></ion-spinner>
          </div>

          <div @animatedContent *ngIf="!isLoading && menuItemDetails">
            <ng-template *ngTemplateOutlet="menuItemOptions; context: menuItemDetails"></ng-template>
            <ng-template *ngTemplateOutlet="menuItemAddOns; context: menuItemDetails"></ng-template>
            <ng-template [ngTemplateOutlet]="menuItemSpecialRequests"></ng-template>
          </div>
        </div>

        <div @animatedContent *ngIf="!isLoading && menuItemDetails" class="item-details-footer">
          <ng-template [ngTemplateOutlet]="menuItemFooter"></ng-template>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ion-modal
  class="wishlists multi-platform"
  *ngIf="!!menuItemDetails"
  [class.no-wishlists]="!wishlists || wishlists.length === 0"
  [isOpen]="isWishlistsModalOpen"
  (didDismiss)="isWishlistsModalOpen = false"
>
  <ng-template>
    <div class="inner-content">
      <div class="empty-lists-content" *ngIf="!wishlists || wishlists.length === 0">
        <img class="empty-lists-image" src="/assets/img/wishlists/illustration.svg" />
        <p class="empty-lists-title" [innerHTML]="'WISHLISTS_PAGE.EMPTY_LISTS_TITLE' | translate"></p>
        <p class="empty-lists-message" [innerHTML]="'WISHLISTS_PAGE.EMPTY_LISTS_MESSAGE' | translate"></p>
      </div>

      <ng-container *ngIf="wishlists?.length">
        <p class="wishlists-title">{{ "WISHLISTS_PAGE.ADD_TO_LIST" | translate }}</p>

        <ion-button
          expand="block"
          color="grey"
          class="custom-button wishlist"
          *ngFor="let wishlist of wishlists"
          (click)="onAddItemToWishlist(wishlist)"
        >
          <div class="wishlist-details">
            <div
              @animatedWishlistName
              class="wishlist-status"
              *ngIf="wishlist.wishListId !== updatingWishlistId && wishlist.wishListId !== updatedWishlistId"
            >
              <p class="wishlist-name">{{ wishlist.title }}</p>
            </div>

            <div @animatedWishlistLoadingIcon class="wishlist-status" *ngIf="wishlist.wishListId === updatingWishlistId">
              <ion-spinner duration="1000" name="lines-sharp-small" class="wishlist-loading-icon"></ion-spinner>
            </div>

            <div @animatedWishlistSuccessIcon class="wishlist-status" *ngIf="wishlist.wishListId === updatedWishlistId">
              <ion-icon class="wishlist-success-icon" src="/assets/custom-icons/item-details-modal/add-to-wishlists-check.svg"></ion-icon>
            </div>
          </div>
        </ion-button>
      </ng-container>

      <ion-button (click)="onAddItemToNewWishlist()" expand="block" class="custom-button" color="green">
        {{ "WISHLISTS_PAGE.NEW_LIST" | translate }}
      </ion-button>
    </div>
  </ng-template>
</ion-modal>
