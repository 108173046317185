<ion-header class="ion-no-border">
  <ion-toolbar>
    <div class="list-header" [class.hidden]="showMap">
      <div class="title" *ngIf="showTitle || showCloseButton">
        <h1 *ngIf="showTitle">{{ "AREA_SELECTION_MODAL_PAGE.TITLE" | translate }}</h1>
        <ion-icon tappable *ngIf="showCloseButton" src="/assets/custom-icons/b-times.svg" (click)="onDismiss()"></ion-icon>
      </div>
      <div data-cy="search-bar" class="search-bar list-search-bar" [class.with-no-title]="!showTitle">
        <ion-icon src="/assets/custom-icons/b-search.svg"></ion-icon>
        <input
          (keyup)="onFilterAreas($event)"
          (keyup.enter)="onHideKeyboard()"
          [placeholder]="'AREA_SELECTION_MODAL_PAGE.SEARCH_BAR_PLACEHOLDER' | translate"
        />
      </div>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding" [class.map-visible]="showMap">
  <div class="loading-spinner" *ngIf="!isPageReady">
    <ion-spinner duration="1000" name="dots"></ion-spinner>
  </div>

  <div class="list-wrapper" [class.hidden]="showMap" *ngIf="isPageReady">
    <app-saved-addresses
      *ngIf="savedAddresses?.length"
      [query]="query"
      [savedAddresses]="savedAddresses"
      [selected]="createdFromArea ? null : address"
      (selectAddress)="onSelectAddress($event)"
    ></app-saved-addresses>

    <app-areas-by-province
      *ngIf="provinces?.length"
      [query]="query"
      [provinces]="provinces"
      [selected]="createdFromArea ? address?.area : null"
      (areaSelect)="onSelectArea($event)"
    ></app-areas-by-province>
  </div>

  <div class="map-wrapper" [class.hidden]="!showMap" *ngIf="isPageReady">
    <div class="search-bar-wrapper">
      <div tappable (click)="onOpenMapAutocompletePage()" class="search-bar map-search-bar">
        <ion-icon src="/assets/custom-icons/b-search.svg"></ion-icon>
        <input readonly [placeholder]="'AREA_SELECTION_MODAL_PAGE.SEARCH_BAR_PLACEHOLDER' | translate" />
      </div>
    </div>

    <app-google-maps
      [lat]="address?.geography?.latitude"
      [lng]="address?.geography?.longitude"
      [area]="address?.area"
      [showMapTypeControl]="false"
      [showUserLocationInMap]="showUserLocationInMap"
      (mapError)="onMapError()"
      (locationUpdated)="onHandleSelectedLocationUpdated($event)"
    ></app-google-maps>
  </div>
</ion-content>

<ion-footer *ngIf="allowSelectionOnMap || !returnWhenSelected">
  <ion-toolbar>
    <ion-row *ngIf="allowSelectionOnMap">
      <ion-col class="ion-no-padding">
        <ion-button
          color="grey"
          expand="block"
          class="custom-button"
          *ngIf="!showMap"
          [disabled]="!isPageReady"
          (click)="onToggleListAndMapView()"
        >
          <span>{{ "AREA_SELECTION_MODAL_PAGE.SELECT_LOCATION_ON_MAP" | translate }}</span>
        </ion-button>

        <ion-button
          color="grey"
          expand="block"
          class="custom-button"
          *ngIf="showMap"
          (click)="onToggleListAndMapView()"
          [disabled]="!isPageReady"
        >
          <span>{{ "AREA_SELECTION_MODAL_PAGE.SELECT_AREA_FROM_LIST" | translate }}</span>
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="!returnWhenSelected">
      <ion-col class="ion-no-padding">
        <ion-button
          [disabled]="!isPageReady"
          (click)="onSelectAreaOrAddressAndDismiss()"
          class="custom-button"
          expand="block"
          color="green"
          data-cy="select-location-button"
        >
          <ng-container *ngIf="!isLoadingLocation && (address?.name || address?.area)">
            {{ address.name ? address.name : address.area?.name }}
          </ng-container>

          <ion-icon
            color="white"
            class="custom-button-icon spinning-icon"
            src="/assets/custom-icons/b-spinner.svg"
            *ngIf="isLoadingLocation"
          ></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-toolbar>
</ion-footer>
