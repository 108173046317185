import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';

import { IonTextarea, ViewWillEnter } from '@ionic/angular';

import { ModalService } from '../../core/services/modal.service';

export const SpecialRequestModalPageIdentifier = 'special-request-modal';

@Component({
  selector: 'app-special-request-modal',
  templateUrl: './special-request-modal.page.html',
  styleUrls: ['./special-request-modal.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SpecialRequestModalPage implements ViewWillEnter {
  @Input()
  public name: string;

  @Input()
  public comment: string = null;

  @Input()
  public isGiftCard: boolean;

  @ViewChild('textAreaElement')
  public textAreaElement: IonTextarea;

  constructor(private modalService: ModalService) {}

  ionViewWillEnter() {
    void this.textAreaElement?.setFocus();
  }

  public onDismiss(comment?: string): Promise<boolean> {
    return this.modalService.dismissModal({ id: SpecialRequestModalPageIdentifier, data: comment });
  }

  public onSubmit(): void {
    void this.onDismiss(this.comment);
  }
}
