import { forkJoin, Observable, of } from 'rxjs';
import { delay, first } from 'rxjs/operators';

import { LanguageCode } from './models/language.model';
import { MultiLanguageValue } from './models/multi-language-value.model';

const SCROLL_ASSIST_SPEED = 0.3;
const SCROLLING_DURATION = 400;

export class Helpers {
  public static readonly inAppBrowserConfig =
    'location=no,usewkwebview=yes,enableViewportScale=yes,closebuttoncaption=Cancel,toolbarposition=bottom';

  private static decimalPrecision = 3;

  public static sumWithPrecision(...numbers: Array<number>): number {
    const total = numbers.reduce((t, value) => t + value, 0);
    return +total.toFixed(Helpers.decimalPrecision);
  }

  public static subtractWithPrecision(firstNumber: number, ...rest: Array<number>): number {
    const total = firstNumber - rest.reduce((t, value) => t + value, 0);
    return +total.toFixed(Helpers.decimalPrecision);
  }

  public static multiplyWithPrecision(...numbers: Array<number>): number {
    const total = numbers.reduce((t, value) => t * value, 1);
    return +total.toFixed(Helpers.decimalPrecision);
  }

  public static runObservableWithMinDuration<T>(call: Observable<T>, minDurationInMs: number): Observable<[T, boolean]> {
    return forkJoin([call, of(true).pipe(delay(minDurationInMs), first())]);
  }

  public static getValues<T>(params: { fromEnum: T; excluding?: T[keyof T] | Array<T[keyof T]> }): Array<T[keyof T]> {
    if (!params.fromEnum) {
      throw new Error('The fromEnum parameter is required');
    }

    const enumObject = params.fromEnum;

    let valuesToExclude = [];
    const valuesToReturn: Array<T[keyof T]> = [];

    // Use an array for the excluded elements even if it's only one
    if (params.excluding !== undefined && params.excluding !== null) {
      valuesToExclude = params.excluding instanceof Array ? params.excluding : [params.excluding];
    }

    for (const enumMember in enumObject) {
      if (enumObject.hasOwnProperty(enumMember)) {
        const valueProperty = parseInt(enumMember, 10) >= 0;

        // In Typescript the enum
        //
        // enum FooBar {
        //   foo = 0,
        //   bar = 1
        // }
        //
        // is transpiled as
        //
        // {
        //   0: "foo",
        //   1: "bar",
        //   "foo": 0,
        //   "bar": 1
        // }
        //
        // We are interested only in enumMembers that are not numeric
        // properties so that FooBar[enumMember] = enumValue. Otherwise
        // this helper won't be compatible with enums having string values

        if (!valueProperty) {
          const shouldExclude = valuesToExclude.findIndex((enumValue) => enumValue === enumObject[enumMember]) !== -1;

          if (!shouldExclude) {
            valuesToReturn.push(enumObject[enumMember]);
          }
        }
      }
    }

    return valuesToReturn;
  }

  public static clone<T>(obj: T): T {
    if (!obj) {
      return null;
    }

    return JSON.parse(JSON.stringify(obj)) as T;
  }

  public static areFlagEnumValuesEquivalent(action: number, anotherAction: number): boolean {
    return (action & anotherAction) === anotherAction;
  }

  public static areArraysEquivalent(
    array1: Array<number>,
    array2: Array<number>,
    { ignoreElementsPosition } = { ignoreElementsPosition: true },
  ): boolean {
    if (!Array.isArray(array1) || !Array.isArray(array2) || array1.length !== array2.length) {
      return false;
    }
    const sortedArray1 = ignoreElementsPosition ? array1.concat().sort() : array1;
    const sortedArray2 = ignoreElementsPosition ? array2.concat().sort() : array2;

    for (let i = 0; i < sortedArray1.length; i++) {
      if (sortedArray1[i] !== sortedArray2[i]) {
        return false;
      }
    }

    return true;
  }

  // Code stolen from Ionic source code to mimic the speed of Ionic's scrolling methods
  // https://github.com/ionic-team/ionic-v3/blob/master/src/components/input/input.ts#L805
  public static getScrollingDuration(distance: number): number {
    return Math.min(SCROLLING_DURATION, Math.max(150, distance / SCROLL_ASSIST_SPEED));
  }

  public static padNumber(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }

  public static getNumberParamFromLink(paramName: string, link: string, isDeepLink: boolean = false): number {
    const deepLinkRegex = new RegExp(`${paramName}+\\/([\\d]+)[^\\s]?`);
    const urlRegex = new RegExp(`${paramName}+\\=([\\d]+)[^\\s]?`);

    const results = isDeepLink ? deepLinkRegex.exec(link) : urlRegex.exec(link);
    return results && results[1] ? +results[1] : null;
  }

  public static getStringParamFromLink(paramName: string, link: string, isDeepLink: boolean = false): string {
    const deepLinkRegex = new RegExp(`${paramName}+\\/([\\w]+)[^\\s]?`);
    const urlRegex = new RegExp(`${paramName}+\\=([\\w]+)[^\\s]?`);

    const results = isDeepLink ? deepLinkRegex.exec(link) : urlRegex.exec(link);
    return results && results[1] ? results[1] : null;
  }

  public static isPropertyLocalized(stringifiedProp: string): boolean {
    return !!stringifiedProp && stringifiedProp.includes('"k":"en"') && stringifiedProp.includes('"k":"ar"');
  }

  public static getLocalizedMultiLanguageValue(json: MultiLanguageValue, languageCode: LanguageCode): string {
    return json.find((name) => name.k === languageCode)?.v ?? '';
  }

  public static areEqual<S>(prevState: S, nextState: S): boolean {
    return JSON.stringify(prevState) === JSON.stringify(nextState);
  }

  public static logWithTimeStamp(message: string | unknown): void {
    console.log(`[Universal App | ${new Date().toISOString()}] ==> ${message}`);
  }

  public static joinStringArray(list: Array<string>, params: { joinSeparator: string; joinSeparatorLastString?: string }): string {
    if (!list?.length) {
      return '';
    }

    const { joinSeparator, joinSeparatorLastString } = params;
    const joinSeparatorOrDefault = joinSeparator || ', ';
    const joinSeparatorLastStringOrDefault = joinSeparatorLastString || joinSeparatorOrDefault;

    if (list.length === 0) {
      return '';
    } else if (list.length === 1) {
      return list[0];
    } else if (list.length === 2) {
      return list.join(joinSeparatorLastStringOrDefault);
    } else {
      const lastItem = list.pop();
      return `${list.join(joinSeparatorOrDefault)}${joinSeparatorLastStringOrDefault}${lastItem}`;
    }
  }
}
