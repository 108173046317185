import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import { IonContent, ViewDidEnter, ViewWillEnter } from '@ionic/angular';

import { KeyboardPlugin } from '../../core/services/native-plugins/keyboard.plugin';

import { forkJoin, of, Subject } from 'rxjs';
import { catchError, debounceTime, filter, switchMap, takeUntil, tap } from 'rxjs/operators';

import {
  GoogleMapsAutocompleteModalPage,
  GoogleMapsAutocompleteModalPageIdentifier,
} from '../google-maps-autocomplete-modal/google-maps-autocomplete-modal.page';

import { Address } from '../../core/models/address.model';
import { Area } from '../../core/models/area.model';
import { Province } from '../../core/models/province.model';

import { AccountService } from '../../core/services/account.service';
import { AddressService } from '../../core/services/address.service';
import { AnalyticsService } from '../../core/services/analytics.service';
import { AreasService } from '../../core/services/areas.service';
import { LoggerService } from '../../core/services/logger.service';
import { ModalService } from '../../core/services/modal.service';
import { OverlayService } from '../../core/services/overlay.service';
import { SettingsService } from '../../core/services/settings.service';
import { ValidationService } from '../../core/services/validation.service';

import { AnalyticsConfig, TOKEN_ANALYTICS_CONFIG } from '../../core/config/analytics.config';

export const AreaSelectionModalPageIdentifier = 'area-selection-modal';

@Component({
  selector: 'app-area-selection-modal',
  templateUrl: './area-selection-modal.page.html',
  styleUrls: ['./area-selection-modal.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AreaSelectionModalPage implements OnInit, OnDestroy, ViewWillEnter, ViewDidEnter {
  @ViewChild(IonContent)
  public content: IonContent;

  @Input()
  public showCloseButton: boolean;

  @Input()
  public showTitle = true;

  @Input()
  public showSavedAddresses = true;

  @Input()
  public allowSelectionOnMap = true;

  @Input()
  public returnWhenSelected = false;

  @Input()
  public showUserLocationInMap: boolean;

  @Input()
  public areaSelection$?: Subject<boolean>;

  public address: Address;
  public createdFromArea: boolean;

  public query = '';
  public showMap = false;

  public isPageReady = false;
  public isLoadingLocation: boolean;
  public provinces: Array<Province>;
  public savedAddresses: Array<Address>;

  private scrollElement: HTMLElement;
  private unsubscribe$ = new Subject<void>();
  private updateAreaFromSelectedLocationOnMap$ = new Subject<{ latitude: number; longitude: number }>();

  constructor(
    private keyboardPlugin: KeyboardPlugin,
    private modalService: ModalService,
    private overlayService: OverlayService,
    private validationService: ValidationService,
    private accountService: AccountService,
    private areasService: AreasService,
    private analyticsService: AnalyticsService,
    private loggerService: LoggerService,
    private settingsService: SettingsService,
    private addressService: AddressService,
    @Inject(TOKEN_ANALYTICS_CONFIG) private analyticsConfig: AnalyticsConfig,
  ) {}

  @Input()
  public set selectedAddress(address: Address) {
    this.updateSelectedAddress(address);
  }

  ngOnInit() {
    this.showMap = this.settingsService.shouldShowMapFirstInAreaSelectionModalPage();

    this.showCloseButton = this.showCloseButton ?? true;
    this.showUserLocationInMap = this.showUserLocationInMap ?? true;

    this.updateAreaFromSelectedLocationOnMap$
      .pipe(
        filter(() => this.showMap),
        tap(() => {
          this.isLoadingLocation = true;
        }),
        debounceTime(300),
        switchMap(({ latitude, longitude }) =>
          this.areasService.getDefaultAreasBasedOnCurrentLocation(latitude, longitude).pipe(
            tap((locations) => {
              this.isLoadingLocation = false;

              if (!locations?.length) {
                return;
              }

              this.updateSelectedAddress(this.addressService.createNewAddressFromCoordinates({ area: locations[0], latitude, longitude }));
            }),
            catchError((error: HttpErrorResponse) => {
              this.isLoadingLocation = false;

              this.loggerService.info({
                component: 'AreaSelectionPage',
                message: "couldn't get default area based on location",
                details: { error, messageShownToUser: this.validationService.getErrorMessage(error) },
              });

              return of(null);
            }),
          ),
        ),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  ionViewWillEnter(): void {
    void this.content.getScrollElement().then((scrollElement) => {
      this.scrollElement = scrollElement;
    });
  }

  ionViewDidEnter() {
    this.trackPageView();
    this.loadAreasAndAddresses();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  public onToggleListAndMapView(): void {
    const maybeScroll = !this.showMap
      ? this.scrollElement.scrollTop <= 5
        ? this.content.scrollToPoint(0, 5)
        : Promise.resolve()
      : this.scrollElement.scrollTop <= 5
      ? this.content.scrollToPoint(0, 0)
      : Promise.resolve();

    void maybeScroll.then(() => {
      this.showMap = !this.showMap;
    });
  }

  public onSelectAreaOrAddressAndDismiss(): void {
    this.areaSelection$?.next(true);
    void this.onDismiss(this.address);
  }

  public onOpenMapAutocompletePage(): void {
    void this.modalService.showModal({
      component: GoogleMapsAutocompleteModalPage,
      id: GoogleMapsAutocompleteModalPageIdentifier,
    });
  }

  public onDismiss(params?: Address): Promise<boolean> {
    return this.modalService.dismissModal({ data: params, id: AreaSelectionModalPageIdentifier });
  }

  public onSelectArea(selectedArea?: Area): void {
    this.updateSelectedAddress(this.addressService.createNewAddressFromArea(selectedArea));

    if (this.returnWhenSelected) {
      this.onSelectAreaOrAddressAndDismiss();
    }
  }

  public onSelectAddress(selectedAddress?: Address): void {
    this.updateSelectedAddress(selectedAddress);

    if (this.returnWhenSelected) {
      this.onSelectAreaOrAddressAndDismiss();
    }
  }

  public onFilterAreas(event: KeyboardEvent): void {
    if (!this.isPageReady) {
      return;
    }

    this.query = (event.target as HTMLInputElement).value;
  }

  public onHideKeyboard(): void {
    this.keyboardPlugin.hideKeyboard();
  }

  public onMapError(): void {
    this.showMap = false;
  }

  public onHandleSelectedLocationUpdated({ latitude, longitude }: { latitude: number; longitude: number }): void {
    this.updateAreaFromSelectedLocationOnMap$.next({ latitude, longitude });
  }

  private loadAreasAndAddresses(): void {
    const isLoggedIn = this.accountService.isLoggedIn();

    const initDataSources =
      isLoggedIn && this.showSavedAddresses
        ? [this.areasService.getAreas(), this.accountService.getUserSavedAddresses()]
        : [this.areasService.getAreas()];

    forkJoin(initDataSources)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ([areas, addresses]: [Array<Province>, Array<Address>]) => {
          this.provinces = areas;
          this.savedAddresses = addresses?.length ? addresses : null;

          // Select the first area by default if there's no selected area
          // or address since the text of the button needs an area to be
          // selected
          if (!this.address?.area) {
            this.updateSelectedAddress(this.addressService.createNewAddressFromArea(this.provinces[0].areas[0]));
          }

          this.isPageReady = true;
        },
        error: (error: HttpErrorResponse) => {
          const errorMessage = this.validationService.getErrorMessage(error);

          this.loggerService.info({
            component: 'AreaSelectionPage',
            message: "couldn't initialize page",
            details: { error, messageShownToUser: errorMessage },
          });

          void this.onDismiss();
          this.overlayService.showToast({ message: errorMessage, showCloseButton: true, type: 'error' });
        },
      });
  }

  private trackPageView(): void {
    void this.analyticsService.trackView({ name: this.analyticsConfig.pageName.areaSelectionPage });
  }

  private updateSelectedAddress(address: Address): void {
    this.address = address;
    this.createdFromArea = this.addressService.createdFromArea(address);
  }
}
