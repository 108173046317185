import { Injectable } from '@angular/core';

import { BackButtonEmitter } from '@ionic/angular/common/providers/platform';

import { Observable, Subject } from 'rxjs';
import { EMPTY_SUBSCRIPTION } from 'rxjs/internal/Subscription';

import { PlatformService } from './platform.service';

@Injectable()
export class PlatformServerService extends PlatformService {
  public get isServer(): boolean {
    return true;
  }

  public get isIos(): boolean {
    return false;
  }

  public get isAndroid(): boolean {
    return false;
  }

  public get areCordovaPluginsAvailable(): boolean {
    return false;
  }

  public get isBrowser(): boolean {
    return true;
  }

  public get isBrowserMobile(): boolean {
    return false;
  }

  public get isCordova(): boolean {
    return false;
  }

  public get isCordovaMobile(): boolean {
    return false;
  }

  public get isCordovaTablet(): boolean {
    return false;
  }

  public get pause(): Observable<void> {
    return new Subject<void>().asObservable();
  }

  public get resume(): Observable<void> {
    return new Subject<void>().asObservable();
  }

  public get backButton(): BackButtonEmitter {
    return {
      subscribeWithPriority: () => EMPTY_SUBSCRIPTION,
    } as unknown as BackButtonEmitter;
  }

  public wait(_: number): Promise<void> {
    return Promise.resolve();
  }

  public preloadImage(_imageUrl: string, _duration: number = 300): Promise<{ loaded: boolean; width?: number; height?: number }> {
    return Promise.resolve({ loaded: false });
  }

  public ready(): Promise<string> {
    return Promise.resolve('');
  }

  public height(): number {
    return 0;
  }

  public width(): number {
    return 0;
  }
}
