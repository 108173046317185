import { Geography } from './geography.model';

export interface Country {
  value: CountryId;
  name: string;
  code: CountryCode;
  flagUrl: string;
  contactPhoneNumber: string;
  phoneCountryCodeValidationPattern: RegExp;
  phoneLocalNumberValidationPattern: RegExp;
  phoneNumberValidationPattern: RegExp;
  geography?: Geography;
}

export const COUNTRY = {
  kuwait: { id: 0, code: 'kw' },
  uae: { id: 1, code: 'ae' },
} as const;

export const COUNTRY_IDS = [COUNTRY.kuwait.id, COUNTRY.uae.id] as const;
export const COUNTRY_CODES = [COUNTRY.kuwait.code, COUNTRY.uae.code] as const;

export type CountryId = (typeof COUNTRY_IDS)[number];
export type CountryCode = (typeof COUNTRY_CODES)[number];
