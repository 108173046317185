import { transition, trigger } from '@angular/animations';

import { AnimationTransition, applyFadeInTranslateAnimation } from '../../core/animations';

export const ANIMATION_DISTANCE_IN_PX = 120;

export const OrderReviewModalPageAnimations = [
  trigger('animatedStep', [
    transition(AnimationTransition.Enter, applyFadeInTranslateAnimation({ duration: 440, distance: '{{ distance }}', axis: 'X' })),
  ]),
];
