import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { LogInModalPage, LogInModalPageIdentifier } from '../../modals/log-in-modal/log-in-modal.page';
import { SocialLoginModalPage, SocialLoginModalPageIdentifier } from '../../modals/social-login-modal/social-login-modal.page';

import { ModalService } from './modal.service';
import { SettingsService } from './settings.service';
import { PlatformService } from './ssr/platform.service';

@Injectable({ providedIn: 'root' })
export class LoginService {
  constructor(private settingsService: SettingsService, private platformService: PlatformService, private modalService: ModalService) {}

  public showSocialLogin(message?: string): Promise<void> {
    if (this.platformService.isBrowser || !this.settingsService.isFirebaseSocialLoginFeatureEnabled()) {
      return this.modalService.showModal({ component: LogInModalPage, id: LogInModalPageIdentifier });
    }

    return this.modalService
      .showSheet<Observable<void>>({
        id: SocialLoginModalPageIdentifier,
        component: SocialLoginModalPage,
        breakpoints: message ? [0, 0.75, 0.95] : [0, 0.5, 0.95],
        initialBreakpoint: message ? 0.75 : 0.5,
        cssClass: 'auto-height',
        componentProps: { message },
      })
      .then((dismissed) => {
        if (dismissed) {
          return new Promise<void>((resolve) => dismissed.subscribe(() => resolve()));
        }
      });
  }
}
