import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { SplashScreenPlugin } from '../services/native-plugins/splash-screen.plugin';

import { from, Observable, throwError, TimeoutError, timer } from 'rxjs';
import { retry, switchMap, timeout } from 'rxjs/operators';

import {
  TimeoutRetryModalDismissReason,
  TimeoutRetryModalPage,
  TimeoutRetryModalPageIdentifier,
} from '../../modals/timeout-retry-modal/timeout-retry-modal.page';

import { ModalService } from '../services/modal.service';
import { PlatformService } from '../services/ssr/platform.service';

import {
  BACKOFF_DURATION_IN_SECONDS,
  MAX_RETRY_ATTEMPTS,
  REQUEST_TIMEOUT_IN_MILLISECONDS,
  REQUEST_TIMEOUT_IN_MILLISECONDS_WEB,
  shouldSkipHttpInterceptors,
} from '../config/app.config';

const ACTIVE_ORDERS_PARTIAL_URL = 'orders/active-orders';

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
  private switchToManualRetries: boolean;

  constructor(private injector: Injector, private splashScreenPlugin: SplashScreenPlugin) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const platformService = this.injector.get(PlatformService);

    if (
      shouldSkipHttpInterceptors(request.url) ||
      request.method.toUpperCase() !== 'GET' ||
      request.url.includes(ACTIVE_ORDERS_PARTIAL_URL) ||
      platformService.isServer
    ) {
      return next.handle(request);
    }

    const timeToWaitInMilliSeconds =
      Number(request.headers.get('timeout')) ||
      (platformService.isBrowser ? REQUEST_TIMEOUT_IN_MILLISECONDS_WEB : REQUEST_TIMEOUT_IN_MILLISECONDS);

    this.switchToManualRetries = false;

    // Send the origial request with timeout & retry operators
    return next.handle(request).pipe(timeout(timeToWaitInMilliSeconds), retry({ delay: this.autoRetryStrategy() }));
  }

  // operator that auto retires on timeout errors with a backoff duration
  private autoRetryStrategy(): (error: Error, retryCount: number) => Observable<number> {
    return (error, retryCount) => {
      // throw on any errors other than timeout
      if (!(error instanceof TimeoutError)) {
        return throwError(() => error);
      }

      // first retry attempt without any delay
      if (retryCount === 1) {
        return timer(0);
      }

      const waitDurationInSeconds =
        retryCount >= MAX_RETRY_ATTEMPTS || this.switchToManualRetries ? 0 : Math.pow(2, retryCount - 2) * BACKOFF_DURATION_IN_SECONDS;

      this.splashScreenPlugin.hide();
      const modalService = this.injector.get(ModalService);

      // show an alert with a countdown for next retry & buttons to retry on demand or cancel pending retry attempts
      const retryAttempt = modalService
        .showAlert<TimeoutRetryModalDismissReason>({
          component: TimeoutRetryModalPage,
          id: TimeoutRetryModalPageIdentifier,
          componentProps: { waitDurationInSeconds },
          backdropDismiss: false,
        })
        .then((reason) => {
          if (reason === 'cancel') {
            return throwError(() => null);
          }

          if (reason === 'manualRetry') {
            this.switchToManualRetries = true;
          }

          return timer(0);
        });

      return from(retryAttempt).pipe(switchMap((x) => x));
    };
  }
}
