import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';

import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

import { LoggerService } from './logger.service';

@Injectable({ providedIn: 'root' })
export class StorageService {
  private db: Storage = null;

  constructor(private storage: Storage, private loggerService: LoggerService) {}

  public async initialize(): Promise<void> {
    try {
      await this.storage.defineDriver(CordovaSQLiteDriver);
    } catch (error: unknown) {
      void this.loggerService.error({ component: 'StorageService', message: 'Unable to definde SQLite driver', error });
    }

    try {
      this.db = await this.storage.create();
    } catch (error: unknown) {
      void this.loggerService.error({ component: 'StorageService', message: 'Unable to initialize storage', error });
    }
  }

  public get<T>(key: string): Promise<T> {
    if (!this.db) {
      return Promise.resolve(undefined as T);
    }

    return this.db
      .get(key)
      .then((value) => value as T)
      .catch((error: unknown) => {
        void this.loggerService.error({ component: 'StorageService', message: 'Unable to get key ${key}', error });
        return Promise.resolve(undefined as T);
      });
  }

  public set(key: string, value: unknown): Promise<void> {
    if (!this.db) {
      return Promise.resolve();
    }

    return this.db
      .set(key, value)
      .then(() => {})
      .catch((error: unknown) => {
        void this.loggerService.error({ component: 'StorageService', message: 'Unable to set key ${key}', error });
        return Promise.resolve();
      });
  }
}
