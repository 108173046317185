export enum PaymentMethod {
  None = 0,
  Knet = 1,
  Visa = 2,
  MasterCard = 3,
  Cash = 4,
  Online = 5,
  AmericanExpress = 6,
  Mada = 7,
  Sadad = 8,
  ApplePay = 9,
  Wallet = 10,
  GooglePay = 11,
  Deema = 12,
}
