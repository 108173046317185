import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { TranslateService } from '@ngx-translate/core';

import { DomController, IonContent, ViewDidEnter, ViewWillEnter } from '@ionic/angular';

import { StatusBarPlugin } from '../../core/services/native-plugins/status-bar.plugin';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AreaSelectionModalPage, AreaSelectionModalPageIdentifier } from '../area-selection-modal/area-selection-modal.page';
import {
  DimensionRequirementsModalPage,
  DimensionRequirementsModalPageIdentifier,
  DimensionRequirementsModalPageParams,
} from '../dimension-requirements-modal/dimension-requirements-modal.page';
import { EditWishlistModalPage, EditWishlistModalPageIdentifier } from '../edit-wishlist-modal/edit-wishlist-modal.page';
import { ImagesPreviewModalPage, ImagesPreviewModalPageIdentifier } from '../images-preview-modal/images-preview-modal.page';
import {
  ItemAvailabilityDateTimePickerModalPage,
  ItemAvailabilityDateTimePickerModalPageIdentifier,
  ItemAvailabilityDateTimePickerModalPageParams,
  ItemAvailabilityDateTimePickerModalPageResult,
} from '../item-availability-date-time-picker-modal/item-availability-date-time-picker-modal.page';

import { Availability } from '../../core/enums/availability.enum';
import { MenuItemRequirement } from '../../core/enums/menu-item-requirement.enum';
import { OrderMenuItemSourceType } from '../../core/enums/order-menu-item-source-type.enum';
import { PricingMethod } from '../../core/enums/pricing-method.enum';
import { SelectionInput } from '../../core/enums/selection-input.enum';
import { WarningSeverity } from '../../core/enums/warning-severity.enum';
import { WarningType } from '../../core/enums/warning-type.enum';

import { Address } from '../../core/models/address.model';
import { AnimationState as AnimatedObject } from '../../core/models/animation-state.model';
import { CartValidatedItem } from '../../core/models/cart-response.model';
import { CartItem, CartItemAddOn, CartItemOption } from '../../core/models/cart.model';
import { CustomScrollEvent, ScrollEventDetail } from '../../core/models/custom-scroll-event.model';
import { LoyaltyProgramNextRewardDetails } from '../../core/models/loyalty-program-next-reward-details.model';
import { MenuItemAddonGroup } from '../../core/models/menu-item-addon-group.model';
import { MenuItemAddon } from '../../core/models/menu-item-addon.model';
import { MenuItemDetails } from '../../core/models/menu-item-details.model';
import { MenuItemOptionGroup } from '../../core/models/menu-item-option-group.model';
import { MenuItemOption } from '../../core/models/menu-item-option.model';
import { MenuItemPrice } from '../../core/models/menu-item-price.model';
import { MultiLanguageValue } from '../../core/models/multi-language-value.model';
import { NewCartItem } from '../../core/models/new-cart-item.model';
import { VendorDetails, VendorDetailsReOrderItem, VendorMenuItem } from '../../core/models/vendor-details.model';
import { Warning } from '../../core/models/warning.model';
import { WishlistDetails } from '../../core/models/wishlist-details.model';
import { WishlistItem } from '../../core/models/wishlist-item.model';
import { Wishlist } from '../../core/models/wishlist.model';

import { AccountService } from '../../core/services/account.service';
import { AnalyticsService } from '../../core/services/analytics.service';
import { CartService } from '../../core/services/cart.service';
import { DateTimeService } from '../../core/services/date-time.service';
import { DeepLinkService } from '../../core/services/deep-link.service';
import { LoggerService } from '../../core/services/logger.service';
import { LoyaltyProgramService } from '../../core/services/loyalty-program.service';
import { ModalService } from '../../core/services/modal.service';
import { OverlayService } from '../../core/services/overlay.service';
import { ReferralProgramService } from '../../core/services/referral-program.service';
import { SafeAreaInsetsService } from '../../core/services/safe-area-insets.service';
import { LanguageDirection, SettingsService } from '../../core/services/settings.service';
import { PlatformService } from '../../core/services/ssr/platform.service';
import { UniversalLinksService } from '../../core/services/universal-links.service';
import { ValidationService } from '../../core/services/validation.service';
import { VendorsService } from '../../core/services/vendors.service';

import { AnalyticsConfig, TOKEN_ANALYTICS_CONFIG } from '../../core/config/analytics.config';
import { AppConfig, TOKEN_CONFIG } from '../../core/config/app.config';

import { LocalCurrencyPipe } from '../../shared/pipes/local-currency.pipe';

import { Helpers } from '../../core/helpers';

import { AnimationState } from '../../core/animations';
import { MenuItemDetailsModalComponentAnimations } from './menu-item-details-modal.animations';

import SwiperCore, { Pagination, Swiper } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { AlertModalButton } from '../../core/models/modal-options';

SwiperCore.use([Pagination]);

export const MenuItemDetailsModalPageIdentifier = 'menu-item-details-modal';

const ITEM_IMAGE_HEIGHT = 240;
const ITEM_IMAGE_MAX_HEIGHT = 360;

export interface MenuItemDetailsModalParams {
  itemId: number;
  vendorDetails: VendorDetails;

  selectedServiceType: number;
  selectedAddress?: Address;
  selectedDateTime?: string;
  isOrderForNowSelected?: boolean;

  sourceId?: number;
  sourceType?: OrderMenuItemSourceType;
  showAvailabilityMessage?: boolean;

  incrementQuantity?: boolean;
  decrementQuantity?: boolean;
  shouldTrackCleverTapEvents?: boolean;
  initializeFromRecentlyOrderedItems?: boolean;

  currentWishlist?: WishlistDetails;
  shouldUpdateCurrentWishlist?: boolean;
}

export interface MenuItemDetailsModalResult {
  addedToCart: boolean;
  updatedWishlist: boolean;
  selectedDateTime?: string;
  isOrderForNowSelected?: boolean;
}

@Component({
  selector: 'app-menu-item-details-modal',
  templateUrl: 'menu-item-details-modal.component.html',
  styleUrls: ['menu-item-details-modal.component.scss'],
  animations: [...MenuItemDetailsModalComponentAnimations],
  encapsulation: ViewEncapsulation.None,
})
export class MenuItemDetailsModalComponent implements OnInit, AfterViewInit, ViewWillEnter, ViewDidEnter, OnDestroy {
  @Input()
  public itemDetailsParams: MenuItemDetailsModalParams;

  @ViewChild(IonContent)
  public content: IonContent;

  @ViewChild('swiper')
  public swiper?: SwiperComponent;

  @ViewChild('navbar', { read: ElementRef })
  public navbar: ElementRef<HTMLElement>;

  @ViewChild('menuItemImageContainer', { read: ElementRef })
  public menuItemImageContainer: ElementRef<HTMLElement>;

  public vendorDetails: VendorDetails;
  public vendorMenuItem: VendorMenuItem;
  public menuItemDetails: MenuItemDetails;

  public isUpdatingCartItem: boolean;
  public isUpdatingWishlistItem: boolean;

  public sourceId: number;
  public sourceType: OrderMenuItemSourceType;

  public isMinQuantity: boolean;
  public isMaxQuantity: boolean;
  public hasOptions: boolean;
  public hasAddOns: boolean;
  public areOptionsRequired: boolean;
  public areAddOnsRequired: boolean;
  public showAvailabilityMessage: boolean;

  public currentCost: number;
  public currentQuantity: number;
  public currentQuantityPrice: MenuItemPrice;

  public requirements: Array<{
    text: string;
    secondaryText: string;
    iconName: string;
    type?: MenuItemRequirement;
    callToAction?: () => void;
  }>;
  public dimensionsRequirementText: string;
  public specialRequests: string;
  public isAddCardSelected: boolean;
  public isAdditionalCardSupported: boolean;
  public isFemaleServiceSelected: boolean;
  public isFemaleServicePreSelected: boolean;

  public isBrowser: boolean;
  public isLoggedIn: boolean;
  public isLoading: boolean;
  public imageHeight: number;
  public shouldHideFooter = false;
  public fullDescriptionFormatted: SafeHtml;
  public currentLanguageDirection: LanguageDirection;

  public navbarHeight: number;
  public showNavbarBackground = false;
  public showNavbarPaddingTop: boolean;

  public Availability = Availability;
  public PricingMethod = PricingMethod;
  public SelectionInput = SelectionInput;
  public AnimationState = AnimationState;

  public wishlists: Array<Wishlist>;
  public updatedWishlistId: number;
  public updatingWishlistId: number;
  public isWishlistsModalOpen = false;
  public currentWishlist: WishlistDetails;
  public shouldUpdateCurrentWishlist: boolean;

  public selectedAddress: Address;
  public selectedDateTime: string;
  public isOrderForNowSelected: boolean;

  public loyaltyProgramNextRewardDetails: LoyaltyProgramNextRewardDetails;

  private localCurrencyPipe: LocalCurrencyPipe;
  private reOrderItemDetails: VendorDetailsReOrderItem;
  private initializeFromRecentlyOrderedItems: boolean;

  private shouldTrackCleverTapEvents: boolean;
  private shouldIncrementQuantity: boolean;
  private shouldDecrementQuantity: boolean;

  private canTrackAddToCartAttemptEventInCurrentStep = true;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private ngZone: NgZone,
    public renderer: Renderer2,
    public domCtrl: DomController,
    private domSanitizer: DomSanitizer,
    private modalService: ModalService,
    private vendorsService: VendorsService,
    private settingsService: SettingsService,
    private platformService: PlatformService,
    private overlayService: OverlayService,
    private loggerService: LoggerService,
    private cartService: CartService,
    private analyticsService: AnalyticsService,
    private validationService: ValidationService,
    private referralProgramService: ReferralProgramService,
    private universalLinksService: UniversalLinksService,
    private translateService: TranslateService,
    private dateTimeService: DateTimeService,
    private deepLinkService: DeepLinkService,
    private accountService: AccountService,
    private safeAreaInsetsService: SafeAreaInsetsService,
    private loyaltyProgramService: LoyaltyProgramService,
    private statusBarPlugin: StatusBarPlugin,
    @Inject(TOKEN_CONFIG) private config: AppConfig,
    @Inject(TOKEN_ANALYTICS_CONFIG) private analyticsConfig: AnalyticsConfig,
  ) {}

  private get minMenuItemCost(): number {
    return this.menuItemDetails.minimumCost || 0;
  }

  private get selectedAddonsCost(): number {
    if (!this.hasAddOns) {
      return 0;
    }

    let addonsTotalCost = 0;
    this.menuItemDetails.addOnGroups.forEach((addonGroup) => {
      addonGroup.addons.forEach((addon) => {
        addonsTotalCost = Helpers.sumWithPrecision(addonsTotalCost, addon.quantity * addon.price);
      });
    });

    return addonsTotalCost;
  }

  private get selectedOptionsCost(): number {
    if (!this.hasOptions) {
      return 0;
    }

    let optionsTotalCost = 0;
    this.menuItemDetails.optionsGroups.forEach((optionGroup) => {
      optionGroup.options.forEach((option) => {
        optionsTotalCost = Helpers.sumWithPrecision(
          optionsTotalCost,
          option.isFixedCost ? option.quantity * option.price : option.quantity * option.price * this.currentQuantityPrice.quantity,
        );
      });
    });

    return optionsTotalCost;
  }

  private get femaleServiceCost(): number {
    return this.menuItemDetails && this.isFemaleServiceSelected ? this.menuItemDetails.femaleServiceCost : 0;
  }

  ngOnInit() {
    this.isBrowser = this.platformService.isBrowser;
    this.isLoggedIn = this.accountService.isLoggedIn();
    this.localCurrencyPipe = new LocalCurrencyPipe(this.settingsService, this.config);
    this.showNavbarPaddingTop = !this.safeAreaInsetsService.getSafeAreaInsetTop();
  }

  ngAfterViewInit() {
    if (!this.isBrowser) {
      this.domCtrl.read(() => {
        // For some reason the height sometimes is not a round number which
        // causes some issues when scrolling to a specific position based on
        // the height of the navbar
        this.navbarHeight = Math.floor(this.navbar.nativeElement.getBoundingClientRect().height);
      });
    }
  }

  ionViewWillEnter(): void {
    this.updateStatusBarColorBasedOnHeaderStatus();
    this.currentLanguageDirection = this.settingsService.getLanguageDirection();

    const itemId = this.itemDetailsParams.itemId;
    this.vendorDetails = this.itemDetailsParams.vendorDetails;
    this.vendorMenuItem = this.vendorsService.getMenuItemFromVendorDetails(this.vendorDetails, itemId);

    this.isLoading = true;
    this.sourceId = this.itemDetailsParams.sourceId;
    this.sourceType = this.itemDetailsParams.sourceType;
    this.showAvailabilityMessage = this.itemDetailsParams.showAvailabilityMessage || false;

    this.shouldTrackCleverTapEvents = this.itemDetailsParams.shouldTrackCleverTapEvents;
    this.shouldIncrementQuantity = this.itemDetailsParams.incrementQuantity ?? false;
    this.shouldDecrementQuantity = this.itemDetailsParams.decrementQuantity ?? false;

    // Note: we need to send both the currentWishlist and shouldUpdateCurrentWishlist properties because:
    // - currentWishlist allows us to load the quantity/option/add-ons from the wishlist item
    // - shouldUpdateCurrentWishlist tells us if changes should be applied to the wishlist or not
    this.currentWishlist = this.itemDetailsParams.currentWishlist;
    this.shouldUpdateCurrentWishlist = this.itemDetailsParams.shouldUpdateCurrentWishlist;

    this.selectedAddress = this.itemDetailsParams.selectedAddress;
    this.selectedDateTime = this.itemDetailsParams.selectedDateTime;
    this.isOrderForNowSelected = this.itemDetailsParams.isOrderForNowSelected;
    this.initializeFromRecentlyOrderedItems = this.itemDetailsParams.initializeFromRecentlyOrderedItems;

    this.imageHeight =
      this.platformService.width() >= this.platformService.tabletMinScreenWidth ? ITEM_IMAGE_MAX_HEIGHT : ITEM_IMAGE_HEIGHT;
  }

  ionViewDidEnter(): void {
    if (!this.vendorMenuItem) {
      const errorMessage = this.validationService.getErrorMessage();
      this.loggerService.info({ component: 'MenuItemDetailsModalComponent', message: "couldn't get menu item details" });

      this.overlayService.showToast({ type: 'error', showCloseButton: true, message: errorMessage });
      this.onDismiss();
      return;
    }

    void this.initializeMenuItemDetails().then((loaded) => {
      if (!loaded) {
        this.dismiss({ addedToCart: false });
        return;
      }

      this.trackPageView();
      this.trackItemViewedCleverTapEvent();
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  public identifyOptionGroup(_index: number, option: MenuItemOptionGroup): number {
    return option.menuItemOptionGroupId;
  }

  public identifyOption(_index: number, option: MenuItemOption): number {
    return option.menuItemOptionId;
  }

  public identifyAddonGroup(_index: number, addon: MenuItemAddonGroup): number {
    return addon.menuItemAddOnGroupId;
  }

  public identifyAddon(_index: number, addon: MenuItemAddon): number {
    return addon.menuItemAddOnId;
  }

  public onIncrementMenuItemOption(menuItemOptionGroup: MenuItemOptionGroup, menuItemOption: MenuItemOption): void {
    const adjustedMaxSelection = this.adjustBasedOnQuantityAndPricingMethod(
      menuItemOptionGroup.maximumSelection,
      menuItemOptionGroup.input,
    );

    if (adjustedMaxSelection > 0 && menuItemOptionGroup.selectedOptionsCount === adjustedMaxSelection) {
      return;
    }

    menuItemOption.quantity = (menuItemOption.quantity || 0) + 1;
    menuItemOptionGroup.selectedOptionsCount = (menuItemOptionGroup.selectedOptionsCount || 0) + 1;

    this.currentCost = Helpers.sumWithPrecision(this.currentCost, this.getOptionPrice(menuItemOption));
    this.updateLoyaltyProgramRewardBasedOnItemPrice();
  }

  public onDecrementMenuItemOption(menuItemOptionGroup: MenuItemOptionGroup, menuItemOption: MenuItemOption): void {
    if (menuItemOption.quantity === 0) {
      return;
    }

    menuItemOption.quantity--;
    menuItemOptionGroup.selectedOptionsCount--;

    this.currentCost = Helpers.subtractWithPrecision(this.currentCost, this.getOptionPrice(menuItemOption));
    this.updateLoyaltyProgramRewardBasedOnItemPrice();
  }

  public onToggleOption(menuItemOptionGroup: MenuItemOptionGroup, menuItemOption: MenuItemOption): void {
    const isRadioButton = menuItemOptionGroup.minimumSelection === 1 && menuItemOptionGroup.maximumSelection === 1;

    // If it's the only option selected and the min is 1,
    // we should not allow the user to deselect it
    if (isRadioButton && menuItemOptionGroup.selectedOptionsCount === 1 && menuItemOption.quantity === 1) {
      return;
    }

    if (isRadioButton && menuItemOptionGroup.selectedOptionsCount === 1 && menuItemOption.quantity === 0) {
      const alreadySelectedOption = menuItemOptionGroup.options.find((option) => option.quantity === 1);

      if (alreadySelectedOption) {
        this.onDecrementMenuItemOption(menuItemOptionGroup, alreadySelectedOption);
      }
    }

    menuItemOption.quantity === 1
      ? this.onDecrementMenuItemOption(menuItemOptionGroup, menuItemOption)
      : this.onIncrementMenuItemOption(menuItemOptionGroup, menuItemOption);
  }

  public onIncrementMenuItemAddOn(menuItemAddonGroup: MenuItemAddonGroup, addon: MenuItemAddon): void {
    const adjustedMaxSelection = this.adjustBasedOnQuantityAndPricingMethod(menuItemAddonGroup.maximumSelection);

    if (menuItemAddonGroup.maximumSelection > 0 && menuItemAddonGroup.selectedAddOnsCount === adjustedMaxSelection) {
      return;
    }

    addon.quantity = (addon.quantity || 0) + 1;
    menuItemAddonGroup.selectedAddOnsCount = (menuItemAddonGroup.selectedAddOnsCount || 0) + 1;

    this.currentCost = Helpers.sumWithPrecision(this.currentCost, addon.price);
    this.updateLoyaltyProgramRewardBasedOnItemPrice();
  }

  public onDecrementMenuItemAddOn(menuItemAddonGroup: MenuItemAddonGroup, addon: MenuItemAddon): void {
    if (addon.quantity === 0) {
      return;
    }

    addon.quantity--;
    menuItemAddonGroup.selectedAddOnsCount--;

    this.currentCost = Helpers.subtractWithPrecision(this.currentCost, addon.price);
    this.updateLoyaltyProgramRewardBasedOnItemPrice();
  }

  public onDecrementQuantity(): void {
    if (this.isMinQuantity) {
      return;
    }

    const currentQuantityPriceIndex = this.menuItemDetails.prices.findIndex(
      (price) => price.quantity === this.currentQuantityPrice.quantity,
    );

    // We should allow the quantity to be 0 when updating items from the cart or wishlists
    if ((this.isUpdatingCartItem || this.isUpdatingWishlistItem) && currentQuantityPriceIndex === 0) {
      this.currentQuantityPrice = { price: 0, quantity: 0 } as MenuItemPrice;
    } else {
      this.currentQuantityPrice = this.menuItemDetails.prices[currentQuantityPriceIndex - 1];
    }

    this.currentQuantity = this.currentQuantityPrice.quantity;
    this.isMinQuantity =
      this.isUpdatingCartItem || this.isUpdatingWishlistItem ? this.currentQuantity === 0 : currentQuantityPriceIndex - 1 === 0;
    this.isMaxQuantity = false; // Since we're decrementing the quantity, there's no way this is the last one

    // Since the min and max amount of options/addons is also based on the current
    // quantity we need now to check the amount of selected options/addons as well
    this.adjustSelectedOptionsBasedOnQuantity();
    this.adjustSelectedAddonsBasedOnQuantity();

    // Since we're resetting the current cost to match one of the prices sent by
    // the API, we need to sum the cost of the addons and options selected by the user
    this.currentCost = Helpers.sumWithPrecision(
      this.currentQuantityPrice.price,
      this.selectedAddonsCost,
      this.selectedOptionsCost,
      this.femaleServiceCost,
    );
    this.updateLoyaltyProgramRewardBasedOnItemPrice();
  }

  public onIncrementQuantity(): void {
    if (this.isMaxQuantity) {
      return;
    }

    const currentQuantityPriceIndex = this.menuItemDetails.prices.findIndex(
      (price) => price.quantity === this.currentQuantityPrice.quantity,
    );
    this.currentQuantityPrice = this.menuItemDetails.prices[currentQuantityPriceIndex + 1];

    // Since we're resetting the current cost to match one of the prices sent by
    // the API, we need to sum the cost of the addons and options selected by the user
    this.currentCost = Helpers.sumWithPrecision(
      this.currentQuantityPrice.price,
      this.selectedAddonsCost,
      this.selectedOptionsCost,
      this.femaleServiceCost,
    );
    this.updateLoyaltyProgramRewardBasedOnItemPrice();

    this.currentQuantity = this.currentQuantityPrice.quantity;

    this.isMinQuantity = false; // Since we're incrementing the quantity, there's no way this is the first one
    this.isMaxQuantity = currentQuantityPriceIndex + 1 === this.menuItemDetails.prices.length - 1;
  }

  public onShare(): void {
    if (!this.vendorDetails || !this.vendorMenuItem) {
      return;
    }

    this.trackShareEvent();

    void this.referralProgramService.maybeShowReferralProgramModal().then((shouldShare) => {
      if (shouldShare) {
        void this.universalLinksService
          .shareMenuItemDetails({
            vendorId: this.vendorDetails.vendorId,
            vendorName: this.vendorDetails.name,
            itemId: this.vendorMenuItem.menuItemId,
            itemName: this.vendorMenuItem.name,
            itemDescription: this.vendorMenuItem.description,
            itemImageUrl: this.vendorMenuItem.imageUrl,
            itemServiceTypes: this.vendorMenuItem.serviceType,
          })
          .catch((error: HttpErrorResponse) => {
            const errorMessage = this.translateService.instant('ERROR_MESSAGE.SHARE') as string;
            this.loggerService.info({
              component: 'MenuItemDetailsModalComponent',
              message: "couldn't share menu item",
              details: { error, messageShownToUser: errorMessage },
            });

            this.overlayService.showToast({ type: 'error', message: errorMessage });
          });
      }
    });
  }

  public onDismiss(): void {
    this.dismiss({ addedToCart: false });
  }

  public checkAvailability(): void {
    void this.showAvailabilityDateTimePicker().then((result) => {
      if (result && result.hasChangedData) {
        this.showAvailabilityMessage = true;
        this.selectedDateTime = result.selectedDateTime;
        this.isOrderForNowSelected = result.isOrderForNowSelected;
        this.reloadVendorAndItemDetails();
      }
    });
  }

  public onToggleAnimatedObject(animatedObject: AnimatedObject): void {
    animatedObject.animationState =
      animatedObject.animationState === AnimationState.Collapsed ? AnimationState.Expanded : AnimationState.Collapsed;
  }

  public onAddMenuItemToCart(): void {
    if (this.canTrackAddToCartAttemptEventInCurrentStep) {
      this.trackMenuItemAddToCartClickEvent();
      this.trackAddToCartAttemptEvent({ description: 'User clicked on add to cart button' });
      this.canTrackAddToCartAttemptEventInCurrentStep = false;
    }

    void this.maybeAddMenuItemToCart().then(({ addedToCart, failureReason }) => {
      if (!addedToCart) {
        this.trackAddToCartFailureEvent({ description: failureReason });
        return;
      }

      this.trackAddToCartSuccessEvent();
      this.dismiss({ addedToCart: true });
    });
  }

  public onUpdateExistingWishlistItem(): void {
    const canContinue = this.validateItemBeforeBeingAddedToWishlist();

    if (!canContinue) {
      return;
    }

    this.accountService
      .addItemToWishlist(this.currentWishlist.wishListId, this.getWishlistItem())
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.dismiss({ addedToCart: false, updatedWishlist: true });
        },
        error: (error: HttpErrorResponse) => {
          const errorMessage = this.validationService.getErrorMessage(error);
          this.loggerService.info({
            component: 'ItemDetailsModal',
            message: "couldn't update user wishlists",
            details: { error, messageShownToUser: errorMessage },
          });

          this.overlayService.showToast({ message: errorMessage, showCloseButton: true, type: 'error' });
          this.dismiss({ addedToCart: false, updatedWishlist: false });
        },
      });
  }

  public onRemoveFromExistingWishlistItem(): void {
    if (!this.currentWishlist) {
      return;
    }

    this.currentWishlist.details.caterers.forEach((vendor) => {
      vendor.items.forEach((item) => {
        if (item.menuItemId === this.vendorMenuItem.menuItemId) {
          item.quantity = 0;
        }
      });
    });

    this.accountService
      .updateWishlistItems(this.currentWishlist)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.dismiss({ addedToCart: false, updatedWishlist: true });
        },
        error: (error: HttpErrorResponse) => {
          const errorMessage = this.validationService.getErrorMessage(error);
          this.loggerService.info({
            component: 'ItemDetailsModal',
            message: "couldn't update user wishlists",
            details: { error, messageShownToUser: errorMessage },
          });

          this.overlayService.showToast({ message: errorMessage, showCloseButton: true, type: 'error' });
          this.dismiss({ addedToCart: false, updatedWishlist: false });
        },
      });
  }

  public onShowWishlists(event: MouseEvent): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const canContinue = this.validateItemBeforeBeingAddedToWishlist();

    if (!canContinue) {
      return;
    }

    this.wishlists = [];
    this.updatedWishlistId = undefined;
    this.updatingWishlistId = undefined;

    void this.overlayService.showLoading().then(() => {
      this.accountService
        .getUserWishlists()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (wishlists) => {
            void this.overlayService.hideLoading().then(() => {
              this.wishlists = wishlists;
              this.isWishlistsModalOpen = true;
            });
          },
          error: (error: HttpErrorResponse) => {
            const errorMessage = this.validationService.getErrorMessage(error);
            this.loggerService.info({
              component: 'ItemDetailsModal',
              message: "couldn't get user wishlists",
              details: { error, messageShownToUser: errorMessage },
            });

            void this.overlayService.hideLoading();
            this.overlayService.showToast({ message: errorMessage, showCloseButton: true, type: 'error' });
          },
        });
    });
  }

  public onAddItemToWishlist(wishlist: Wishlist): void {
    if (this.updatingWishlistId) {
      return;
    }

    this.updatingWishlistId = wishlist.wishListId;

    this.accountService
      .addItemToWishlist(wishlist.wishListId, this.getWishlistItem())
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.updatingWishlistId = undefined;
          this.updatedWishlistId = wishlist.wishListId;

          void this.platformService.wait(1000).then(() => {
            this.updatedWishlistId = undefined;
          });
        },
        error: (error: HttpErrorResponse) => {
          if (error.status === 403) {
            const errorMessage = this.translateService.instant('MENU_ITEM_DETAILS_PAGE.DUPLICATE_WISHLIST_ITEM') as string;
            this.loggerService.info({
              component: 'ItemDetailsModal',
              message: "couldn't update user wishlists",
              details: { error, messageShownToUser: errorMessage },
            });

            this.updatedWishlistId = undefined;
            this.updatingWishlistId = undefined;
            this.overlayService.showToast({ message: errorMessage, showCloseButton: true, type: 'info' });
            return;
          }

          const errorMessage = this.validationService.getErrorMessage(error);
          this.loggerService.info({
            component: 'ItemDetailsModal',
            message: "couldn't update user wishlists",
            details: { error, messageShownToUser: errorMessage },
          });

          this.updatedWishlistId = undefined;
          this.updatingWishlistId = undefined;
          this.overlayService.showToast({ message: errorMessage, showCloseButton: true, type: 'error' });
        },
      });
  }

  public onAddItemToNewWishlist(): void {
    if (this.updatingWishlistId) {
      return;
    }

    const canContinue = this.validateItemBeforeBeingAddedToWishlist();

    if (!canContinue) {
      return;
    }

    this.isWishlistsModalOpen = false;

    void this.platformService.wait(300).then(() => {
      void this.modalService
        .showModal<string>({ component: EditWishlistModalPage, id: EditWishlistModalPageIdentifier })
        .then((newName) => {
          if (newName) {
            void this.overlayService.showLoading().then(() => {
              this.accountService
                .createWishlist(newName, this.getWishlistItem())
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                  next: () => {
                    void this.overlayService.hideLoading();
                  },
                  error: (error: HttpErrorResponse) => {
                    const errorMessage = this.validationService.getErrorMessage(error);
                    this.loggerService.info({
                      component: 'ItemDetailsModal',
                      message: "couldn't create new wishlist",
                      details: { error, messageShownToUser: errorMessage },
                    });

                    void this.overlayService.hideLoading().then(() => {
                      this.overlayService.showToast({ message: errorMessage, showCloseButton: true, type: 'error' });
                    });
                  },
                });
            });
          }
        });
    });
  }

  public onRemoveFromCart(): void {
    this.cartService.removeItemsFromCart(this.vendorMenuItem.vendorId, [this.vendorMenuItem.menuItemId]);
    this.dismiss({ addedToCart: true });
  }

  public onToggleAddCard(): void {
    if (this.isAddCardSelected) {
      this.specialRequests = '';
    }

    this.isAddCardSelected = !this.isAddCardSelected;
  }

  public onToggleFemaleService(): void {
    this.isFemaleServiceSelected = !this.isFemaleServiceSelected;

    this.currentCost = Helpers.sumWithPrecision(
      this.currentQuantityPrice.price,
      this.selectedAddonsCost,
      this.selectedOptionsCost,
      this.femaleServiceCost,
    );
    this.updateLoyaltyProgramRewardBasedOnItemPrice();
  }

  public onSwiperLoaded(swiper: Swiper): void {
    if (swiper) {
      void this.platformService.wait(50).then(() => swiper.update());
    }
  }

  public onOpenImagesPreviewModalPage(): void {
    void this.modalService
      .showModal<number>({
        component: ImagesPreviewModalPage,
        id: ImagesPreviewModalPageIdentifier,
        componentProps: {
          imageUrls: this.vendorMenuItem.largeImageUrls?.length > 0 ? this.vendorMenuItem.largeImageUrls : this.vendorMenuItem.imageUrls,
          selectedImageIndex: this.swiper.swiperRef.activeIndex,
        },
      })
      .then((currentIndex) => {
        if (!currentIndex) {
          currentIndex = 0;
        }

        if (this.swiper.swiperRef.activeIndex !== currentIndex) {
          this.swiper.swiperRef.slideTo(currentIndex, 0);
        }
      });
  }

  public onScroll(event: Event): void {
    if (!event || this.isLoading || this.platformService.isBrowser) {
      return;
    }

    this.handleScrollEvent(event as CustomScrollEvent);
  }

  public onOpenDimensionRequirementsModalPage(): void {
    const params: DimensionRequirementsModalPageParams = {
      width: this.menuItemDetails.widthMeters || undefined,
      depth: this.menuItemDetails.depthMeters || undefined,
      height: this.menuItemDetails.heightMeters || undefined,
      dimensionsDescription: this.dimensionsRequirementText,
    };

    void this.modalService.showAlert<void>({
      component: DimensionRequirementsModalPage,
      id: DimensionRequirementsModalPageIdentifier,
      componentProps: { params },
    });
  }

  private maybeAddMenuItemToCart(): Promise<{ addedToCart: boolean; failureReason?: string }> {
    return this.validateMenuItemBeforeAddingToCart().then(({ canAddToCart, failureReason: beforeAddToCartValidationError }) => {
      if (!canAddToCart) {
        return { addedToCart: false, failureReason: beforeAddToCartValidationError };
      }

      return this.addMenuItemToCart({ updateDateTimeAutomatically: true }).then(
        ({ addedToCart, failureReason: addToCartValidationError }) => {
          if (!addedToCart) {
            return { addedToCart: false, failureReason: addToCartValidationError };
          }

          return this.validateMenuItemAfterAddingToCart().then(({ isValid, failureReason: afterAddToCartValidationError }) => {
            if (!isValid) {
              return { addedToCart: false, failureReason: afterAddToCartValidationError };
            }

            // We need to update the date/time in the VendorsPage after adding
            // something to the cart but the user could change the date/time in
            // the cart before going back to the VendorsPage so we store the id
            // of the vendor so we can then check its date/time when entering to
            // the VendorsPage
            // https://bilbayt.atlassian.net/browse/CA-1984
            this.vendorsService.setUpdateSearchResultsBasedOnDateTimeFromVendorId(this.vendorMenuItem.vendorId);

            return { addedToCart: true };
          });
        },
      );
    });
  }

  private getWishlistItem(): WishlistItem {
    const selectedOptions: Array<{ optionId: number; quantity: number }> = [];
    const selectedAddOns: Array<{ addonId: number; quantity: number }> = [];

    if (this.menuItemDetails) {
      this.menuItemDetails.optionsGroups.forEach((optionsGroup) => {
        optionsGroup.options.forEach((option) => {
          if (option.quantity > 0) {
            selectedOptions.push({ optionId: option.menuItemOptionId, quantity: option.quantity });
          }
        });
      });

      this.menuItemDetails.addOnGroups.forEach((addOnGroup) => {
        addOnGroup.addons.forEach((addOn) => {
          if (addOn.quantity > 0) {
            selectedAddOns.push({ addonId: addOn.menuItemAddOnId, quantity: addOn.quantity });
          }
        });
      });
    }

    return {
      itemId: this.vendorMenuItem.menuItemId,
      quantity: this.currentQuantity,
      femaleService: this.isFemaleServiceSelected || undefined,
      specialRequests: this.specialRequests || undefined,
      options: selectedOptions,
      addons: selectedAddOns,
    };
  }

  private validateMenuItemBeforeAddingToCart(): Promise<{ canAddToCart: boolean; failureReason?: string }> {
    if (this.hasOptions && !this.areSelectedOptionsValid(this.menuItemDetails.optionsGroups)) {
      const message = this.translateService.instant('MENU_ITEM_DETAILS_PAGE.MISSING_OPTIONS_TOAST') as string;
      this.overlayService.showToast({ message, showCloseButton: true, type: 'error' });

      return Promise.resolve({
        canAddToCart: false,
        failureReason: 'User still needs to select some mandatory options',
      });
    }

    if (this.hasAddOns && !this.areSelectedAddOnsValid(this.menuItemDetails.addOnGroups)) {
      const message = this.translateService.instant('MENU_ITEM_DETAILS_PAGE.MISSING_ADDONS_TOAST') as string;
      this.overlayService.showToast({ message, showCloseButton: true, type: 'error' });

      return Promise.resolve({
        canAddToCart: false,
        failureReason: 'User still needs to select some mandatory add-ons',
      });
    }

    if (this.currentCost < this.minMenuItemCost) {
      const errorMessage = this.translateService.instant('MENU_ITEM_DETAILS_PAGE.MIN_MENU_ITEM_VALUE_ERROR', {
        total: this.localCurrencyPipe.transform(this.minMenuItemCost),
      }) as string;
      this.overlayService.showToast({ message: errorMessage, showCloseButton: true, type: 'error' });

      return Promise.resolve({
        canAddToCart: false,
        failureReason: 'Current item configuration is less than minimum item cost',
      });
    }

    // In the webapp, selecting the address in the home page is optional so it
    // may happen that we reach this page without an area/address yet
    const alreadySelectedAddress = this.vendorsService.getAddressSearchFilters();

    const getAddress = !!this.selectedAddress
      ? Promise.resolve(this.selectedAddress)
      : !!alreadySelectedAddress
      ? Promise.resolve(alreadySelectedAddress)
      : this.modalService.showCard<Address>({
          component: AreaSelectionModalPage,
          id: AreaSelectionModalPageIdentifier,
          presentingElement: undefined,
          componentProps: {
            showCloseButton: true,
          },
        });

    return getAddress.then((selectedAddress) => {
      if (!selectedAddress) {
        const message = this.translateService.instant('MENU_ITEM_DETAILS_PAGE.MISSING_AREA') as string;
        this.overlayService.showToast({ message, showCloseButton: true, type: 'error' });

        return {
          canAddToCart: false,
          failureReason: "Area/address was empty and user didn't want to select it when asked",
        };
      }

      this.selectedAddress = selectedAddress;

      if (
        this.settingsService.isServiceTypeBasedOnDateTime(this.menuItemDetails.serviceType) &&
        !this.isOrderForNowSelected &&
        !this.selectedDateTime
      ) {
        // We don't have a date/time for this item since we may have opened this item from a deep link or from the
        // FOR YOU section so no date/time was sent to this page BUT maybe the user already selected the date/time
        // for the service type or the vendor
        const { dateTime, orderForNow } = this.getSearchFiltersBasedOnExistingData({
          vendorId: this.vendorMenuItem.vendorId,
          serviceType: this.menuItemDetails.serviceType,
        });

        this.selectedDateTime = dateTime ?? null;
        this.isOrderForNowSelected = orderForNow ?? null;

        if (this.selectedDateTime || this.isOrderForNowSelected) {
          // We need to check the availability because we may be using the
          // date/time selected for another vendor in the cart so we don't
          // really know if this item is available for that date/time
          return { canAddToCart: true };
        }

        // There's no data in the app that we can use to "guess" the date/time
        // for this items so we need to show the picker and ask the user
        return new Promise((resolve) => {
          void this.showAvailabilityDateTimePicker().then((areaDateTimeResults) => {
            if (areaDateTimeResults && areaDateTimeResults.hasChangedData) {
              this.selectedDateTime = areaDateTimeResults.selectedDateTime;
              this.isOrderForNowSelected = areaDateTimeResults.isOrderForNowSelected;
              resolve({ canAddToCart: true });
            } else {
              resolve({
                canAddToCart: false,
                failureReason: "Date/time was empty and user didn't want to select it when asked",
              });
            }
          });
        });
      }

      return { canAddToCart: true };
    });
  }

  private validateItemBeforeBeingAddedToWishlist(): boolean {
    if (this.hasOptions && !this.areSelectedOptionsValid(this.menuItemDetails.optionsGroups)) {
      const message = this.translateService.instant('MENU_ITEM_DETAILS_PAGE.MISSING_OPTIONS_TOAST') as string;
      this.overlayService.showToast({ message, showCloseButton: true, type: 'error' });
      return false;
    }

    if (this.hasAddOns && !this.areSelectedAddOnsValid(this.menuItemDetails.addOnGroups)) {
      const message = this.translateService.instant('MENU_ITEM_DETAILS_PAGE.MISSING_ADDONS_TOAST') as string;
      this.overlayService.showToast({ message, showCloseButton: true, type: 'error' });
      return false;
    }

    if (this.currentCost < this.minMenuItemCost) {
      const errorMessage = this.translateService.instant('MENU_ITEM_DETAILS_PAGE.MIN_MENU_ITEM_VALUE_ERROR', {
        total: this.localCurrencyPipe.transform(this.minMenuItemCost),
      }) as string;

      this.overlayService.showToast({ message: errorMessage, showCloseButton: true, type: 'error' });
      return false;
    }

    return true;
  }

  private trackShareEvent(): void {
    const isReferralProgramEnabled = this.settingsService.isReferralProgramFeatureEnabled();

    const eventDetails = {
      data: {
        ...this.getEventsSharedProperties(),
        [this.analyticsConfig.propertyName.referralProgram]: !!isReferralProgramEnabled,
      },
    };

    void this.analyticsService.trackEvent({ name: this.analyticsConfig.eventName.share, ...eventDetails });
    void this.analyticsService.trackEvent({ name: this.analyticsConfig.eventName.menuItemDetailsPageShare, ...eventDetails });
  }

  private areSelectedOptionsValid(optionGroups: Array<MenuItemOptionGroup>): boolean {
    for (const optionsGroup of optionGroups) {
      if (isNaN(optionsGroup.selectedOptionsCount)) {
        optionsGroup.selectedOptionsCount = 0;
      }

      const adjustedMinSelection = this.adjustBasedOnQuantityAndPricingMethod(optionsGroup.minimumSelection, optionsGroup.input);
      if (optionsGroup.selectedOptionsCount < adjustedMinSelection) {
        return false;
      }
    }

    return true;
  }

  private areSelectedAddOnsValid(addOnGroups: Array<MenuItemAddonGroup>): boolean {
    for (const addOnGroup of addOnGroups) {
      if (isNaN(addOnGroup.selectedAddOnsCount)) {
        addOnGroup.selectedAddOnsCount = 0;
      }

      const adjustedMinSelection = this.adjustBasedOnQuantityAndPricingMethod(addOnGroup.minimumSelection);
      if (addOnGroup.selectedAddOnsCount < adjustedMinSelection) {
        return false;
      }
    }

    return true;
  }

  // Adjusts a number based on the current quantity and the pricing method of the item.
  // For example, if a menu item is of princing type "Quantity" and the user selects 2 units, the min
  // and max number of selectable options should be also multiplied by 2.
  private adjustBasedOnQuantityAndPricingMethod(aNumber: number, type?: SelectionInput): number {
    // Based on CA-585 we only need to update the value if the option/addon
    // group is of type PlusMinusSelector and the item is quantity based
    return this.menuItemDetails.pricingMethod === PricingMethod.Quantity && (!type || type === SelectionInput.PlusMinusSelector)
      ? this.currentQuantity * aNumber
      : aNumber;
  }

  private getOptionPrice(menuItemOption: MenuItemOption): number {
    if (menuItemOption.price === 0) {
      return 0;
    }
    return menuItemOption.isFixedCost ? menuItemOption.price : menuItemOption.price * this.currentQuantityPrice.quantity;
  }

  private initializeMenuItemDetails(): Promise<boolean> {
    if (this.menuItemDetails) {
      this.isLoading = false;
      return Promise.resolve(this.initializeLocalPropertiesBasedOnVendorDetailsAndCart());
    }

    return new Promise((resolve) => {
      this.isLoading = true;

      this.vendorsService
        .getMenuItemDetails(this.vendorMenuItem.vendorId, this.vendorMenuItem.menuItemId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (menuItemDetails) => {
            if (!menuItemDetails) {
              const errorMessage = this.validationService.getErrorMessage();
              this.loggerService.info({ component: 'MenuItemDetailsModalComponent', message: "couldn't get menu item details" });

              this.overlayService.showToast({ type: 'error', showCloseButton: true, message: errorMessage });
              resolve(false);
              return;
            }

            this.menuItemDetails = menuItemDetails;
            this.reOrderItemDetails = this.initializeFromRecentlyOrderedItems
              ? this.vendorDetails.reOrderItems.find((item) => item.menuItemId === this.menuItemDetails.menuItemId)
              : null;

            this.requirements = [];

            if (this.menuItemDetails.setupTime) {
              this.requirements.push({
                text: this.dateTimeService.toMinutesOrHours(this.menuItemDetails.setupTime),
                secondaryText: this.translateService.instant('MENU_ITEM_DETAILS_PAGE.SETUP_TIME') as string,
                iconName: 'setup-time',
              });
            }

            if (this.menuItemDetails.maximumTime) {
              this.requirements.push({
                text: this.dateTimeService.toMinutesOrHours(this.menuItemDetails.maximumTime),
                secondaryText: this.translateService.instant('MENU_ITEM_DETAILS_PAGE.MAX_TIME') as string,
                iconName: 'max-time',
              });
            }

            if (!!this.menuItemDetails.requirementList?.length) {
              this.menuItemDetails.requirementList.forEach((requirementDetails) => {
                this.requirements.push({
                  text: requirementDetails.text,
                  secondaryText: this.translateService.instant('MENU_ITEM_DETAILS_PAGE.REQUIRED') as string,
                  iconName:
                    requirementDetails.requirement === MenuItemRequirement.ElectricOutlet
                      ? 'electric-outlet'
                      : requirementDetails.requirement === MenuItemRequirement.Ventilation
                      ? 'ventilation'
                      : requirementDetails.requirement === MenuItemRequirement.Indoors
                      ? 'indoors'
                      : requirementDetails.requirement === MenuItemRequirement.Outdoors
                      ? 'outdoors'
                      : 'generic-requirement',
                  type: requirementDetails.requirement,
                });
              });
            }

            const itemWidth = this.menuItemDetails.widthMeters ? this.menuItemDetails.widthMeters : 0;
            const itemHeight = this.menuItemDetails.heightMeters ? this.menuItemDetails.heightMeters : 0;
            const itemDepth = this.menuItemDetails.depthMeters ? this.menuItemDetails.depthMeters : 0;

            if (itemWidth || itemDepth || itemHeight) {
              const dimensionsDescription: Array<string> = [];

              if (+itemWidth) {
                dimensionsDescription.push(
                  `${itemWidth} ${this.translateService.instant(
                    this.menuItemDetails.widthMeters === 1 ? 'METER' : 'METERS',
                  )} ${this.translateService.instant('WIDTH')}`,
                );
              }

              if (+itemDepth) {
                dimensionsDescription.push(
                  `${itemDepth} ${this.translateService.instant(
                    this.menuItemDetails.depthMeters === 1 ? 'METER' : 'METERS',
                  )} ${this.translateService.instant('DEPTH')}`,
                );
              }

              if (+itemHeight) {
                dimensionsDescription.push(
                  `${itemHeight} ${this.translateService.instant(
                    this.menuItemDetails.heightMeters === 1 ? 'METER' : 'METERS',
                  )} ${this.translateService.instant('HEIGHT')}`,
                );
              }

              this.dimensionsRequirementText = dimensionsDescription.join(` ${this.translateService.instant('X')} `);

              this.requirements.push({
                text: this.dimensionsRequirementText,
                secondaryText: this.translateService.instant('MENU_ITEM_DETAILS_PAGE.DIMENSION_DETAILS') as string,
                iconName: 'dimensions',
                callToAction: () => this.onOpenDimensionRequirementsModalPage(),
              });
            }

            this.initializeLocalPropertiesBasedOnVendorDetailsAndCart();

            this.isLoading = false;
            resolve(true);
          },
          error: (error: HttpErrorResponse) => {
            const errorMessage = this.validationService.getErrorMessage(error);
            this.loggerService.info({
              component: 'MenuItemDetailsModalComponent',
              message: "couldn't get menu item details",
              details: { error, messageShownToUser: errorMessage },
            });

            this.overlayService.showToast({ type: 'error', showCloseButton: true, message: errorMessage });
            resolve(false);
          },
        });
    });
  }

  private initializeLocalPropertiesBasedOnVendorDetailsAndCart(): boolean {
    const itemFromCart = this.cartService.getItemFromCart({
      vendorId: this.vendorMenuItem.vendorId,
      itemId: this.vendorMenuItem.menuItemId,
    });
    const itemFromWishlist = this.getItemFromWishlist({ vendorId: this.vendorMenuItem.vendorId, itemId: this.vendorMenuItem.menuItemId });

    this.isUpdatingCartItem = !!itemFromCart;
    this.isUpdatingWishlistItem = this.shouldUpdateCurrentWishlist && !!itemFromWishlist;

    this.fullDescriptionFormatted = this.menuItemDetails.fullDescription
      ? this.domSanitizer.bypassSecurityTrustHtml(this.menuItemDetails.fullDescription)
      : null;

    const customizedItem = itemFromWishlist || itemFromCart || this.reOrderItemDetails;

    this.initializeItemOptions(customizedItem);
    this.initializeItemAddons(customizedItem);
    this.initializeFemaleService(customizedItem);

    this.specialRequests = customizedItem ? customizedItem.specialRequests : '';

    // We need to initialize the quantity and price AFTER initializing
    // the female service since it can include a cost (CA-984)
    this.initializeItemQuantityAndPrice(customizedItem);

    this.updateLoyaltyProgramRewardBasedOnItemPrice();

    if (this.isUpdatingCartItem) {
      this.sourceId = itemFromCart.sourceId;
      this.sourceType = itemFromCart.sourceType;
    }

    this.isAddCardSelected = !!this.specialRequests;
    this.isAdditionalCardSupported = this.settingsService.isAdditionalCardSupported(this.vendorMenuItem.serviceType);

    return true;
  }

  private getItemFromWishlist({ vendorId, itemId }: { vendorId: number; itemId: number }): CartValidatedItem {
    let itemFromWishlist: CartValidatedItem = null;

    if (!this.currentWishlist?.details?.caterers?.length) {
      return itemFromWishlist;
    }

    vendorsLoop: for (const vendor of this.currentWishlist?.details.caterers) {
      if (vendor?.catererId === vendorId) {
        if (vendor.items?.length) {
          for (const item of vendor.items) {
            if (item?.menuItemId === itemId) {
              itemFromWishlist = item;

              break vendorsLoop;
            }
          }
        }
      }
    }

    return itemFromWishlist;
  }

  private initializeItemOptions(item: CartItem | VendorDetailsReOrderItem): void {
    this.hasOptions = this.menuItemDetails.optionsGroups && !!this.menuItemDetails.optionsGroups.length;
    this.areOptionsRequired = false;

    if (this.hasOptions) {
      this.menuItemDetails.optionsGroups.forEach((optionsGroup) => {
        if (!this.areOptionsRequired && optionsGroup.minimumSelection > 0) {
          this.areOptionsRequired = true;
        }

        optionsGroup.options.forEach((option) => {
          const selectedOption =
            item && item.options?.length && item.options.find((itemOption) => itemOption.menuItemOptionId === option.menuItemOptionId);

          option.quantity = selectedOption ? selectedOption.quantity : 0;
        });

        optionsGroup.selectedOptionsCount = Helpers.sumWithPrecision(...optionsGroup.options.map((theOption) => theOption.quantity));
      });
    }
  }

  private initializeItemAddons(item: CartItem | VendorDetailsReOrderItem): void {
    this.hasAddOns = this.menuItemDetails.addOnGroups && !!this.menuItemDetails.addOnGroups.length;
    this.areAddOnsRequired = false;

    if (this.hasAddOns) {
      this.menuItemDetails.addOnGroups.forEach((addonsGroup) => {
        if (!this.areAddOnsRequired && addonsGroup.minimumSelection > 0) {
          this.areAddOnsRequired = true;
        }

        addonsGroup.addons.forEach((addon) => {
          const selectedAddOn =
            item && item.addOns?.length && item.addOns.find((itemAddOn) => itemAddOn.menuItemAddOnId === addon.menuItemAddOnId);

          addon.quantity = selectedAddOn ? selectedAddOn.quantity : 0;
        });

        addonsGroup.selectedAddOnsCount = Helpers.sumWithPrecision(...addonsGroup.addons.map((theAddon) => theAddon.quantity));
      });
    }
  }

  private initializeFemaleService(item: CartItem | VendorDetailsReOrderItem): void {
    this.isFemaleServicePreSelected = item?.femaleServers;

    // Only check the female service checkbox if it's available, not blocked
    // and already selected in the cart or previous order
    this.isFemaleServiceSelected =
      this.vendorMenuItem.isFemaleServeAvailable && !this.vendorMenuItem.isFemaleServeBlocked && this.isFemaleServicePreSelected;
  }

  private initializeItemQuantityAndPrice(item: CartItem | VendorDetailsReOrderItem): void {
    if (item) {
      const currentPriceQuantityIndex = this.menuItemDetails.prices.findIndex((priceQuantity) => priceQuantity.quantity === item.quantity);
      const newPriceQuantityIndex = currentPriceQuantityIndex >= 0 ? currentPriceQuantityIndex : 0;

      this.currentQuantityPrice = this.menuItemDetails.prices[newPriceQuantityIndex];

      this.currentQuantity = this.currentQuantityPrice.quantity;

      // Calculate the total since there could be a change in the price
      // of the menu item, options, add-ons or female service AFTER the
      // menu item was added to the cart
      this.currentCost = Helpers.sumWithPrecision(
        this.currentQuantityPrice.price,
        this.selectedAddonsCost,
        this.selectedOptionsCost,
        this.femaleServiceCost,
      );
      this.updateLoyaltyProgramRewardBasedOnItemPrice();

      this.isMinQuantity =
        this.isUpdatingCartItem || this.isUpdatingWishlistItem ? this.currentQuantity === 0 : newPriceQuantityIndex === 0;
      this.isMaxQuantity = newPriceQuantityIndex === this.menuItemDetails.prices.length - 1;

      if (this.shouldIncrementQuantity) {
        this.onIncrementQuantity();
      } else if (this.shouldDecrementQuantity) {
        this.onDecrementQuantity();
      }
    } else {
      this.currentQuantityPrice = this.menuItemDetails.prices[0];
      this.currentQuantity = this.currentQuantityPrice.quantity;

      this.currentCost = Helpers.sumWithPrecision(this.currentQuantityPrice.price, this.selectedAddonsCost, this.selectedOptionsCost);
      this.updateLoyaltyProgramRewardBasedOnItemPrice();

      switch (this.menuItemDetails.pricingMethod) {
        case PricingMethod.Quantity:
        case PricingMethod.HeadCount:
          this.isMinQuantity = true;
          this.isMaxQuantity = this.menuItemDetails.prices.length === 1;
          break;
      }
    }
  }

  private adjustSelectedOptionsBasedOnQuantity(): void {
    for (const optionsGroup of this.menuItemDetails.optionsGroups) {
      const adjustedMaxSelection = this.adjustBasedOnQuantityAndPricingMethod(optionsGroup.maximumSelection, optionsGroup.input);

      // Only adjust the selected options if there is a truly max selection limit
      // since "0" means that there is no limit at all
      if (adjustedMaxSelection > 0) {
        // This is the max number of options that the user can
        // select for each group of options
        let remainingOptionsPerGroup = adjustedMaxSelection;

        const menuItemOptionGroup = optionsGroup;

        // Now we check each option of the group, and adjust the selected
        // quantity based on the remaining number of options
        for (const option of menuItemOptionGroup.options) {
          if (option.quantity > remainingOptionsPerGroup) {
            // The quantity is higher than the remaining options for this group
            // so we need to adjust both the quantity of this option
            option.quantity = remainingOptionsPerGroup;
          }
          remainingOptionsPerGroup -= option.quantity;
        }

        // Update the amount of options selected
        if (menuItemOptionGroup.options.length === 0) {
          // CA-375: Check if there are no options to avoid
          // breaking the app if there is a content issue
          menuItemOptionGroup.selectedOptionsCount = 0;
        } else {
          menuItemOptionGroup.selectedOptionsCount = Helpers.sumWithPrecision(...menuItemOptionGroup.options.map((item) => item.quantity));
        }
      }
    }
  }

  private adjustSelectedAddonsBasedOnQuantity(): void {
    for (const addOnGroup of this.menuItemDetails.addOnGroups) {
      const adjustedMaxSelection = this.adjustBasedOnQuantityAndPricingMethod(addOnGroup.maximumSelection);

      // Only adjust the selected add-ons if there is a truly max selection limit
      // since "0" means that there is no limit at all
      if (adjustedMaxSelection > 0) {
        // This is the max number of addons that the user can
        // select for each group of addons
        let remainingAddonsPerGroup = adjustedMaxSelection;

        const menuItemAddonGroup = addOnGroup;

        // Now we check each addon of the group, and adjust the selected
        // quantity based on the remaining number of addons
        for (const addon of menuItemAddonGroup.addons) {
          if (addon.quantity > remainingAddonsPerGroup) {
            // The quantity is higher than the remaining addons for this group
            // so we need to adjust both the quantity of this addon
            addon.quantity = remainingAddonsPerGroup;
          }
          remainingAddonsPerGroup -= addon.quantity;
        }

        // Update the amount of addons selected
        if (menuItemAddonGroup.addons.length === 0) {
          // CA-375: Check if there are no add-ons to avoid
          // breaking the app if there is a content issue
          menuItemAddonGroup.selectedAddOnsCount = 0;
        } else {
          menuItemAddonGroup.selectedAddOnsCount = Helpers.sumWithPrecision(...menuItemAddonGroup.addons.map((item) => item.quantity));
        }
      }
    }
  }

  private trackItemViewedCleverTapEvent(): void {
    if (!this.shouldTrackCleverTapEvents) {
      return;
    }

    let serviceType: number = null;
    let itemName: MultiLanguageValue = null;

    if (this.vendorMenuItem?.nameJson) {
      serviceType = this.vendorMenuItem.serviceType;
      itemName = JSON.parse(this.vendorMenuItem.nameJson) as MultiLanguageValue;
    }

    this.analyticsService.trackItemViewedCleverTapEvent({
      id: this.vendorMenuItem.menuItemId,
      nameEn: itemName ? Helpers.getLocalizedMultiLanguageValue(itemName, 'en') : null,
      nameAr: itemName ? Helpers.getLocalizedMultiLanguageValue(itemName, 'ar') : null,
      vendorId: this.vendorMenuItem.vendorId,
      serviceType,
    });
  }

  private trackItemAddedCleverTapEvent(itemTotal: number): void {
    if (!this.shouldTrackCleverTapEvents) {
      return;
    }

    let serviceType: number;
    let itemName: MultiLanguageValue;

    if (this.vendorMenuItem) {
      serviceType = this.vendorMenuItem.serviceType;
      itemName = this.vendorMenuItem.nameJson ? (JSON.parse(this.vendorMenuItem.nameJson) as MultiLanguageValue) : undefined;
    }

    this.analyticsService.trackItemAddedCleverTapEvent({
      id: this.vendorMenuItem.menuItemId,
      nameEn: itemName ? Helpers.getLocalizedMultiLanguageValue(itemName, 'en') : null,
      nameAr: itemName ? Helpers.getLocalizedMultiLanguageValue(itemName, 'ar') : null,
      currency: this.settingsService.currencySymbolEN,
      amount: itemTotal,
      vendorId: this.vendorMenuItem.vendorId,
      serviceType,
    });
  }

  private trackPageView(): void {
    void this.analyticsService.trackView({
      name: this.analyticsConfig.pageName.menuItemDetailsPage,
      data: {
        ...this.getEventsSharedProperties(),
        [this.analyticsConfig.propertyName.dateTime]: this.selectedDateTime ?? null,
        [this.analyticsConfig.propertyName.bilbaytNow]: this.isOrderForNowSelected ?? false,
      },
      includeAreaDateTime: true,
    });
  }

  private trackMenuItemAddToCartEvent(updatedDateTimeAutomatically: boolean): void {
    void this.analyticsService.trackEvent({
      name: this.analyticsConfig.eventName.menuItemDetailsPageAddToCart,
      data: {
        ...this.getEventsSharedProperties(),
        [this.analyticsConfig.propertyName.amount]: this.currentCost,
        [this.analyticsConfig.propertyName.currency]: this.settingsService.currencySymbolEN,
        [this.analyticsConfig.propertyName.areaId]: this.selectedAddress?.area.areaId,
        [this.analyticsConfig.propertyName.dateTime]: this.selectedDateTime ?? null,
        [this.analyticsConfig.propertyName.bilbaytNow]: this.isOrderForNowSelected ?? false,
        [this.analyticsConfig.propertyName.updatedDateTimeAutomatically]: updatedDateTimeAutomatically ?? false,
        [this.analyticsConfig.propertyName.soonestDeliveryDateTime]: this.vendorMenuItem?.soonestDeliveryDateTime ?? null,
      },
      includeAreaDateTime: true,
    });
  }

  private trackAddToCartAttemptEvent({ description }: { description: string }): void {
    void this.analyticsService.trackMixpanelAddToCartAttempt({
      description,
      itemId: this.vendorMenuItem.menuItemId,
      vendorId: this.vendorMenuItem.vendorId,
      serviceType: this.vendorMenuItem.serviceType,
      amount: this.currentCost,
    });
  }

  private trackAddToCartSuccessEvent(): void {
    this.canTrackAddToCartAttemptEventInCurrentStep = true;
    void this.analyticsService.trackMixpanelAddToCartSuccess({
      itemId: this.vendorMenuItem.menuItemId,
      vendorId: this.vendorMenuItem.vendorId,
      serviceType: this.vendorMenuItem.serviceType,
      amount: this.currentCost,
    });
  }

  private trackAddToCartFailureEvent({ description }: { description: string }): void {
    this.canTrackAddToCartAttemptEventInCurrentStep = true;

    void this.analyticsService.trackMixpanelAddToCartFailure({
      description,
      itemId: this.vendorMenuItem.menuItemId,
      vendorId: this.vendorMenuItem.vendorId,
      serviceType: this.vendorMenuItem.serviceType,
      amount: this.currentCost,
    });
  }

  private trackMenuItemAddToCartClickEvent(): void {
    void this.analyticsService.trackEvent({
      name: this.analyticsConfig.eventName.menuItemDetailsPageAddToCartClick,
      data: {
        ...this.getEventsSharedProperties(),
        [this.analyticsConfig.propertyName.amount]: this.currentCost,
        [this.analyticsConfig.propertyName.currency]: this.settingsService.currencySymbolEN,
        [this.analyticsConfig.propertyName.areaId]: this.selectedAddress?.area.areaId,
        [this.analyticsConfig.propertyName.dateTime]: this.selectedDateTime ?? null,
        [this.analyticsConfig.propertyName.bilbaytNow]: this.isOrderForNowSelected ?? false,
      },
      includeAreaDateTime: true,
    });
  }

  private getEventsSharedProperties(): Record<string, number | boolean> {
    return {
      [this.analyticsConfig.propertyName.catererId]: this.vendorMenuItem.vendorId,
      [this.analyticsConfig.propertyName.menuItemId]: this.vendorMenuItem.menuItemId,
      [this.analyticsConfig.propertyName.serviceType]: this.vendorMenuItem.serviceType,
      [this.analyticsConfig.propertyName.fromFeaturedMenuItems]: this.sourceType === OrderMenuItemSourceType.FeaturedMenuItem,
      [this.analyticsConfig.propertyName.fromFeaturedVendors]: this.sourceType === OrderMenuItemSourceType.SponseredVendor,
    };
  }

  private getSearchFiltersBasedOnExistingData({ vendorId, serviceType }: { vendorId: number; serviceType: number }): {
    dateTime: string;
    orderForNow: boolean;
  } {
    let dateTime: string;
    let orderForNow: boolean;

    const vendorSearchFilters = this.getVendorDateTimeAndOrderForNowFromCart(vendorId);

    if (vendorSearchFilters?.dateTime || vendorSearchFilters?.orderForNow) {
      // We already have the search filters for this vendor so we
      // can use it to add this item to the cart
      dateTime = vendorSearchFilters.dateTime;
      orderForNow = vendorSearchFilters.orderForNow;
    } else {
      const searchFilters = this.vendorsService.getDatetimeSearchFilters(serviceType);
      if (searchFilters.dateTime || searchFilters.orderForNow) {
        // We don't have the search filters for this vendor but we do have
        // them for the service type of the item so we can use that to add
        // this item to the cart
        dateTime = searchFilters.dateTime;
        orderForNow = searchFilters.orderForNow;
      } else {
        // We don't have the search filters for the vendor or even the service
        // type so the best we can do is to check the cart and see if we can use
        // the search filters from other vendors for this item
        const cartIsForNow = this.cartService.isBilbaytNowCart();
        const cartLastVendorDateTime = this.cartService.getSelectedDateTimeForLastVendorInCart();

        if ((cartIsForNow && this.menuItemDetails.canOrderForNow) || cartLastVendorDateTime) {
          dateTime = cartLastVendorDateTime;
          orderForNow = cartIsForNow;
        }
      }
    }

    return {
      dateTime,
      orderForNow,
    };
  }

  private getVendorDateTimeAndOrderForNowFromCart(vendorId: number): { dateTime: string; orderForNow: boolean } | null {
    const isVendorInCart = this.cartService.isVendorInCart(vendorId);

    if (isVendorInCart) {
      if (this.cartService.isBilbaytNowCart()) {
        return { dateTime: null, orderForNow: true };
      }

      const selectedDateTimeFromCart = this.cartService.getSelectedDateTimeForVendor(vendorId);

      if (selectedDateTimeFromCart && !this.dateTimeService.isBeforeCurrentLocalTime(selectedDateTimeFromCart)) {
        return {
          dateTime: selectedDateTimeFromCart,
          orderForNow: false,
        };
      }
    }

    return null;
  }

  private validateMenuItemAfterAddingToCart(): Promise<{ isValid: boolean; failureReason?: string }> {
    return new Promise((resolve) => {
      void this.overlayService.showLoading().then(() => {
        this.cartService
          .validateAndUpdateCart()
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe({
            next: () => {
              void this.overlayService.hideLoading();

              const cartDetails = this.cartService.getCartDetails();

              let itemServiceType: number;
              let itemWarningSeverity: WarningSeverity;
              let itemWarnings: Array<Warning>;

              for (const vendor of cartDetails.caterers) {
                if (vendor.catererId === this.vendorMenuItem.vendorId) {
                  for (const item of vendor.items) {
                    if (item.menuItemId === this.vendorMenuItem.menuItemId) {
                      itemWarnings = item.warnings;
                      itemWarningSeverity = item.warningSeverity;
                      itemServiceType = item.serviceType;
                      break;
                    }
                  }
                }
              }

              if (itemWarningSeverity !== WarningSeverity.Critical) {
                resolve({ isValid: true });
                return;
              }

              const mainWarning = itemWarnings.find((warning) => warning.severity === itemWarningSeverity);

              let buttons: Array<AlertModalButton> = [];

              const invalidDateTimeButtonChangeDateTime = {
                isPrimary: true,
                text: this.translateService.instant('MENU_ITEM_DETAILS_PAGE.CHANGE_DATE_TIME') as string,
                handler: () => {
                  // Since the date/time selected is not valid, we can just
                  // reset them and ask again for the date/time when the user
                  // wants to add the item to the cart
                  this.selectedDateTime = null;
                  this.isOrderForNowSelected = null;

                  void this.showAvailabilityDateTimePicker().then((areaDateTimeResults) => {
                    if (areaDateTimeResults && areaDateTimeResults.hasChangedData) {
                      this.selectedDateTime = areaDateTimeResults.selectedDateTime;
                      this.isOrderForNowSelected = areaDateTimeResults.isOrderForNowSelected;

                      resolve(
                        this.maybeAddMenuItemToCart().then(({ addedToCart: isValid, failureReason }) => ({ isValid, failureReason })),
                      );
                    } else {
                      resolve({
                        isValid: false,
                        failureReason: "Selected date/time was invalid and user didn't want to update it when asked",
                      });
                    }
                  });
                },
              };

              const invalidDateTimeButtonCancel = {
                text: this.translateService.instant('MENU_ITEM_DETAILS_PAGE.CANCEL') as string,
                handler: () => {
                  resolve({
                    isValid: false,
                    failureReason: "Selected date/time was invalid and user didn't want to update it when asked",
                  });
                },
              };

              const invalidServiceTimeOrNoticePeriodButtonCancel = {
                text: this.translateService.instant('MENU_ITEM_DETAILS_PAGE.CANCEL') as string,
                handler: () => {
                  resolve({
                    isValid: false,
                    failureReason: 'Service time or notice period not valid (item not based on date/time)',
                  });
                },
              };

              const unavailableFemaleServiceButtonRemoveFemaleService = {
                isPrimary: true,
                text: this.translateService.instant('MENU_ITEM_DETAILS_PAGE.ADD_ITEM') as string,
                handler: () => {
                  this.onToggleFemaleService();
                  resolve(
                    this.maybeAddMenuItemToCart().then(({ addedToCart, failureReason }) => ({ isValid: addedToCart, failureReason })),
                  );
                },
              };

              const unavailableFemaleServiceButtonChangeDateTime = {
                text: this.translateService.instant('MENU_ITEM_DETAILS_PAGE.CHANGE_DATE_TIME') as string,
                handler: () => {
                  // Since the date/time selected is not valid, we can just
                  // reset them and ask again for the date/time when the user
                  // wants to add the item to the cart
                  this.selectedDateTime = null;
                  this.isOrderForNowSelected = null;

                  void this.showAvailabilityDateTimePicker().then((areaDateTimeResults) => {
                    if (areaDateTimeResults && areaDateTimeResults.hasChangedData) {
                      this.selectedDateTime = areaDateTimeResults.selectedDateTime;
                      this.isOrderForNowSelected = areaDateTimeResults.isOrderForNowSelected;

                      resolve(
                        this.maybeAddMenuItemToCart().then(({ addedToCart: isValid, failureReason }) => ({ isValid, failureReason })),
                      );
                    } else {
                      resolve({
                        isValid: false,
                        failureReason: "Female servers not available and user didn't want to update it when asked",
                      });
                    }
                  });
                },
              };

              const unavailableFemaleServiceButtonCancel = {
                text: this.translateService.instant('MENU_ITEM_DETAILS_PAGE.CANCEL') as string,
                handler: () => {
                  resolve({
                    isValid: false,
                    failureReason: "Female servers not available and user didn't want to update it when asked",
                  });
                },
              };

              const serviceTypeBasedOnDateTime = this.settingsService.isServiceTypeBasedOnDateTime(itemServiceType);

              const buttonsForDateTimeWarning: Array<AlertModalButton> = serviceTypeBasedOnDateTime
                ? [invalidDateTimeButtonChangeDateTime, invalidDateTimeButtonCancel]
                : [invalidServiceTimeOrNoticePeriodButtonCancel];

              const buttonsForFemaleServiceWarning: Array<AlertModalButton> = serviceTypeBasedOnDateTime
                ? [
                    unavailableFemaleServiceButtonRemoveFemaleService,
                    unavailableFemaleServiceButtonChangeDateTime,
                    unavailableFemaleServiceButtonCancel,
                  ]
                : [unavailableFemaleServiceButtonRemoveFemaleService, unavailableFemaleServiceButtonCancel];

              switch (mainWarning.type) {
                case WarningType.UnavailableServiceTime:
                case WarningType.ItemInsufficientNotice:
                case WarningType.UnavailableFullyBooked:
                case WarningType.UnavailableUnspecified:
                  buttons = buttonsForDateTimeWarning;
                  break;

                case WarningType.UnavailableFemaleService:
                  buttons = buttonsForFemaleServiceWarning;
                  break;

                default:
                  buttons = [
                    {
                      text: this.translateService.instant('MENU_ITEM_DETAILS_PAGE.CANCEL') as string,
                      handler: () => {
                        resolve({
                          isValid: false,
                          failureReason: `Item was not valid because of warning type ${mainWarning.type} with severity ${mainWarning.severity}`,
                        });
                      },
                    },
                  ];
                  break;
              }

              this.cartService.removeItemsFromCart(this.vendorMenuItem.vendorId, [this.vendorMenuItem.menuItemId]);

              void this.modalService.showAlert({
                title: mainWarning.title,
                message: mainWarning.message,
                buttons,
              });
            },
            error: (error: HttpErrorResponse) => {
              void this.overlayService.hideLoading();
              this.loggerService.info({
                component: 'ItemDetailsModalComponent',
                message: "couldn't validate item availability",
                details: { error },
              });

              resolve({ isValid: false, failureReason: "Couldn't validate item availability because of error in the app/server" });
            },
          });
      });
    });
  }

  private addMenuItemToCart(
    { updateDateTimeAutomatically } = { updateDateTimeAutomatically: true },
  ): Promise<{ addedToCart: boolean; failureReason?: string }> {
    const selectedOptions: Array<CartItemOption> = [];
    const selectedAddOns: Array<CartItemAddOn> = [];

    this.menuItemDetails.optionsGroups.forEach((optionsGroup) => {
      optionsGroup.options.forEach((option) => {
        if (option.quantity > 0) {
          selectedOptions.push({
            menuItemOptionGroupId: optionsGroup.menuItemOptionGroupId,
            menuItemOptionId: option.menuItemOptionId,
            name: option.name,
            quantity: option.quantity,
          });
        }
      });
    });

    this.menuItemDetails.addOnGroups.forEach((addOnGroup) => {
      addOnGroup.addons.forEach((addOn) => {
        if (addOn.quantity > 0) {
          selectedAddOns.push({
            menuItemAddOnGroupId: addOnGroup.menuItemAddOnGroupId,
            menuItemAddOnId: addOn.menuItemAddOnId,
            name: addOn.name,
            quantity: addOn.quantity,
          });
        }
      });
    });

    const total = this.currentCost;
    const minimumOrderValue =
      this.vendorDetails.serviceTypeRequirements.find((requirement) => requirement.serviceType === this.vendorMenuItem.serviceType)
        ?.minimumOrderAmount || 0;

    const newCartItem: NewCartItem = {
      catererId: this.vendorDetails.vendorId,
      catererName: this.vendorDetails.name,
      minimumOrderValue,
      menuItemId: this.menuItemDetails.menuItemId,
      menuItemName: this.menuItemDetails.name,
      serviceType: this.menuItemDetails.serviceType,
      imageUrl: this.menuItemDetails.images[0],
      isBilbaytNow: this.menuItemDetails.canOrderForNow,
      specialRequests: this.specialRequests,
      femaleService: this.isFemaleServiceSelected,
      sourceId: this.sourceId ?? this.vendorMenuItem.vendorId,
      sourceType: this.sourceType ?? OrderMenuItemSourceType.Vendor,
      total,
      quantity: this.currentQuantity,
      selectedOptions,
      selectedAddOns,
      noticePeriod: this.vendorMenuItem.noticePeriod,
    };

    const shouldUpdateDateTime = updateDateTimeAutomatically && this.shouldUpdateSelectedDateTime();
    const shouldAskUserBeforeUpdatingDate = shouldUpdateDateTime && this.shouldAskUserBeforeUpdatingDate();
    const shouldAddToCart = shouldAskUserBeforeUpdatingDate ? this.askUserBeforeUpdatingDate() : Promise.resolve(true);

    return shouldAddToCart.then((addToCart) => {
      if (!addToCart) {
        return {
          addedToCart: false,
          failureReason: "User didn't accept the soonest delivery date/time suggested",
        };
      }

      let selectedDateTime = this.selectedDateTime;
      let isOrderForNowSelected = this.isOrderForNowSelected;

      if (shouldUpdateDateTime) {
        selectedDateTime = this.vendorMenuItem.soonestDeliveryDateTime;
        isOrderForNowSelected = false;
      }

      return this.cartService
        .maybeAddToCart({
          item: newCartItem,
          address: this.selectedAddress,
          dateTime: selectedDateTime,
          isForNow: isOrderForNowSelected,
        })
        .then(({ added, failureReason }) => {
          if (added) {
            this.trackMenuItemAddToCartEvent(shouldUpdateDateTime);
            this.trackItemAddedCleverTapEvent(total);

            if (shouldUpdateDateTime) {
              this.selectedDateTime = selectedDateTime;
              this.isOrderForNowSelected = isOrderForNowSelected;
              this.cartService.addVendorToListOfVendorIdsDateTimeUpdatedAutomatically(this.vendorMenuItem.vendorId);
            }
          }

          return { addedToCart: added, failureReason };
        });
    });
  }

  private shouldUpdateSelectedDateTime(): boolean {
    if (!this.settingsService.isServiceTypeBasedOnDateTime(this.vendorMenuItem.serviceType)) {
      return false;
    }

    const shouldUpdateForNow = this.isOrderForNowSelected && !this.menuItemDetails.canOrderForNow;

    // If the user selected a date/time before opening the vendor details page, the
    // itemSoonestDeliveryDateTime will be equal to that date/time. But it may happen
    // that the date/time was null and then the user selects a date/time when trying
    // to add something to the cart. In that case, the selected date/time will be
    // different than the itemSoonestDeliveryDateTime so we should ONLY update the
    // date/time if the selected date/time is BEFORE the itemSoonestDeliveryDateTime,
    // othwerwise the selected date/time is valid
    // https://bilbayt.atlassian.net/browse/CA-2248

    const shouldUpdateForLater =
      this.selectedDateTime &&
      this.vendorMenuItem.soonestDeliveryDateTime &&
      this.dateTimeService.isBefore({
        dateTime1: this.selectedDateTime,
        dateTime2: this.vendorMenuItem.soonestDeliveryDateTime,
      });

    return shouldUpdateForLater || shouldUpdateForNow;
  }

  private shouldAskUserBeforeUpdatingDate(): boolean {
    const shouldAskForNow =
      this.isOrderForNowSelected &&
      !this.menuItemDetails.canOrderForNow &&
      this.vendorMenuItem.soonestDeliveryDateTime &&
      !this.dateTimeService.isToday(this.vendorMenuItem.soonestDeliveryDateTime);

    const shouldAskForLater =
      this.selectedDateTime &&
      this.vendorMenuItem.soonestDeliveryDateTime &&
      +this.dateTimeService.format({ dateTimeIso: this.selectedDateTime, format: 'D' }) !==
        +this.dateTimeService.format({ dateTimeIso: this.vendorMenuItem.soonestDeliveryDateTime, format: 'D' });

    return shouldAskForNow || shouldAskForLater;
  }

  private askUserBeforeUpdatingDate(): Promise<boolean> {
    const soonestDeliveryDateTimeFormatted = this.dateTimeService.format({
      dateTimeIso: this.vendorMenuItem.soonestDeliveryDateTime,
      format: 'MMM DD h:mma',
      useRelativeDate: true,
    });

    return new Promise((resolve) => {
      void this.modalService.showAlert({
        title: this.translateService.instant('MENU_ITEM_DETAILS_PAGE.CHANGE_DATE_TITLE') as string,
        message: this.translateService.instant('MENU_ITEM_DETAILS_PAGE.CHANGE_DATE_MESSAGE', {
          time: soonestDeliveryDateTimeFormatted,
        }) as string,
        inlineButtons: true,
        buttons: [
          {
            text: this.translateService.instant('MENU_ITEM_DETAILS_PAGE.CANCEL') as string,
            handler: () => resolve(false),
          },
          {
            isPrimary: true,
            text: this.translateService.instant('MENU_ITEM_DETAILS_PAGE.ADD_ITEM') as string,
            handler: () => resolve(true),
          },
        ],
      });
    });
  }

  private handleScrollEvent(event: CustomEvent<ScrollEventDetail>): void {
    this.domCtrl.read(() => {
      let showNavbarBackground: boolean;
      let hideNavbarBackground: boolean;

      if (this.menuItemImageContainer?.nativeElement) {
        const imageContainerBottom = this.menuItemImageContainer?.nativeElement.getBoundingClientRect().bottom;
        showNavbarBackground = !this.showNavbarBackground && (event.detail.scrollTop < 0 || imageContainerBottom <= this.navbarHeight - 24);
        hideNavbarBackground = this.showNavbarBackground && event.detail.scrollTop >= 0 && imageContainerBottom > this.navbarHeight - 24;
      } else {
        showNavbarBackground = !this.showNavbarBackground && event.detail.scrollTop >= 24;
        hideNavbarBackground = this.showNavbarBackground && event.detail.scrollTop < 24;
      }

      if (showNavbarBackground || hideNavbarBackground) {
        this.ngZone.run(() => {
          this.showNavbarBackground = showNavbarBackground;
          this.updateStatusBarColorBasedOnHeaderStatus();
        });
      }
    });
  }

  private updateStatusBarColorBasedOnHeaderStatus(): void {
    if (this.isBrowser) {
      return;
    }

    if (this.showNavbarBackground) {
      this.statusBarPlugin.useDarkTextColor();
    } else {
      this.statusBarPlugin.useLightTextColor();
    }
  }

  private dismiss({ addedToCart, updatedWishlist }: { addedToCart: boolean; updatedWishlist?: boolean }): void {
    // Make sure we reset the statusbar color before going back to the caller
    this.statusBarPlugin.useDarkTextColor();

    const data: MenuItemDetailsModalResult = {
      addedToCart,
      updatedWishlist: !!updatedWishlist,
      selectedDateTime: this.selectedDateTime,
      isOrderForNowSelected: this.isOrderForNowSelected,
    };

    void this.modalService.dismissModal({ id: MenuItemDetailsModalPageIdentifier, data });

    if (addedToCart && this.sourceType === OrderMenuItemSourceType.FeaturedMenuItem) {
      this.deepLinkService.openCartPage();
    }
  }

  private showAvailabilityDateTimePicker(): Promise<ItemAvailabilityDateTimePickerModalPageResult> {
    const vendorId = this.vendorMenuItem.vendorId;
    const menuItemId = this.vendorMenuItem.menuItemId;
    const isOrderForNowAvailable = this.menuItemDetails.canOrderForNow && this.cartService.isOrderForNowAvailable();

    const params: ItemAvailabilityDateTimePickerModalPageParams = {
      vendorId,
      menuItemId,
      selectedAddress: this.selectedAddress,
      selectedDateTime: this.selectedDateTime,
      isOrderForNowAvailable,
      isOrderForNowSelected: isOrderForNowAvailable && this.isOrderForNowSelected,
    };

    return this.modalService.showModal<ItemAvailabilityDateTimePickerModalPageResult>({
      id: ItemAvailabilityDateTimePickerModalPageIdentifier,
      component: ItemAvailabilityDateTimePickerModalPage,
      componentProps: { params },
    });
  }

  private reloadVendorAndItemDetails(): void {
    const vendorId = this.vendorMenuItem.vendorId;
    const menuItemId = this.vendorMenuItem.menuItemId;

    this.isLoading = true;
    this.shouldHideFooter = true;

    this.vendorsService
      .getVendorDetailsById({
        serviceTypes: this.vendorDetails.serviceTypes,
        vendorId,
        areaId: this.selectedAddress?.areaId,
        dateTime: this.selectedDateTime,
        orderForNow: this.isOrderForNowSelected,
      })
      .subscribe({
        next: (vendorDetails) => {
          this.vendorDetails = vendorDetails;
          this.vendorMenuItem = this.vendorsService.getMenuItemFromVendorDetails(this.vendorDetails, menuItemId);

          void this.initializeMenuItemDetails().then((loaded) => {
            if (!loaded) {
              this.dismiss({ addedToCart: false });
              return;
            }

            this.shouldHideFooter = false;
          });
        },
        error: (error: HttpErrorResponse) => {
          const errorMessage = this.validationService.getErrorMessage(error);
          this.loggerService.info({
            component: 'MenuItemDetailsPage',
            message: "couldn't reload vendor details",
            details: { error, messageShownToUser: errorMessage },
          });

          this.dismiss({ addedToCart: false });
          this.overlayService.showToast({ type: 'error', message: errorMessage, showCloseButton: true });
        },
      });
  }

  private updateLoyaltyProgramRewardBasedOnItemPrice(): void {
    if (this.vendorMenuItem && (this.vendorMenuItem.availability === Availability.Available || this.vendorMenuItem.supportNextAvailable)) {
      this.loyaltyProgramNextRewardDetails = this.loyaltyProgramService.getNextRewardDetailsForItem({ itemTotalPrice: this.currentCost });
    }
  }
}
