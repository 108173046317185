import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { ModalController, ModalOptions } from '@ionic/angular';
import { Animation, OverlayEventDetail } from '@ionic/core';

import { StatusBarPlugin } from './native-plugins/status-bar.plugin';

import { AlertModalPage, AlertModalPageIdentifier } from '../../modals/alert-modal/alert-modal.page';

import { PlatformService } from './ssr/platform.service';
import { WindowService } from './ssr/window.service';

import {
  browserAlertModalEnterAnimation,
  browserAlertModalLeaveAnimation,
  browserModalEnterAnimation,
  browserModalLeaveAnimation,
} from '../transitions/browser-modal-transition';

import { alertModalAndroidEnterAnimation } from '../../modals/alert-modal/alert-modal-android-enter.animation';
import { alertModalAndroidLeaveAnimation } from '../../modals/alert-modal/alert-modal-android-leave.animation';
import { alertModalIosEnterAnimation } from '../../modals/alert-modal/alert-modal-ios-enter.animation';
import { alertModalIosLeaveAnimation } from '../../modals/alert-modal/alert-modal-ios-leave.animation';
import { AlertModalOptions, AppModalOptions, CardModalOptions, SheetModalOptions } from '../models/modal-options';

@Injectable({ providedIn: 'root' })
export class ModalService {
  private isIos: boolean;
  private isBrowser: boolean;

  constructor(
    private modalCtrl: ModalController,
    private windowService: WindowService,
    private platformService: PlatformService,
    private translateService: TranslateService,
    private statusBarPlugin: StatusBarPlugin,
  ) {
    this.isIos = this.platformService.isIos;
    this.isBrowser = this.platformService.isBrowser;

    if (this.isBrowser && this.windowService.isWindowDefined) {
      // When running the app in the browser we need to make the back button to
      // dismiss any active/top modals instead of going back to the previous page
      this.windowService.window.addEventListener('popstate', () => {
        void this.modalCtrl
          .getTop()
          .then((modal) => {
            if (modal && modal.dismiss && modal.canDismiss) {
              void modal.dismiss();
            }
          })
          .catch(() => {});
      });
    }
  }

  private get alertModalEnterAnimation(): (baseElement: HTMLElement) => Animation {
    return this.isBrowser ? browserAlertModalEnterAnimation : this.isIos ? alertModalIosEnterAnimation : alertModalAndroidEnterAnimation;
  }

  private get alertModalLeaveAnimation(): (baseElement: HTMLElement) => Animation {
    return this.isBrowser ? browserAlertModalLeaveAnimation : this.isIos ? alertModalIosLeaveAnimation : alertModalAndroidLeaveAnimation;
  }

  public showAlert<T>(options: AlertModalOptions | AppModalOptions): Promise<T> {
    return 'component' in options ? this.showModalAsAlert<T>(options) : this.showAlertModal<T>(options);
  }

  public showCard<T>(options: CardModalOptions): Promise<T> {
    if (this.platformService.isBrowser) {
      return this.showModal({
        ...options,
        presentingElement: undefined,
        enterAnimation: browserModalEnterAnimation,
        leaveAnimation: browserModalLeaveAnimation,
      });
    }

    if (this.isIos && this.windowService.isWindowDefined) {
      try {
        const mainEl = this.windowService.window.document.getElementById('main');
        mainEl.style.background = 'black';
      } catch (e) {}

      const existingStatusBarTextColor = this.statusBarPlugin.getStatusBarTextColor();
      this.statusBarPlugin.useLightTextColor();

      return this.presentModal<T>({ ...options }).then((data) => {
        this.statusBarPlugin.setStatusBarTextColor(existingStatusBarTextColor);
        return data;
      });
    } else {
      delete options.presentingElement;
      return this.showSheet({ ...options, breakpoints: [0, 1], initialBreakpoint: 1 });
    }
  }

  // IMPORTANT: this method will show a 600x600px popup for tablet/desktop
  // or a full-height "sheet" (with no handler) for mobile when running the
  // app on the browser.
  // If we want to make the height to be based on the content, please send
  // { cssClass: 'auto-height'} in the options but make sure the content
  // doesn't use <ion-content> and uses <div class="inner-content"> instead.
  public showSheet<T>(options: SheetModalOptions): Promise<T> {
    if (this.platformService.isBrowser) {
      return this.showModal({
        ...options,
        breakpoints: undefined,
        initialBreakpoint: undefined,
        enterAnimation: browserModalEnterAnimation,
        leaveAnimation: browserModalLeaveAnimation,
      });
    }

    options.backdropDismiss = options.backdropDismiss ?? true;
    return this.presentModal<T>({ ...options, handle: true, showBackdrop: true });
  }

  public showModal<T>(options: AppModalOptions): Promise<T> {
    const cssClass = options.cssClass ? options.cssClass : '';

    return this.presentModal<T>({
      ...options,
      cssClass: options.setAutoHeightOnBrowser && this.isBrowser ? 'auto-height' + (cssClass ? ` ${cssClass}` : '') : cssClass,
    });
  }

  public dismissModal<T = unknown>({ data, role, id }: { data?: T; role?: string; id: string }): Promise<boolean> {
    return this.modalCtrl.dismiss(data, role, id).catch(() => true);
  }

  public dismissAllModals(): Promise<boolean> {
    return this.modalCtrl.getTop().then((topModal) => {
      if (!topModal) {
        return true;
      }

      return topModal
        .dismiss()
        .then(() => this.dismissAllModals())
        .catch(() => true);
    });
  }

  public dismissTopModal(): Promise<boolean> {
    return this.modalCtrl
      .getTop()
      .then((modal) => (modal?.dismiss ? modal.dismiss() : true))
      .catch(() => true);
  }

  private showAlertModal<T>(options: AlertModalOptions): Promise<T> {
    if (!options.buttons?.length) {
      options.buttons = [{ text: this.translateService.instant('OK') as string }];
    }

    return this.presentModal<T>({
      id: AlertModalPageIdentifier,
      component: AlertModalPage,
      componentProps: options,
      cssClass: options?.cssClass ? options.cssClass : undefined,
      enterAnimation: this.alertModalEnterAnimation,
      leaveAnimation: this.alertModalLeaveAnimation,
    });
  }

  private showModalAsAlert<T>(options: AppModalOptions): Promise<T> {
    return this.presentModal<T>({
      ...options,
      cssClass: AlertModalPageIdentifier,
      enterAnimation: this.alertModalEnterAnimation,
      leaveAnimation: this.alertModalLeaveAnimation,
    });
  }

  private presentModal<T = void>(options: ModalOptions): Promise<T> {
    if (this.isBrowser && this.windowService.isWindowDefined) {
      // We need to push a new state into the navigation history so that pressing
      // the back button from the browser doesn't redirect the user to the prev page
      if (!(this.windowService.window.history.state as { modal: boolean }).modal) {
        const modalState = { modal: true };
        history.pushState(modalState, null);
      }
    }

    return new Promise((resolve) => {
      const classes =
        options.cssClass && typeof options.cssClass === 'object'
          ? [...options.cssClass]
          : typeof options.cssClass === 'string'
          ? [options.cssClass]
          : [];

      options.cssClass = [...classes, options.id];

      // In Ionic v7 the default for canDismiss was chagned to true but there were some issues
      // when setting it to null so the idea is to make sure it's always set to true or false
      // and not null or undefined
      // https://bilbayt.atlassian.net/browse/CA-2870
      if (options.canDismiss === null || options.canDismiss === undefined) {
        options.canDismiss = true;
      }

      void this.modalCtrl.create(options).then((modal) => {
        void modal.onDidDismiss().then(() => {
          if (this.isIos && this.windowService.isWindowDefined && options.presentingElement) {
            try {
              const mainEl = this.windowService.window.document.getElementById('main');
              mainEl.style.background = null;
            } catch (e) {}
          }
        });
        void modal.onWillDismiss().then((result: OverlayEventDetail<T>) => resolve(result?.data));
        void modal.present();
      });
    });
  }
}
