import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { GestureController } from '@ionic/angular';
import { Gesture } from '@ionic/core';

import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

const STAR_WIDTH = 28;

@Component({
  selector: 'app-editable-rating',
  templateUrl: './editable-rating.component.html',
  styleUrls: ['./editable-rating.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditableRatingComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output()
  public changeRating: EventEmitter<number> = new EventEmitter<number>();

  public rating = 0;

  private startLimit: number;
  private unsubscribe$ = new Subject<void>();
  private distinctValuesEmitter = new Subject<number>();

  constructor(private elementRef: ElementRef<HTMLElement>, private getstureCtrl: GestureController) {}

  @Input()
  public set initialValue(value: number) {
    this.rating = value || 0;
  }

  ngOnInit() {
    this.distinctValuesEmitter.pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$)).subscribe((value) => {
      this.rating = value;
      this.changeRating.next(value);
    });
  }

  ngAfterViewInit() {
    const gesture: Gesture = this.getstureCtrl.create({
      el: this.elementRef.nativeElement,
      gestureName: 'drag-gesture',
      gesturePriority: 31,
      direction: 'x',
      threshold: 10,
      passive: false,
      onStart: () => {
        this.startLimit = this.elementRef.nativeElement.getBoundingClientRect().x;
      },
      onMove: (event) => {
        const currentX = event.currentX;
        const relativeCurrentX = currentX - this.startLimit;
        const selectedStarProgress = relativeCurrentX / STAR_WIDTH;

        if (selectedStarProgress >= 4.5) {
          this.distinctValuesEmitter.next(5);
        } else if (selectedStarProgress < 1.5) {
          this.distinctValuesEmitter.next(1);
        } else {
          this.distinctValuesEmitter.next(Math.ceil(selectedStarProgress));
        }
      },
    });

    gesture.enable();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  public handleStarTap(newRating: number): void {
    this.distinctValuesEmitter.next(newRating);
  }
}
