import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { TestFormModalPage } from './test-form-modal.page';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, FormsModule, IonicModule, TranslateModule.forChild()],
  declarations: [TestFormModalPage],
})
export class TestFormModalPageModule {}
