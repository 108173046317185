import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { debounceTime, filter, forkJoin, from, map, Observable, Subject, switchMap, takeUntil, tap } from 'rxjs';

import { AreaSelectionModalPage, AreaSelectionModalPageIdentifier } from '../../../modals/area-selection-modal/area-selection-modal.page';
import {
  SpecialRequestModalPage,
  SpecialRequestModalPageIdentifier,
} from '../../../modals/special-request-modal/special-request-modal.page';
import {
  TimeSlotSelectionModalPage,
  TimeSlotSelectionModalPageIdentifier,
} from '../../../modals/time-slot-selection-modal/time-slot-selection-modal.page';

import { WarningSeverity } from '../../../core/enums/warning-severity.enum';
import { WarningType } from '../../../core/enums/warning-type.enum';

import { Address } from '../../../core/models/address.model';
import { CartResponse, CartValidatedItem, CartValidatedVendor } from '../../../core/models/cart-response.model';
import { Cart, CartItem } from '../../../core/models/cart.model';
import { GroceryMenuItem } from '../../../core/models/grocery-menu-item.model';
import { GroceryVendorDetails } from '../../../core/models/grocery-vendor-details.model';
import { SelectedDateTimePerVendor } from '../../../core/models/order-in-progress.model';
import { SelectedTimeSlot, SelectedTimeSlotsPerVendor } from '../../../core/models/selected-time-slots-per-vendor.model';
import { VendorDetails } from '../../../core/models/vendor-details.model';

import { AccountService } from '../../../core/services/account.service';
import { AddressService } from '../../../core/services/address.service';
import { AnalyticsService } from '../../../core/services/analytics.service';
import { CartService } from '../../../core/services/cart.service';
import { DateTimeService } from '../../../core/services/date-time.service';
import { LoggerService } from '../../../core/services/logger.service';
import { LoginService } from '../../../core/services/login.service';
import { ModalService } from '../../../core/services/modal.service';
import { NavigationService } from '../../../core/services/navigation.service';
import { NoticePeriodService } from '../../../core/services/notice-period.service';
import { OverlayService } from '../../../core/services/overlay.service';
import { SettingsService } from '../../../core/services/settings.service';
import { PlatformService } from '../../../core/services/ssr/platform.service';
import { ValidationService } from '../../../core/services/validation.service';
import { VendorsService } from '../../../core/services/vendors.service';

import { AnalyticsConfig, TOKEN_ANALYTICS_CONFIG } from '../../../core/config/analytics.config';

import {
  GroceryItemDetailsModalComponent,
  GroceryItemDetailsModalPageIdentifier,
  GroceryItemDetailsModalParams,
  GroceryItemDetailsModalResult,
} from '../../../modals/grocery-item-details-modal/grocery-item-details-modal.component';
import {
  MenuItemDetailsModalComponent,
  MenuItemDetailsModalPageIdentifier,
  MenuItemDetailsModalParams,
  MenuItemDetailsModalResult,
} from '../../../modals/menu-item-details-modal/menu-item-details-modal.component';

import { ErrorPageParams } from '../../../pages/error/error-routing.module';

import { CartDetailsComponentAnimations } from './cart-details.animations';

const DEBOUNCE_TIME_IN_MS = 500;

interface ItemQuantitiesUpdates {
  vendorId: number;
  itemId: number;
  quantity: number;
}

interface CartValidationAlertButtonType {
  text: string;
  isDestructive?: boolean;
  handler: () => void;
}

@Component({
  selector: 'app-cart-details',
  templateUrl: './cart-details.component.html',
  styleUrls: ['./cart-details.component.scss'],
  animations: [...CartDetailsComponentAnimations],
  encapsulation: ViewEncapsulation.None,
})
export class CartDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  public elementRef: ElementRef<HTMLElement>;

  @Input()
  public inEditMode: boolean;

  @Input()
  public hideTotals?: boolean;

  @Input()
  public showOnlyVendorId?: number;

  @Output()
  public checkoutStarted = new EventEmitter<void>();

  @Output()
  public cartTotalUpdated = new EventEmitter<void>();

  @Output()
  public editModeChanged = new EventEmitter<boolean>();

  public cart: Cart;
  public cartDetails: CartResponse;

  public isBrowser: boolean;
  public isCartEmpty: boolean;

  // We use a different property for showing the empty
  // cart message so that we can show it only after the
  // footer animation finishes
  public showEmptyCartMessage: boolean;

  public isTimeSlotBasedOrder: boolean;
  public isDateTimeBasedOrder: boolean;
  public isOrderForNowSelected: boolean;
  public groceryItemServiceType: number;
  public isAdditionalCardSupportedByServiceType: { [key: number]: boolean };

  public subtotal: number;
  public discount: number;
  public walletCredits: number;
  public total: number;

  public selectedDateTimesPerVendor: SelectedDateTimePerVendor;
  public selectedTimeSlotsPerVendor: SelectedTimeSlotsPerVendor = {};
  public listOfVendorIdsDateTimeUpdatedAutomatically: { [key: number]: boolean };
  public noticePeriodWarnings: Record<number, string> = {};

  private itemsQuantityUpdates: { [vendorId: number]: { [itemId: number]: number } } = {};

  private unsubscribe$ = new Subject<void>();

  private isShowingRemoveItemConfirmationAlert = false;
  private updateCartQuantities$ = new Subject<ItemQuantitiesUpdates | undefined>();

  constructor(
    private modalService: ModalService,
    private navigationService: NavigationService,
    private overlayService: OverlayService,
    private validationService: ValidationService,
    private settingsService: SettingsService,
    private platformService: PlatformService,
    private cartService: CartService,
    private noticePeriodService: NoticePeriodService,
    private translateService: TranslateService,
    private loggerService: LoggerService,
    private loginService: LoginService,
    private accountService: AccountService,
    private analyticsService: AnalyticsService,
    private vendorsService: VendorsService,
    private addressService: AddressService,
    private dateTimeService: DateTimeService,
    @Inject(TOKEN_ANALYTICS_CONFIG) private analyticsConfig: AnalyticsConfig,
  ) {}

  ngOnInit() {
    this.isBrowser = this.platformService.isBrowser;
    this.groceryItemServiceType = this.settingsService.getServiceTypeBasedOnCategories();

    this.isAdditionalCardSupportedByServiceType = {};
    for (const serviceType of this.settingsService.getServiceTypes()) {
      this.isAdditionalCardSupportedByServiceType[serviceType] = this.settingsService.isAdditionalCardSupported(serviceType);
    }

    this.updateCartQuantities$
      .pipe(
        tap((params) => {
          if (params) {
            const { vendorId, itemId, quantity } = params;

            if (!this.itemsQuantityUpdates[vendorId]) {
              this.itemsQuantityUpdates[vendorId] = {};
            }

            this.itemsQuantityUpdates[vendorId][itemId] = quantity;
          }
        }),
        debounceTime(DEBOUNCE_TIME_IN_MS),
        filter(() => !this.isShowingRemoveItemConfirmationAlert),
        filter(() => Object.keys(this.itemsQuantityUpdates).length > 0),
        switchMap(() => this.updateQuantityAndUpdateCart()),
        tap(() => {
          this.itemsQuantityUpdates = {};
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    void this.updateCart().then(({ updated }) => {
      if (!updated) {
        void this.navigationService.navigateBack();
        return;
      }

      if (!this.isBrowser) {
        void this.platformService.wait(400).then(() => {
          if (this.isDateTimeBasedOrder) {
            this.maybeShowDateTimePickerAutomatically();
          } else if (this.isTimeSlotBasedOrder) {
            this.maybeShowTimeSlotPromptAutomatically();
          }
        });
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  public startCheckout(): void {
    if (this.isDateTimeBasedOrder && !this.isOrderForNowSelected && !this.cartService.hasSelectedDateTimeForAllVendorsInCart()) {
      this.showDateTimePickerAndUpdateVendorsWithInvalidDateTime();
      return;
    }

    if (this.isTimeSlotBasedOrder && !this.cartService.hasSelectedTimeSlotsForAllVendorsInCart()) {
      this.overlayService.showToast({
        message: this.translateService.instant('CART_PAGE.MISSING_TIME_SLOTS') as string,
        showCloseButton: true,
        type: 'error',
      });
      return;
    }

    if (!this.cartDetails) {
      return;
    }

    void this.overlayService.showLoading().then(() => {
      this.cartService
        .validateAndUpdateCart()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: () => {
            const cart = this.cartService.getCart();
            const cartDetails = this.cartService.getCartDetails();

            void this.overlayService.hideLoading();

            if (cartDetails.warningSeverity === WarningSeverity.None) {
              this.checkUserLoggedInAndEmitCheckoutStartedEvent();
            } else {
              this.cart = cart;
              this.cartDetails = cartDetails;

              const { title, message, buttons, isError } = this.getWarningSummaryDetails(cartDetails);

              if (title) {
                void this.modalService.showAlert({
                  title,
                  message,
                  buttons,
                });
              } else {
                this.overlayService.showToast({
                  message,
                  type: isError ? 'error' : 'info',
                  showCloseButton: true,
                  dismissCallback: () => {
                    if (cartDetails.warningSeverity !== WarningSeverity.Critical) {
                      this.checkUserLoggedInAndEmitCheckoutStartedEvent();
                    }
                  },
                });
              }
            }
          },
          error: (error: HttpErrorResponse) => {
            this.loggerService.error({ component: 'CartPage', message: "couldn't get cart details to start checkout", error });

            const pageParams: ErrorPageParams = { message: this.validationService.getErrorMessage(error) };
            void this.navigationService.navigateTo('/error', true, { state: pageParams }).then(() => {
              void this.overlayService.hideLoading();
            });
          },
        });
    });
  }

  public clearCart(skipPrompt?: boolean): void {
    const clearCart = () => {
      this.editModeChanged.emit(false);

      this.cartService.clearCartAndSelectedPromotion();
      void this.updateCart();
    };

    if (skipPrompt) {
      clearCart();
    } else {
      void this.modalService.showAlert({
        title: this.translateService.instant('CART_PAGE.CLEAR_CART_ALERT_TITLE') as string,
        inlineButtons: true,
        buttons: [
          {
            isDestructive: true,
            text: this.translateService.instant('CLEAR') as string,
            handler: () => clearCart(),
          },
          { text: this.translateService.instant('CANCEL') as string },
        ],
      });
    }
  }

  public onEditMenuItem({
    vendorId,
    itemId,
    incrementItemQuantity,
    decrementItemQuantity,
  }: {
    vendorId: number;
    itemId: number;
    incrementItemQuantity?: boolean;
    decrementItemQuantity?: boolean;
  }): void {
    this.editModeChanged.emit(false);

    const vendor = this.cart.caterers.find((cartVendor) => cartVendor.catererId === vendorId);
    const item = vendor.items.find((cartItem) => cartItem.menuItemId === itemId);

    this.showItemDetailsModal({
      vendorId,
      itemId,
      serviceType: item.serviceType,
      incrementItemQuantity,
      decrementItemQuantity,
    });
  }

  public onDeleteVendor(vendor: CartValidatedVendor): void {
    this.cartService.removeVendorFromCart(vendor.catererId);

    void this.updateCart();
  }

  public onIncreaseItemQuantity(e: Event, item: CartValidatedItem): void {
    e.stopPropagation();

    if (item.quantity === item.maximumQuantity) {
      return;
    }

    if (!this.canUpdateItemDirectly(item)) {
      this.onEditMenuItem({ vendorId: item.catererId, itemId: item.menuItemId, incrementItemQuantity: true });
      return;
    }

    item.quantity += item.quantityIncrement;
    this.updateCartQuantities$.next({ vendorId: item.catererId, itemId: item.menuItemId, quantity: item.quantity });
  }

  public onDecreaseItemQuantity(e: Event, item: CartValidatedItem): void {
    e.stopPropagation();

    // minimumQuantity can be 0 if the item doesn't really have a min quantity set in the backend
    const itemMinQuantity = item.minimumQuantity || 1;

    // Since we debounce requests to the API, it may happen that we show
    // quantity being 0 for a few milliseconds — when that happens we
    // should ignore any changes until the cart is validated/updated
    if (item.quantity < itemMinQuantity) {
      return;
    }

    if (this.shouldRemoveItemFromCart(item, itemMinQuantity)) {
      this.removeItemAfterQuantityUpdate(item, itemMinQuantity);
      return;
    }

    if (!this.canUpdateItemDirectly(item)) {
      this.onEditMenuItem({ vendorId: item.catererId, itemId: item.menuItemId, decrementItemQuantity: true });
      return;
    }

    item.quantity -= item.quantityIncrement;
    this.updateCartQuantities$.next({ vendorId: item.catererId, itemId: item.menuItemId, quantity: item.quantity });
  }

  public onUpdateItemNotes(vendorName: string, item: CartValidatedItem): void {
    void this.modalService
      .showAlert<string>({
        component: SpecialRequestModalPage,
        id: SpecialRequestModalPageIdentifier,
        componentProps: {
          name: vendorName,
          comment: item.specialRequests,
          isGiftCard: this.isAdditionalCardSupportedByServiceType[item.serviceType],
        },
      })
      .then((comment) => {
        if (comment || comment === '') {
          item.specialRequests = comment;
          this.cartService.updateItemSpecialRequest({ vendorId: item.catererId, itemId: item.menuItemId, request: comment });
        }
      });
  }

  public onEditDeliveryTime(vendorId: number): void {
    if (!vendorId) {
      return;
    }

    if (this.isDateTimeBasedOrder) {
      this.editSelectedDateTime({ vendorId });
      return;
    }

    if (this.isTimeSlotBasedOrder) {
      this.editSelectedTimeSlot({ vendorId });
      return;
    }
  }

  public identifyVendor(_: number, vendor: CartValidatedVendor): number {
    return vendor.catererId;
  }

  public identifyMenuItem(_: number, item: CartValidatedItem): number {
    return item.menuItemId;
  }

  public updateCart(): Promise<{ updated: boolean }> {
    return new Promise((resolve) => {
      this.cart = this.cartService.getCart();
      this.isCartEmpty = this.cartService.isCartEmpty();
      this.isOrderForNowSelected = this.cartService.isBilbaytNowCart();
      this.isTimeSlotBasedOrder = this.cartService.isCartBasedOnTimeSlots();
      this.isDateTimeBasedOrder = this.cartService.isCartBasedDateTime();
      this.listOfVendorIdsDateTimeUpdatedAutomatically = this.cartService.getListOfVendorIdsDateTimeUpdatedAutomatically();
      this.editModeChanged.emit(this.inEditMode && !this.isCartEmpty);

      const delay = this.isBrowser ? Promise.resolve() : this.platformService.wait(500);
      void delay.then(() => (this.showEmptyCartMessage = this.isCartEmpty));

      if (this.isCartEmpty) {
        resolve({ updated: true });
        return;
      }

      void this.overlayService.showLoading();

      this.cartService
        .validateAndUpdateCart()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: () => {
            this.cart = this.cartService.getCart();
            this.cartDetails = this.cartService.getCartDetails();
            this.selectedDateTimesPerVendor = this.cartService.getSelectedDateTimes();
            this.selectedTimeSlotsPerVendor = this.cartService.getSelectedTimeSlots();
            this.updateNoticePeriodWarnings();
            this.updateCartTotals();

            void this.overlayService.hideLoading();
            resolve({ updated: true });
          },
          error: (error: HttpErrorResponse) => {
            this.loggerService.error({ component: 'CartPage', message: "couldn't get user wallet, promotions and cart", error });
            void this.overlayService
              .hideLoading()
              .then(() => this.overlayService.showToast({ type: 'error', message: this.validationService.getErrorMessage(error) }));

            resolve({ updated: false });
          },
        });
    });
  }

  private checkUserLoggedInAndEmitCheckoutStartedEvent(): void {
    // If the user wants to proceed we can assume he/she accepted
    // the new date/time updated automatically by us
    this.cart.caterers.forEach((vendor) => this.cartService.removeVendorFromListOfVendorIdsDateTimeUpdatedAutomatically(vendor.catererId));

    if (this.accountService.isLoggedIn()) {
      this.checkoutStarted.next();
      return;
    }

    void this.loginService.showSocialLogin(this.translateService.instant('SOCIAL_LOGIN.PLACE_ORDER_MESSAGE') as string).then(() => {
      if (this.accountService.isLoggedIn()) {
        this.checkoutStarted.next();
      }
    });
  }

  private showItemDetailsModal({
    vendorId,
    itemId,
    serviceType,
    incrementItemQuantity,
    decrementItemQuantity,
  }: {
    vendorId: number;
    itemId: number;
    serviceType: number;
    incrementItemQuantity: boolean;
    decrementItemQuantity: boolean;
  }): void {
    const isGroceryItem = this.settingsService.isServiceTypeBasedOnCategories(serviceType);

    if (!isGroceryItem) {
      const isServiceTypeBasedOnTimeSlots = this.settingsService.isServiceTypeBasedOnTimeSlots(serviceType);

      const isOrderForNowSelected = this.cartService.isBilbaytNowCart();
      const selectedDateTimeForVendor = this.cartService.getSelectedDateTimeForVendor(vendorId);
      const dateTime = isServiceTypeBasedOnTimeSlots || isOrderForNowSelected ? null : selectedDateTimeForVendor ?? null;

      void this.getMenuVendorDetailsFromApi({
        vendorId,
        itemId,
        serviceType,
        areaId: this.cartService.getArea()?.areaId,
        dateTime,
        orderForNow: isOrderForNowSelected,
      }).then((vendorDetails) => {
        if (!vendorDetails) {
          return;
        }

        const itemDetailsParams: MenuItemDetailsModalParams = {
          itemId,
          vendorDetails,
          selectedServiceType: serviceType,
          selectedAddress: this.cartService.getAddress(),
          selectedDateTime: dateTime,
          isOrderForNowSelected,
          incrementQuantity: incrementItemQuantity ?? false,
          decrementQuantity: decrementItemQuantity ?? false,
        };

        void this.modalService
          .showModal<MenuItemDetailsModalResult>({
            component: MenuItemDetailsModalComponent,
            id: MenuItemDetailsModalPageIdentifier,
            setAutoHeightOnBrowser: true,
            componentProps: { itemDetailsParams },
          })
          .then((result) => {
            if (result?.addedToCart) {
              void this.updateCart();
            }
          });
      });
    } else {
      void this.getGroceryVendorAndItemDetailsFromApi(vendorId, itemId).then(({ vendorDetails, itemDetails }) => {
        if (!vendorDetails || !itemDetails) {
          return;
        }

        const itemDetailsParams: GroceryItemDetailsModalParams = {
          itemDetails,
          vendorDetails,
          selectedAddress: this.cartService.getAddress(),
        };

        void this.modalService
          .showCard<GroceryItemDetailsModalResult>({
            component: GroceryItemDetailsModalComponent,
            id: GroceryItemDetailsModalPageIdentifier,
            setAutoHeightOnBrowser: true,
            presentingElement: this.elementRef?.nativeElement ?? undefined,
            componentProps: { itemDetailsParams },
          })
          .then((result) => {
            if (result?.addedToCart) {
              void this.updateCart();
            }
          });
      });
    }
  }

  private getMenuVendorDetailsFromApi({
    vendorId,
    itemId,
    serviceType,
    areaId,
    dateTime,
    orderForNow,
  }: {
    vendorId: number;
    itemId: number;
    serviceType: number;
    areaId: number;
    dateTime: string;
    orderForNow: boolean;
  }): Promise<VendorDetails> {
    return new Promise((resolve) => {
      void this.overlayService.showLoading().then(() => {
        this.vendorsService
          .getVendorDetailsById({
            vendorId,
            itemId,
            areaId,
            dateTime,
            orderForNow,
            serviceTypes: serviceType === this.settingsService.getFoodServiceType() ? serviceType : undefined,
          })
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe({
            next: (vendorDetails) => {
              resolve(vendorDetails);
              void this.overlayService.hideLoading();
            },
            error: (error: HttpErrorResponse) => {
              void this.overlayService.hideLoading().then(() => {
                const errorMessage = this.validationService.getErrorMessage(error);
                this.loggerService.info({
                  component: 'CartPage',
                  message: "couldn't get menu vendor details",
                  details: { error, messageShownToUser: errorMessage },
                });
                this.overlayService.showToast({ type: 'error', showCloseButton: true, message: errorMessage });
                resolve(null);
              });
            },
          });
      });
    });
  }

  private getGroceryVendorAndItemDetailsFromApi(
    vendorId: number,
    itemId: number,
  ): Promise<{ vendorDetails: GroceryVendorDetails; itemDetails: GroceryMenuItem }> {
    return new Promise((resolve) => {
      void this.overlayService.showLoading().then(() => {
        forkJoin([this.vendorsService.getGroceryVendorDetails(vendorId), this.vendorsService.getGroceryMenuItemDetails(itemId)])
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe({
            next: ([vendorDetails, itemDetails]) => {
              resolve({ vendorDetails, itemDetails });
              void this.overlayService.hideLoading();
            },
            error: (error: HttpErrorResponse) => {
              void this.overlayService.hideLoading().then(() => {
                const errorMessage = this.validationService.getErrorMessage(error);
                this.loggerService.info({
                  component: 'CartPage',
                  message: "couldn't get grocery item details",
                  details: { error, messageShownToUser: errorMessage },
                });
                this.overlayService.showToast({ type: 'error', showCloseButton: true, message: errorMessage });
                resolve({ vendorDetails: null, itemDetails: null });
              });
            },
          });
      });
    });
  }

  private updateCartTotals() {
    this.discount = this.cartService.getTotalDiscount();
    this.walletCredits = this.cartService.getWalletCreditsUsed();
    this.subtotal = this.cartService.getCartTotalBeforeVendorDiscounts();
    this.total = this.cartService.getCartTotalAfterDiscountsAndWalletCredits();

    this.cartTotalUpdated.emit();
  }

  private updateQuantityAndUpdateCart(): Observable<boolean> {
    return from(this.overlayService.showLoading()).pipe(
      switchMap(() => this.cartService.updateItemQuantity(this.itemsQuantityUpdates)),
      tap((updated) => {
        this.isCartEmpty = this.cartService.isCartEmpty();
        this.isOrderForNowSelected = this.cartService.isBilbaytNowCart();
        this.isTimeSlotBasedOrder = this.cartService.isCartBasedOnTimeSlots();
        this.isDateTimeBasedOrder = this.cartService.isCartBasedDateTime();
        this.listOfVendorIdsDateTimeUpdatedAutomatically = this.cartService.getListOfVendorIdsDateTimeUpdatedAutomatically();
        this.editModeChanged.emit(this.inEditMode && !this.isCartEmpty);

        this.cart = this.cartService.getCart();
        this.cartDetails = this.cartService.getCartDetails();
        this.selectedDateTimesPerVendor = this.cartService.getSelectedDateTimes();
        this.selectedTimeSlotsPerVendor = this.cartService.getSelectedTimeSlots();
        this.updateNoticePeriodWarnings();
        this.updateCartTotals();

        const delay = this.isBrowser ? Promise.resolve() : this.platformService.wait(500);
        void delay.then(() => (this.showEmptyCartMessage = this.isCartEmpty));

        if (!updated) {
          this.overlayService.showToast({
            type: 'error',
            message: this.validationService.getDefaultErrorMessage(),
            showCloseButton: true,
          });
        }

        void this.overlayService.hideLoading();
      }),
    );
  }

  private updateNoticePeriodWarnings() {
    if (!this.noticePeriodWarnings) {
      this.noticePeriodWarnings = {};
    }

    const noticePeriodItems = this.cart.caterers.reduce((pv, v) => pv.concat(v.items.filter((i) => i.noticePeriod)), [] as Array<CartItem>);

    for (const item of noticePeriodItems) {
      const canCalculateForDateTimeOrder = this.isDateTimeBasedOrder && this.selectedDateTimesPerVendor[item.catererId];

      // It may happen that some vendors don't have any available time slots
      // because of a content problem so we must check if there's a time
      // slot already selected to prevent a JS error
      const canCalculateForTimeSlotOrder = this.isTimeSlotBasedOrder && this.selectedTimeSlotsPerVendor[item.catererId]?.timeSlot;

      if (canCalculateForDateTimeOrder) {
        this.noticePeriodWarnings[item.menuItemId] = this.noticePeriodService.getNoticePeriodWarningForCart({
          dateTime: this.selectedDateTimesPerVendor[item.catererId],
          noticePeriod: item.noticePeriod,
        });
      } else if (canCalculateForTimeSlotOrder) {
        this.noticePeriodWarnings[item.menuItemId] = this.noticePeriodService.getNoticePeriodWarningForCart({
          dateTime: this.dateTimeService.getDateTimeIsoFromTimeSlot(this.selectedTimeSlotsPerVendor[item.catererId].timeSlot),
          noticePeriod: item.noticePeriod,
        });
      }
    }
  }

  private maybeShowTimeSlotPromptAutomatically(): void {
    if (this.isTimeSlotBasedOrder && this.cart.caterers.length === 1 && !this.cartService.getCartTimeSlotPromptAlreadyShown()) {
      this.editSelectedTimeSlot({ vendorId: this.cart.caterers[0].catererId });
      this.cartService.markCartTimeSlotPromptAsAlreadyShown();
    }
  }

  private maybeShowDateTimePickerAutomatically(): void {
    if (this.isDateTimeBasedOrder && !this.isOrderForNowSelected && !this.cartService.hasSelectedDateTimeForAllVendorsInCart()) {
      this.showDateTimePickerAndUpdateVendorsWithInvalidDateTime();
    }
  }

  private editSelectedTimeSlot({ vendorId }: { vendorId: number }): void {
    this.trackChangeTimeSlotEvent(vendorId);

    const selectedTimeSlotForVendor = this.cartService.getSelectedTimeSlotForVendor(vendorId);

    const selectedTimeSlot = selectedTimeSlotForVendor ? selectedTimeSlotForVendor.timeSlot : null;
    const timeSlotsByDate = this.cartDetails.caterers.find((cartVendor) => cartVendor.catererId === vendorId).availableTimeSlots;

    void this.modalService
      .showCard<SelectedTimeSlot>({
        component: TimeSlotSelectionModalPage,
        id: TimeSlotSelectionModalPageIdentifier,
        presentingElement: this.elementRef?.nativeElement ?? undefined,
        componentProps: {
          selectedVendorId: vendorId,
          selectedTimeSlot,
          timeSlotsByDate,
        },
      })
      .then((result) => {
        if (result) {
          this.cartService.setSelectedTimeSlotForVendor(vendorId, {
            dateText: result.dateText,
            timeSlot: result.timeSlot,
          });

          this.selectedTimeSlotsPerVendor = this.cartService.getSelectedTimeSlots();
        }
      });
  }

  private editSelectedDateTime({ vendorId }: { vendorId: number }): void {
    this.trackChangeDateTimeEvent(vendorId);

    const selectedAddress = this.cartService.getAddress();
    const selectedDateTime = this.cartService.getSelectedDateTimeForVendor(vendorId) ?? null;

    void this.overlayService
      .showAreaDateTimePicker({
        enableBackdropDismiss: true,
        hideAddressPicker: true,
        buttonTranslationKey: 'SAVE_AND_CONTINUE',
        selectedAddress,
        selectedDateTime,
        isOrderForNowSelected: this.isOrderForNowSelected,
        isOrderForNowAvailable: this.cartService.isOrderForNowAvailable(),
        availableDateTimesAsync: this.vendorsService.getVendorAvailableDateTimes({
          areaId: selectedAddress?.areaId,
          vendorId,
          itemsIds: this.cartService.getItemsIdsFromVendor(vendorId),
        }),
      })
      .then((areaDateTimeResults) => {
        if (areaDateTimeResults?.selectedSubmitButton) {
          this.cartService.removeVendorFromListOfVendorIdsDateTimeUpdatedAutomatically(vendorId);
          this.listOfVendorIdsDateTimeUpdatedAutomatically = this.cartService.getListOfVendorIdsDateTimeUpdatedAutomatically();
        }

        if (areaDateTimeResults?.hasChangedData) {
          const dateTime = areaDateTimeResults.selectedDateTime;
          const isForNow = !!areaDateTimeResults.isOrderForNowSelected;

          this.cartService.updateIsBilbaytNowCart(isForNow);
          this.cartService.setSelectedDateTimeForVendor(vendorId, dateTime);

          void this.updateCart();
        }
      });
  }

  private showDateTimePickerAndUpdateVendorsWithInvalidDateTime(): void {
    const vendorsIdsWithInvalidDateTime: Array<number> = this.cart.caterers
      .map((vendor) => vendor.catererId)
      .filter(
        (vendorId) =>
          !this.selectedDateTimesPerVendor[vendorId] ||
          this.dateTimeService.isBeforeCurrentLocalTime(this.selectedDateTimesPerVendor[vendorId]),
      );

    if (!vendorsIdsWithInvalidDateTime?.length) {
      return;
    }

    // Find the intersection so that we show date/times where all the invalid vendors are available
    const availableTimesIntersertionAsync = forkJoin(
      vendorsIdsWithInvalidDateTime.map((vendorId) =>
        this.vendorsService.getVendorAvailableDateTimes({
          areaId: this.cartService.getAddress()?.areaId,
          vendorId,
          itemsIds: this.cartService.getItemsIdsFromVendor(vendorId),
        }),
      ),
    ).pipe(
      map((availableDateTimesForAllVendors) =>
        this.vendorsService.getVendorsAvailableDateTimesIntersection(availableDateTimesForAllVendors),
      ),
    );

    void this.overlayService
      .showAreaDateTimePicker({
        enableBackdropDismiss: true,
        hideAddressPicker: true,
        buttonTranslationKey: 'SAVE_AND_CONTINUE',
        selectedAddress: this.cartService.getAddress(),
        isOrderForNowSelected: this.cartService.isBilbaytNowCart(),
        isOrderForNowAvailable: this.cartService.isOrderForNowAvailable(),
        availableDateTimesAsync: availableTimesIntersertionAsync,
      })
      .then((areaDateTimeResults) => {
        const dataHasChanged = areaDateTimeResults?.hasChangedData;

        if (dataHasChanged) {
          const dateTime = areaDateTimeResults.selectedDateTime;
          const isForNow = areaDateTimeResults.isOrderForNowSelected;

          this.cartService.updateIsBilbaytNowCart(isForNow);
          vendorsIdsWithInvalidDateTime.forEach((vendorId) => this.cartService.setSelectedDateTimeForVendor(vendorId, dateTime));

          this.vendorsService.setUpdateSearchResultsBasedOnDateTimeFromVendorId(vendorsIdsWithInvalidDateTime[0]);

          void this.updateCart();
        }
      });
  }

  private canUpdateItemDirectly(item: CartValidatedItem): boolean {
    if (this.settingsService.isServiceTypeBasedOnCategories(item.serviceType)) {
      return true;
    }

    return !item.options?.length && !item.addOns?.length;
  }

  private shouldRemoveItemFromCart(cartItem: CartValidatedItem, itemMinQuantity: number): boolean {
    return this.cartDetails.caterers.some(
      (vendor) =>
        vendor.catererId === cartItem.catererId &&
        vendor.items.some((item) => item.menuItemId === cartItem.menuItemId && item.quantity === itemMinQuantity),
    );
  }

  private removeItemAfterQuantityUpdate(item: CartValidatedItem, itemMinQuantity: number): void {
    // Some items may have a minimum quantity different than 1 which won't
    // make very obvious to the user that decreasing that quantity would actually
    // remove the item from the cart
    const maybeShowConfirmationModal =
      itemMinQuantity !== 1
        ? new Promise((resolve) => {
            this.isShowingRemoveItemConfirmationAlert = true;

            void this.modalService.showAlert({
              title: this.translateService.instant('CART_PAGE.REMOVE_ITEM_ALERT_TITLE') as string,
              message: this.translateService.instant('CART_PAGE.REMOVE_ITEM_ALERT_MESSAGE', { name: item.name }) as string,
              buttons: [
                {
                  text: this.translateService.instant('CANCEL') as string,
                  handler: () => resolve(false),
                },
                {
                  text: this.translateService.instant('CART_PAGE.CTA_REMOVE_ITEM') as string,
                  handler: () => resolve(true),
                  isDestructive: true,
                },
              ],
            });
          })
        : Promise.resolve(true);

    void maybeShowConfirmationModal.then((shouldRemoveItem) => {
      if (shouldRemoveItem) {
        item.quantity = 0;
        this.updateCartQuantities$.next({ vendorId: item.catererId, itemId: item.menuItemId, quantity: item.quantity });
      }

      this.isShowingRemoveItemConfirmationAlert = false;
      this.updateCartQuantities$.next(undefined);
    });
  }

  private getWarningSummaryDetails(cartDetails: CartResponse): {
    message: string;
    isError?: boolean;
    title?: string;
    buttons?: Array<CartValidationAlertButtonType>;
  } {
    const warningSummary = cartDetails.warningsSummary.find((warning) => warning.severity === cartDetails.warningSeverity);

    const cartItems = () => cartDetails.caterers.map((caterer) => caterer.items).reduce((acc, cur) => [...acc, ...cur]);

    const isCartBasedOnDateTime = this.cartService.isCartBasedDateTime();

    const getVendorByWarningType = (warningType: WarningType) =>
      cartDetails.caterers.find((caterer) => caterer.warnings.some((warning) => warning.type === warningType));

    const getVendorById = (vendorId: number) => cartDetails.caterers.find((caterer) => caterer.catererId === vendorId);

    const getItemByWarningType = (warningType: WarningType) =>
      cartItems().find((cartItem) => cartItem.warnings.some((warning) => warning.type === warningType));

    const removeFromCart = (vendorId: number, itemIds?: Array<number>): void => {
      itemIds ? this.cartService.removeItemsFromCart(vendorId, itemIds) : this.cartService.removeVendorFromCart(vendorId);
      void this.updateCart();
    };

    const changeDateForVendorButton = (vendorId: number): CartValidationAlertButtonType => ({
      text: this.translateService.instant('CART_PAGE.CTA_CHANGE_DATE_TIME') as string,
      handler: () => this.editSelectedDateTime({ vendorId }),
    });

    const removeVendorButton = (vendorId: number, name: string): CartValidationAlertButtonType => ({
      text: this.translateService.instant('CART_PAGE.CTA_REMOVE_VENDOR', { vendor: name }) as string,
      isDestructive: true,
      handler: () => removeFromCart(vendorId),
    });

    const removeItemButton = (vendorId: number, itemId: number): CartValidationAlertButtonType => ({
      text: this.translateService.instant('CART_PAGE.CTA_REMOVE_ITEM') as string,
      isDestructive: true,
      handler: () => removeFromCart(vendorId, [itemId]),
    });

    switch (warningSummary.type) {
      case WarningType.DateTimeInPast: {
        return {
          title: warningSummary.title,
          message: warningSummary.message,
          buttons: [
            {
              text: this.translateService.instant('CART_PAGE.CTA_CHANGE_DATE_TIME') as string,
              handler: () => this.showDateTimePickerAndUpdateVendorsWithInvalidDateTime(),
            },
            {
              text: this.translateService.instant('CART_PAGE.CTA_CLEAR_CART') as string,
              isDestructive: true,
              handler: () => this.clearCart(true),
            },
          ],
        };
      }

      case WarningType.UnavailableTimeSlot: {
        const vendor = getVendorByWarningType(WarningType.UnavailableTimeSlot);

        return {
          title: warningSummary.title,
          message: warningSummary.message,
          buttons: [
            {
              text: this.translateService.instant('CART_PAGE.CTA_CHANGE_TIME_SLOT') as string,
              handler: () => this.editSelectedTimeSlot({ vendorId: vendor.catererId }),
            },
          ],
        };
      }

      case WarningType.UnavailableDeliveryArea: {
        const vendor = getVendorByWarningType(WarningType.UnavailableDeliveryArea);

        return {
          title: warningSummary.title,
          message: warningSummary.message,
          buttons: [
            {
              text: this.translateService.instant('CART_PAGE.CTA_CHANGE_ADDRESS') as string,
              handler: () => this.showAreaModalAndUpdateCart(),
            },
            removeVendorButton(vendor.catererId, vendor.name),
          ],
        };
      }

      case WarningType.UnavailableServiceTime: {
        const item = getItemByWarningType(WarningType.UnavailableServiceTime);
        const vendor = getVendorById(item.catererId);

        return {
          title: warningSummary.title,
          message: warningSummary.message,
          buttons: isCartBasedOnDateTime
            ? [changeDateForVendorButton(vendor.catererId), removeVendorButton(vendor.catererId, vendor.name)]
            : [removeVendorButton(vendor.catererId, vendor.name)],
        };
      }

      case WarningType.DeliveryChargeChanged: {
        return { message: warningSummary.message };
      }

      case WarningType.CatererInactive: {
        const vendor = getVendorByWarningType(WarningType.CatererInactive);

        return {
          title: warningSummary.title,
          message: warningSummary.message,
          buttons: [removeVendorButton(vendor.catererId, vendor.name)],
        };
      }

      case WarningType.MinOrderValueChanged: {
        const vendor = getVendorByWarningType(WarningType.MinOrderValueChanged);

        return {
          message: warningSummary.message,
          isError:
            this.cartService.getVendorItemsTotalPrice(vendor.catererId) <= this.cartService.getVendorMinimumOrderValue(vendor.catererId),
        };
      }

      case WarningType.InvalidPromotionCoupon: {
        return { message: warningSummary.message, isError: true };
      }

      case WarningType.ItemInactive: {
        const item = getItemByWarningType(WarningType.ItemInactive);

        return {
          title: warningSummary.title,
          message: warningSummary.message,
          buttons: [
            {
              text: this.translateService.instant('OKAY') as string,
              isDestructive: true,
              handler: () => removeFromCart(item.catererId, [item.menuItemId]),
            },
          ],
        };
      }

      case WarningType.ItemInsufficientNotice: {
        const item = getItemByWarningType(WarningType.ItemInsufficientNotice);

        return {
          title: warningSummary.title,
          message: warningSummary.message,
          buttons: isCartBasedOnDateTime
            ? [changeDateForVendorButton(item.catererId), removeItemButton(item.catererId, item.menuItemId)]
            : [removeItemButton(item.catererId, item.menuItemId)],
        };
      }

      case WarningType.ItemPriceChanged: {
        return { message: warningSummary.message };
      }

      case WarningType.InsufficientMinOrderValue: {
        return { message: warningSummary.message, isError: true };
      }

      case WarningType.OptionInactive:
      case WarningType.AddonInactive:
      case WarningType.OptionsMinError:
      case WarningType.OptionsMaxError:
      case WarningType.AddonsMinError:
      case WarningType.AddonsMaxError: {
        const isOptionInactive = warningSummary.type === WarningType.OptionInactive;
        const isAddonInactive = warningSummary.type === WarningType.AddonInactive;
        const isOptionInvalid = warningSummary.type === WarningType.OptionsMinError || warningSummary.type === WarningType.OptionsMaxError;

        const item = cartItems().find((cartItem) => {
          if (isOptionInactive) {
            return cartItem.options.some((option) => option.warnings.some((warning) => warning.type === WarningType.OptionInactive));
          } else if (isAddonInactive) {
            return cartItem.addOns.some((addon) => addon.warnings.some((warning) => warning.type === WarningType.AddonInactive));
          } else if (isOptionInvalid) {
            return cartItem.warnings.some(
              (warning) => warning.type === WarningType.OptionsMinError || warning.type === WarningType.OptionsMaxError,
            );
          } else {
            return cartItem.warnings.some(
              (warning) => warning.type === WarningType.AddonsMinError || warning.type === WarningType.AddonsMaxError,
            );
          }
        });

        return {
          title: warningSummary.title,
          message: warningSummary.message,
          buttons: [
            {
              text: this.translateService.instant('CART_PAGE.CTA_EDIT_ITEM') as string,
              handler: () => this.onEditMenuItem({ vendorId: item.catererId, itemId: item.menuItemId }),
            },
            removeItemButton(item.catererId, item.menuItemId),
          ],
        };
      }

      case WarningType.UnavailableFemaleService: {
        const item = cartItems().find((cartItem) =>
          cartItem.warnings.some((warning) => warning.type === WarningType.UnavailableFemaleService),
        );
        const vendor = cartDetails.caterers.find((caterer) => caterer.catererId === item.catererId);

        return {
          title: warningSummary.title,
          message: warningSummary.message,
          buttons: isCartBasedOnDateTime
            ? [changeDateForVendorButton(vendor.catererId), removeVendorButton(vendor.catererId, vendor.name)]
            : [removeVendorButton(vendor.catererId, vendor.name)],
        };
      }

      case WarningType.FemaleServiceCostHasChanged: {
        return { message: warningSummary.message };
      }

      case WarningType.OutOfStock: {
        const item = cartItems().find((cartItem) => cartItem.warnings.some((warning) => warning.type === WarningType.OutOfStock));

        return {
          title: warningSummary.title,
          message: warningSummary.message,
          buttons: [removeItemButton(item.catererId, item.menuItemId)],
        };
      }

      default: {
        return {
          message: warningSummary.message,
          isError: true,
        };
      }
    }
  }

  private showAreaModalAndUpdateCart(): void {
    const currentAddress = this.cartService.getAddress();

    void this.modalService
      .showCard<Address>({
        component: AreaSelectionModalPage,
        id: AreaSelectionModalPageIdentifier,
        presentingElement: this.elementRef?.nativeElement ?? undefined,
        componentProps: {
          selectedAddress: this.cartService.getAddress(),
          showCloseButton: true,
        },
      })
      .then((address) => {
        if (!address) {
          return;
        }

        const hasLocationChanged = this.addressService.hasLocationChanged(currentAddress, address);

        if (hasLocationChanged) {
          this.cartService.updateOrderAddress(address);
          void this.updateCart();
        }
      });
  }

  private trackChangeTimeSlotEvent(vendorId: number): void {
    void this.analyticsService.trackEvent({
      name: this.analyticsConfig.eventName.cartPageChangeTimeSlot,
      data: { [this.analyticsConfig.propertyName.vendorId]: vendorId },
      includeAreaDateTime: true,
    });
  }

  private trackChangeDateTimeEvent(vendorId: number): void {
    void this.analyticsService.trackEvent({
      name: this.analyticsConfig.eventName.cartPageChangeDateTime,
      data: { [this.analyticsConfig.propertyName.vendorId]: vendorId },
      includeAreaDateTime: true,
    });
  }
}
