import { AddressModalPageModule } from './address-modal/address-modal.module';
import { AlertModalPageModule } from './alert-modal/alert-modal.module';
import { AreaSelectionModalPageModule } from './area-selection-modal/area-selection-modal.module';
import { BrowserCartModalPageModule } from './browser-cart-modal/browser-cart-modal.module';
import { BrowserCountryPickerModalPageModule } from './browser-country-picker-modal/browser-country-picker-modal.module';
import { BrowserDateTimePickerModalPageModule } from './browser-date-time-picker-modal/browser-date-time-picker-modal.module';
import { ContactModalPageModule } from './contact-modal/contact-modal.module';
import { CouponCodeModalPageModule } from './coupon-code-modal/coupon-code-modal.module';
import { CreditCardModalPageModule } from './credit-card-modal/credit-card-modal.module';
import { DimensionRequirementsModalPageModule } from './dimension-requirements-modal/dimension-requirements-modal.module';
import { EditWishlistModalPageModule } from './edit-wishlist-modal/edit-wishlist-modal.module';
import { EnableLocationServicesModalPageModule } from './enable-location-services-modal/enable-location-services-modal.module';
import { GoogleMapsAutocompleteModalPageModule } from './google-maps-autocomplete-modal/google-maps-autocomplete-modal.module';
import { GroceryItemDetailsModalComponentModule } from './grocery-item-details-modal/grocery-item-details-modal.module';
import { IdentifiedLocationModalPageModule } from './identified-location-modal/identified-location-modal.module';
import { ImagesPreviewModalPageModule } from './images-preview-modal/images-preview-modal.module';
import { ItemAvailabilityDateTimePickerModalPageModule } from './item-availability-date-time-picker-modal/item-availability-date-time-picker-modal.module';
import { LogInModalPageModule } from './log-in-modal/log-in-modal.module';
import { LoyaltyProgramModalPageModule } from './loyalty-program-modal/loyalty-program-modal.module';
import { MenuItemDetailsModalComponentModule } from './menu-item-details-modal/menu-item-details-modal.module';
import { ModalAdPageModule } from './modal-ad/modal-ad.module';
import { OrderDetailsModalPageModule } from './order-details-modal/order-details-modal.module';
import { OrderPaymentModalPageModule } from './order-payment-modal/order-payment-modal.module';
import { OrderReviewModalPageModule } from './order-review-modal/order-review-modal.module';
import { OrderTrackingModalPageModule } from './order-tracking-modal/order-tracking-modal.module';
import { OrderUpdateModalPageModule } from './order-update-modal/order-update-modal.module';
import { PreviousOrderModalPageModule } from './previous-order-modal/previous-order-modal.module';
import { PushNotificationsDisabledModalPageModule } from './push-notifications-disabled-modal/push-notifications-disabled-modal.module';
import { PushNotificationsModalPageModule } from './push-notifications-modal/push-notifications-modal.module';
import { RateBilbaytModalPageModule } from './rate-bilbayt-modal/rate-bilbayt-modal.module';
import { ReferralProgramModalPageModule } from './referral-program-modal/referral-program-modal.module';
import { SocialLoginModalPageModule } from './social-login-modal/social-login-modal.module';
import { SpecialRequestModalPageModule } from './special-request-modal/special-request-modal.module';
import { TagsModalPageModule } from './tags-modal/tags-modal.module';
import { TestFormModalPageModule } from './test-form-modal/test-form-modal.module';
import { TimeoutRetryModalPageModule } from './timeout-retry-modal/timeout-retry-modal.module';
import { TrackingTransparencyModalPageModule } from './tracking-transparency-modal/tracking-transparency-modal.module';
import { VendorCartModalPageModule } from './vendor-cart-modal/vendor-cart-modal.module';
import { VendorChatExplainerModalPageModule } from './vendor-chat-explainer-modal/vendor-chat-explainer-modal.module';
import { VendorReviewsModalPageModule } from './vendor-reviews-modal/vendor-reviews-modal.module';

export default [
  OrderTrackingModalPageModule,
  ReferralProgramModalPageModule,
  PushNotificationsDisabledModalPageModule,
  GoogleMapsAutocompleteModalPageModule,
  ImagesPreviewModalPageModule,
  ModalAdPageModule,
  AreaSelectionModalPageModule,
  TagsModalPageModule,
  EnableLocationServicesModalPageModule,
  IdentifiedLocationModalPageModule,
  TrackingTransparencyModalPageModule,
  AlertModalPageModule,
  ContactModalPageModule,
  VendorReviewsModalPageModule,
  RateBilbaytModalPageModule,
  CouponCodeModalPageModule,
  LogInModalPageModule,
  OrderReviewModalPageModule,
  OrderUpdateModalPageModule,
  OrderDetailsModalPageModule,
  SpecialRequestModalPageModule,
  MenuItemDetailsModalComponentModule,
  GroceryItemDetailsModalComponentModule,
  PushNotificationsModalPageModule,
  SocialLoginModalPageModule,
  CreditCardModalPageModule,
  PreviousOrderModalPageModule,
  BrowserDateTimePickerModalPageModule,
  BrowserCountryPickerModalPageModule,
  BrowserCartModalPageModule,
  EditWishlistModalPageModule,
  AddressModalPageModule,
  VendorCartModalPageModule,
  TimeoutRetryModalPageModule,
  OrderPaymentModalPageModule,
  VendorChatExplainerModalPageModule,
  ItemAvailabilityDateTimePickerModalPageModule,
  DimensionRequirementsModalPageModule,
  LoyaltyProgramModalPageModule,
  TestFormModalPageModule,
];
