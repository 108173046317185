<ion-header class="ion-no-border white-header">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="onDismiss()">
        <ion-icon slot="icon-only" src="/assets/custom-icons/b-times.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ "ADDRESS_PAGE.ADDRESS_DETAILS" | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="search-bar">
    <ion-icon src="/assets/custom-icons/address-page/search.svg"></ion-icon>
    <ion-input
      #input
      clear-input
      debounce="500"
      class="ion-no-padding"
      [(ngModel)]="queryText"
      (ionInput)="onQueryChange()"
      [placeholder]="'ADDRESS_PAGE.SEARCH' | translate"
    ></ion-input>
  </div>

  <div class="separator"></div>

  <div *ngIf="queryText" [@animatedResults]="searchResults?.length" class="suggestions">
    <div tappable class="suggestion" *ngFor="let searchResult of searchResults" (click)="onSelectResult(searchResult)">
      {{ searchResult.mainText }}, {{ searchResult.secondaryText }}
    </div>
  </div>
</ion-content>
