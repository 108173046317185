import { Injectable, OnDestroy } from '@angular/core';

import { Subject, takeUntil } from 'rxjs';

import { AppState } from '../models/app-state.model';
import { Country } from '../models/country.model';
import { DeepLinkClick } from '../models/deep-link-click.model';
import { Language } from '../models/language.model';
import { OrderInProgressWithSelectedArea } from '../models/order-in-progress.model';
import { ReferralProgramLinkClick } from '../models/referral-program-link-click.model';
import { SearchTrackingData } from '../models/search-tracking-data.model';
import { TestGroup } from '../models/test-group.model';
import { TokenDetails } from '../models/token-details.model';
import { User } from '../models/user.model';
import { VendorChatEvent } from '../models/vendor-chat-event.model';

import { AddressService } from '../services/address.service';
import { StateService } from '../services/state.service';
import { StorageService } from '../services/storage.service';
import { AddressDateTimeFilters } from '../services/vendors.service';

import { APP_NAME } from '../config/app.config';

import { Helpers } from '../helpers';

import { Effect } from '../effects';
import { filterEmptyObject } from '../operators/filter-empty-object';
import { select } from '../operators/select';
import { selectNotEmptyArray } from '../operators/select-not-empty-array';
import { selectNotNil } from '../operators/select-not-nil';
import { selectTruthy } from '../operators/select-truthy';

interface StorageInfo {
  key: string;
  serialize: boolean;
  lastSavedValue?: unknown;
}

type StorageKeys =
  | 'orderInProgress'
  | 'user'
  | 'token'
  | 'referralProgramLinkClicks'
  | 'deepLinkClicks'
  | 'language'
  | 'country'
  | 'testGroups'
  | 'modalAdIdsAlreadySeen'
  | 'searchEvent'
  | 'latestVendorChatEvent'
  | 'topSearchedVendor'
  | 'topSearchedServiceType'
  | 'sessionsCount'
  | 'searchFilters'
  | 'searches'
  | 'referralProgramModalShown'
  | 'pushNotificationsPromptShown'
  | 'appTrackingTransparencyPromptShown'
  | 'vendorChatExplainerShown'
  | 'rateBilaytPromptShown';

export const StorageMap: Record<StorageKeys, StorageInfo> = {
  orderInProgress: { key: `${APP_NAME}:cartDetails`, serialize: true },
  user: { key: `${APP_NAME}:user`, serialize: true },
  token: { key: `${APP_NAME}:authentication`, serialize: true },
  referralProgramLinkClicks: { key: `${APP_NAME}:affiliateUser`, serialize: true },
  deepLinkClicks: { key: `${APP_NAME}:campaignAttribution`, serialize: true },
  language: { key: `${APP_NAME}:language`, serialize: true },
  country: { key: `${APP_NAME}:country`, serialize: true },
  testGroups: { key: `${APP_NAME}:testGroups`, serialize: true },
  modalAdIdsAlreadySeen: { key: `${APP_NAME}:modalAds`, serialize: true },
  searchEvent: { key: `${APP_NAME}:globalSearchTrackingEvent`, serialize: false },
  latestVendorChatEvent: { key: `${APP_NAME}:latestVendorChatEvent`, serialize: false },
  topSearchedVendor: { key: `${APP_NAME}:globalSearchTopSearchedVendor`, serialize: false },
  topSearchedServiceType: { key: `${APP_NAME}:globalSearchTopSearchedServiceType`, serialize: false },
  sessionsCount: { key: `${APP_NAME}:sessionsCount`, serialize: false },
  searchFilters: { key: `${APP_NAME}:searchFilters`, serialize: false },
  searches: { key: `${APP_NAME}:searchHistory`, serialize: false },
  referralProgramModalShown: { key: `${APP_NAME}:affiliateLinkModal`, serialize: false },
  pushNotificationsPromptShown: { key: `${APP_NAME}:pushNotificationsPrompt`, serialize: false },
  appTrackingTransparencyPromptShown: { key: `${APP_NAME}:appTrackingTransparencyPrompt`, serialize: false },
  vendorChatExplainerShown: { key: `${APP_NAME}:vendorChatExplainerShown`, serialize: false },
  rateBilaytPromptShown: { key: `${APP_NAME}:loveBilbaytPrompt`, serialize: false },
};

@Injectable({ providedIn: 'root' })
export class StorageEffects implements Effect, OnDestroy {
  private stateChanges$ = this.stateService.state$.pipe(filterEmptyObject()); // Skip the initial state

  private unsubscribe$ = new Subject<void>();

  constructor(private stateService: StateService, private storageService: StorageService, private addressService: AddressService) {}

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  public initialize(): void {
    this.saveCountryLanguageChanges();
    this.saveUserAuthChanges();
    this.savePromptsShownChanges();
    this.saveOrderInProgressChanges();
    this.saveDeepLinkClicksChanges();
    this.saveSearchRelatedDataChanges();
    this.saveVendorChatRelatedDataChanges();
    this.saveModalAdsSeenChanges();
    this.saveTestGroupsChanges();
  }

  public async getPreviousSessionState(): Promise<Partial<AppState>> {
    const sessionsCount = (await this.getValueFromStorage('sessionsCount', 1)) as number;
    const orderInProgress = (await this.getValueFromStorage('orderInProgress')) as OrderInProgressWithSelectedArea;

    // Always restore the area and the address even if the date/time
    // is not valid or even if the cart is empty (CA-1233)
    const address = orderInProgress?.selectedAddress
      ? Helpers.clone(orderInProgress.selectedAddress)
      : orderInProgress?.selectedArea
      ? this.addressService.createNewAddressFromArea(orderInProgress.selectedArea)
      : null;

    const initialState: Partial<AppState> = {
      sessionsCount,
      address,
      orderInProgress,
      isFirstSession: sessionsCount === 1,
      user: await this.getValueFromStorage('user').then((user: User) => {
        if (user && user.phoneNumber && !user.phone) {
          user.phone = {
            countryCode: user.phoneNumber.split(' ')[0],
            localNumber: user.phoneNumber.split(' ')[1],
          };
        }

        return user;
      }),
      token: (await this.getValueFromStorage('token')) as TokenDetails,
      language: (await this.getValueFromStorage('language')) as Language,
      country: (await this.getValueFromStorage('country')) as Country,
      testGroups: (await this.getValueFromStorage('testGroups', [])) as Array<TestGroup>,
      referralProgramModalShown: (await this.getValueFromStorage('referralProgramModalShown', false)) as boolean,
      pushNotificationsPromptShown: (await this.getValueFromStorage('pushNotificationsPromptShown', false)) as boolean,
      appTrackingTransparencyPromptShown: (await this.getValueFromStorage('appTrackingTransparencyPromptShown', false)) as boolean,
      vendorChatExplainerShown: (await this.getValueFromStorage('vendorChatExplainerShown', false)) as boolean,
      rateBilaytPromptShown: (await this.getValueFromStorage('rateBilaytPromptShown', false)) as boolean,
      modalAdIdsAlreadySeen: (await this.getValueFromStorage('modalAdIdsAlreadySeen', [])) as Array<number>,
      searches: (await this.getValueFromStorage('searches', [])) as Array<string>,
      searchFilters: (await this.getValueFromStorage('searchFilters')) as AddressDateTimeFilters,
      searchEvent: await this.getValueFromStorage('searchEvent').then((searchEvent: SearchTrackingData) => {
        if (searchEvent) {
          searchEvent.vendorsOpenedCount = searchEvent.vendorsOpened?.length;
          searchEvent.itemsOpenedCount = searchEvent.itemsOpened?.length;
        }

        return searchEvent;
      }),
      latestVendorChatEvent: (await this.getValueFromStorage('latestVendorChatEvent')) as VendorChatEvent,
      topSearchedVendor: (await this.getValueFromStorage('topSearchedVendor')) as number,
      topSearchedServiceType: (await this.getValueFromStorage('topSearchedServiceType')) as number,
      pendingDeepLinkClicks: await this.getValueFromStorage('deepLinkClicks', []).then((clicks: Array<DeepLinkClick>) =>
        clicks.filter((click) => !!click),
      ),
      pendingReferralProgramLinkClicks: await this.getValueFromStorage('referralProgramLinkClicks', []).then(
        (clicks: Array<ReferralProgramLinkClick>) => clicks.filter((click) => !!click),
      ),
    };

    // Increment the session count for the next session
    this.setValueToStorage('sessionsCount', sessionsCount + 1);

    return initialState;
  }

  private saveCountryLanguageChanges(): void {
    this.stateChanges$
      .pipe(
        selectNotNil((state) => state.country),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((country) => this.setValueToStorage('country', country));

    this.stateChanges$
      .pipe(
        selectNotNil((state) => state.language),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((language) => this.setValueToStorage('language', language));
  }

  private saveUserAuthChanges(): void {
    this.stateService.state$
      .pipe(
        select((state) => state.user),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((user) => this.setValueToStorage('user', user));

    this.stateService.state$
      .pipe(
        select((state) => state.token),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((token) => this.setValueToStorage('token', token));
  }

  private savePromptsShownChanges(): void {
    this.stateService.state$
      .pipe(
        selectTruthy((state) => state.rateBilaytPromptShown),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((rateBilaytPromptShown) => this.setValueToStorage('rateBilaytPromptShown', rateBilaytPromptShown));

    this.stateService.state$
      .pipe(
        selectTruthy((state) => state.referralProgramModalShown),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((referralProgramModalShown) => this.setValueToStorage('referralProgramModalShown', referralProgramModalShown));

    this.stateService.state$
      .pipe(
        selectTruthy((state) => state.pushNotificationsPromptShown),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((pushNotificationsPromptShown) => this.setValueToStorage('pushNotificationsPromptShown', pushNotificationsPromptShown));

    this.stateService.state$
      .pipe(
        selectTruthy((state) => state.appTrackingTransparencyPromptShown),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((appTrackingTransparencyPromptShown) =>
        this.setValueToStorage('appTrackingTransparencyPromptShown', appTrackingTransparencyPromptShown),
      );

    this.stateService.state$
      .pipe(
        selectTruthy((state) => state.vendorChatExplainerShown),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((vendorChatExplainerShown) => this.setValueToStorage('vendorChatExplainerShown', vendorChatExplainerShown));
  }

  private saveOrderInProgressChanges(): void {
    this.stateService.state$
      .pipe(
        selectNotNil((state) => state.orderInProgress, { debounceTimeMs: 1000 }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((orderInProgress) => this.setValueToStorage('orderInProgress', orderInProgress));
  }

  private saveDeepLinkClicksChanges(): void {
    this.stateService.state$
      .pipe(
        select((state) => state.pendingReferralProgramLinkClicks),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((referralProgramLinkClicks) => this.setValueToStorage('referralProgramLinkClicks', referralProgramLinkClicks || []));

    this.stateService.state$
      .pipe(
        select((state) => state.pendingDeepLinkClicks),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((deepLinkClicks) => this.setValueToStorage('deepLinkClicks', deepLinkClicks || []));
  }

  private saveSearchRelatedDataChanges(): void {
    this.stateService.state$
      .pipe(
        select((state) => state.searchEvent),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((searchEvent) => this.setValueToStorage('searchEvent', searchEvent));

    this.stateService.state$
      .pipe(
        select((state) => state.topSearchedVendor),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((topSearchedVendor) => this.setValueToStorage('topSearchedVendor', topSearchedVendor));

    this.stateService.state$
      .pipe(
        select((state) => state.topSearchedServiceType),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((topSearchedServiceType) => this.setValueToStorage('topSearchedServiceType', topSearchedServiceType));

    this.stateService.state$
      .pipe(
        select((state) => state.searchFilters),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((searchFilters) => this.setValueToStorage('searchFilters', searchFilters));

    this.stateService.state$
      .pipe(
        select((state) => state.searches),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((searches) => this.setValueToStorage('searches', searches || []));
  }

  private saveVendorChatRelatedDataChanges(): void {
    this.stateService.state$
      .pipe(
        select((state) => state.latestVendorChatEvent),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((latestVendorChatEvent) => this.setValueToStorage('latestVendorChatEvent', latestVendorChatEvent || undefined));
  }

  private saveModalAdsSeenChanges(): void {
    this.stateService.state$
      .pipe(
        selectNotEmptyArray((state) => state.modalAdIdsAlreadySeen),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((modalAdIdsAlreadySeen) => this.setValueToStorage('modalAdIdsAlreadySeen', modalAdIdsAlreadySeen || []));
  }

  private saveTestGroupsChanges(): void {
    this.stateService.state$
      .pipe(
        select((state) => state.testGroups),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((testGroups) => this.setValueToStorage('testGroups', testGroups || []));
  }

  private async getValueFromStorage<K extends StorageKeys>(propName: K, defaultValue: unknown = undefined): Promise<unknown> {
    const storageInfo = StorageMap[propName];
    const storageValue = await this.storageService.get(storageInfo.key);

    const value = storageInfo.serialize
      ? storageValue !== null && storageValue !== undefined
        ? (JSON.parse(storageValue as string) as unknown) || defaultValue
        : defaultValue
      : storageValue || defaultValue;

    // Only for testing
    // console.log(`[StorageEffect] getValueFromStorage --> ${propName}: ${JSON.stringify(value, null, 2)}`);

    storageInfo.lastSavedValue = value;
    return value;
  }

  private setValueToStorage<K extends StorageKeys>(propName: K, value: unknown): void {
    const storageInfo = StorageMap[propName];

    if (Helpers.areEqual(storageInfo.lastSavedValue, value)) {
      return;
    }

    // Only for testing
    // console.log(`[StorageEffect] setValueToStorage --> ${propName}: ${JSON.stringify(value, null, 2)}`);

    const storageValue = storageInfo.serialize ? JSON.stringify(value) : value;

    storageInfo.lastSavedValue = storageValue;
    void this.storageService.set(storageInfo.key, storageValue);
  }
}
