import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { BranchPlugin } from './native-plugins/branch.plugin';
import { SocialSharingPlugin } from './native-plugins/social-sharing.plugin';

import { filter, map, Observable, Subject } from 'rxjs';

import { TargetPage } from '../enums/target-page.enum';

import { UniversalLink } from '../models/universal-link.model';

import { AccountService } from './account.service';
import { EnvironmentService } from './environment.service';
import { ModalService } from './modal.service';
import { NavigationService } from './navigation.service';
import { OverlayService } from './overlay.service';
import { SessionService } from './session.service';
import { SettingsService } from './settings.service';
import { PlatformService } from './ssr/platform.service';
import { StateService } from './state.service';

import { AppConfig, TOKEN_CONFIG } from '../config/app.config';
import { TOKEN_UNIVERSAL_LINKS_CONFIG, UniversalLinksConfig } from '../config/universal-links.config';

interface VendorDetailsShareLinkParams {
  vendorId: number;
  vendorName: string;
  vendorDescription: string;
  vendorImageUrl: string;
  vendorServiceTypes: number;
}

type VendorChatShareLinkParams = Omit<VendorDetailsShareLinkParams, 'vendorServiceTypes'>;

interface ItemDetailsShareLinkParams {
  vendorId: number;
  vendorName: string;
  itemId: number;
  itemName: string;
  itemDescription: string;
  itemImageUrl: string;
  itemServiceTypes: number;
}

interface OrderDetailsShareLinkParams {
  isForNow: boolean;
  dateStr: string;
  timeStr: string;
}

interface WishlistDetailsShareLinkParams {
  wishlistId: number;
  wishlistName: string;
}

interface ShareLinkParams {
  title?: string;
  description?: string;
  imageUrl?: string;
  params?: Record<string, unknown>;
  websiteUrl?: string;
}

@Injectable({ providedIn: 'root' })
export class UniversalLinksService {
  public processPendingUniversalLink$ = new Subject<UniversalLink>();
  public handleUniversalLinkOnAccountPage$ = new Subject<UniversalLink>();
  public handleUniversalLinkOnSearchResultsPage$ = new Subject<UniversalLink>();

  constructor(
    private http: HttpClient,
    private branchPlugin: BranchPlugin,
    private socialSharingPlugin: SocialSharingPlugin,
    private platformService: PlatformService,
    private stateService: StateService,
    private modalService: ModalService,
    private overlayService: OverlayService,
    private sessionService: SessionService,
    private accountService: AccountService,
    private settingsService: SettingsService,
    private translateService: TranslateService,
    private navigationService: NavigationService,
    private environmentService: EnvironmentService,
    @Inject(TOKEN_UNIVERSAL_LINKS_CONFIG) private universalLinksConfig: UniversalLinksConfig,
    @Inject(TOKEN_CONFIG) private config: AppConfig,
  ) {}

  public initializeUniversalLinks(): void {
    this.platformService.resume.subscribe(() => {
      void this.branchPlugin.initSession().then(() => {
        this.checkUniversalLinks({ appWasOpenedInBackground: true });
      });
    });

    this.checkUniversalLinks();
  }

  public checkUniversalLinks({ appWasOpenedInBackground } = { appWasOpenedInBackground: false }): void {
    void this.branchPlugin
      .getLatestDeepLinkData()
      .then((branchParams) => {
        if (!branchParams) {
          return;
        }

        const universalLink: UniversalLink = {
          isNewInstall: !this.platformService.isBrowser && !!this.sessionService.isFirstSession() && !appWasOpenedInBackground,
          targetPage: branchParams.targetPage ? +branchParams.targetPage : branchParams.targetPage,
          orderId: branchParams.orderId ? +branchParams.orderId : branchParams.orderId,
          catererId: branchParams.catererId ? +branchParams.catererId : branchParams.catererId,
          menuItemId: branchParams.menuItemId ? +branchParams.menuItemId : branchParams.menuItemId,
          marketingCollectionId: branchParams.marketingCollectionId
            ? +branchParams.marketingCollectionId
            : branchParams.marketingCollectionId,
          wishlistId: branchParams.wishlistId ? +branchParams.wishlistId : branchParams.wishlistId,
          serviceTypes: branchParams.serviceTypes ? +branchParams.serviceTypes : branchParams.serviceTypes,
          campaignId: branchParams.campaignId ? +branchParams.campaignId : branchParams.campaignId,
          campaignSource: branchParams.campaignSource,
          affiliateUserId: branchParams.affiliateUserId,
          couponCode: branchParams.couponCode,
        };

        // Testing only
        // if (universalLink) alert(`${JSON.stringify(universalLink, null, 4)}`);

        const appOpenedFromPushNotification = !this.shouldBeAttributed(universalLink) && !!this.stateService.state?.pendingPushNotification;
        if (appOpenedFromPushNotification) {
          return;
        }

        this.handleUniversalLink(universalLink);
      })
      .catch(() => {});
  }

  public handleUniversalLink(universalLink: UniversalLink): void {
    this.stateService.update({ pendingUniversalLink: universalLink });

    if (universalLink.couponCode) {
      this.stateService.update({
        deeplinkPromotions: [...(this.stateService.state.deeplinkPromotions || []), universalLink.couponCode],
      });

      this.navigationService.firstNavigationEnded$.pipe(filter((ended) => !!ended)).subscribe(() => {
        this.modalService.showAlert({
          backdropDismiss: false,
          title: this.translateService.instant('CHECKOUT_PAGE.PROMO_CODE') as string,
          message: this.translateService.instant('CART_PAGE.DEEPLINK_PROMO_CODE_MESSAGE', { code: universalLink.couponCode }) as string,
        });
      });
    }
  }

  public getDataFromUniversalLinkUrl(linkUrl: string): Observable<unknown> {
    const linkId = linkUrl.split('/').pop();
    const url = this.config.routes.getUniversalLinkDetails.url.replace('#linkId#', linkId);

    return this.http.get(url).pipe(map((branchLinkContent: { data: unknown }) => (branchLinkContent ? branchLinkContent.data : null)));
  }

  public getUniversalLinkFromLinkData(linkData: UniversalLink, source?: 'modalAd' | 'bannerAd'): UniversalLink {
    const newUniversalLink = this.getUniversalLinkFromObject(linkData);

    newUniversalLink.fromModalAds = source && source === 'modalAd';
    newUniversalLink.fromBannerAds = source && source === 'bannerAd';

    return newUniversalLink;
  }

  public shareWishlist(wishlistDetailsParams: WishlistDetailsShareLinkParams): Promise<void> {
    return this.createWishlistUniversalLink(wishlistDetailsParams).then((link) => {
      const message = this.translateService.instant('WISHLIST_SHARE.MESSAGE', { url: link }) as string;
      return this.socialSharingPlugin.share(message);
    });
  }

  public shareVendorDetails(vendorDetailsParams: VendorDetailsShareLinkParams): Promise<void> {
    return this.createVendorDetailsUniversalLink(vendorDetailsParams).then((link) => {
      const message = this.translateService.instant('VENDOR_SHARE.MESSAGE', { url: link }) as string;
      return this.socialSharingPlugin.share(message);
    });
  }

  public shareMenuItemDetails(itemDetailsParams: ItemDetailsShareLinkParams): Promise<void> {
    return this.createMenuItemUniversalLink(itemDetailsParams).then((link) => {
      const message = this.translateService.instant('ITEM_SHARE.MESSAGE', { url: link }) as string;
      return this.socialSharingPlugin.share(message);
    });
  }

  public shareOrderDetails(orderDetailsParams: OrderDetailsShareLinkParams): Promise<void> {
    const { isForNow, dateStr, timeStr } = orderDetailsParams;

    return this.createOrderDetailsUniversalLink(orderDetailsParams).then((link) => {
      const message = isForNow
        ? (this.translateService.instant('ORDER_FOR_NOW_SHARE.MESSAGE', { date: dateStr, url: link }) as string)
        : (this.translateService.instant('ORDER_FOR_LATER_SHARE.MESSAGE', { date: dateStr, time: timeStr, url: link }) as string);

      return this.socialSharingPlugin.share(message);
    });
  }

  public shareReferralProgramLink(universalLink?: string): Promise<void> {
    const maybeCreateLink = !universalLink ? this.createReferralProgramUniversalLink() : Promise.resolve(universalLink);

    return maybeCreateLink.then((link) => {
      const message = this.translateService.instant('AFFILIATE_LINK_SHARE.MESSAGE', {
        value: this.settingsService.getReferralProgramCredits(),
        currency: this.settingsService.getCurrencySymbol(),
        url: link,
      }) as string;

      return this.socialSharingPlugin.share(message);
    });
  }

  public createReferralProgramUniversalLink(): Promise<string> {
    const websiteUrl = this.environmentService.websiteUrl;

    if (!this.accountService.isLoggedIn()) {
      return Promise.resolve(websiteUrl);
    }

    return this.createBranchLinkUrl({
      title: this.translateService.instant('AFFILIATE_LINK_SHARE.TITLE') as string,
      description: this.translateService.instant('AFFILIATE_LINK_SHARE.DESCRIPTION') as string,
      websiteUrl,
    });
  }

  public getUniversalLinkFromObject(linkData: Partial<UniversalLink>): UniversalLink {
    return {
      targetPage: linkData.targetPage ? +linkData.targetPage : linkData.targetPage,
      isNewInstall: !this.platformService.isBrowser && linkData.isNewInstall,
      orderId: linkData.orderId ? +linkData.orderId : linkData.orderId,
      catererId: linkData.catererId ? +linkData.catererId : linkData.catererId,
      menuItemId: linkData.menuItemId ? +linkData.menuItemId : linkData.menuItemId,
      marketingCollectionId: linkData.marketingCollectionId ? +linkData.marketingCollectionId : linkData.marketingCollectionId,
      wishlistId: linkData.wishlistId ? +linkData.wishlistId : linkData.wishlistId,
      serviceTypes: linkData.serviceTypes ? +linkData.serviceTypes : linkData.serviceTypes,
      campaignId: linkData.campaignId ? +linkData.campaignId : linkData.campaignId,
      campaignSource: linkData.campaignSource,
      affiliateUserId: linkData.affiliateUserId,
      couponCode: linkData.couponCode,
    } as UniversalLink;
  }

  public getValidTargetPage(universalLink: UniversalLink): TargetPage {
    switch (universalLink.targetPage) {
      case TargetPage.MarketingCollectionPage:
        return universalLink.marketingCollectionId ? TargetPage.MarketingCollectionPage : null;

      case TargetPage.WishlistDetails:
        return universalLink.wishlistId ? TargetPage.WishlistDetails : null;

      case TargetPage.OrderReview:
        return universalLink.orderId ? TargetPage.OrderReview : null;

      case TargetPage.OrderDetails:
        return universalLink.orderId ? TargetPage.OrderDetails : null;

      case TargetPage.CatererDetails:
        return universalLink.catererId ? TargetPage.CatererDetails : null;

      case TargetPage.MenuItemDetails:
        return universalLink.catererId && universalLink.menuItemId ? TargetPage.MenuItemDetails : null;

      case TargetPage.ServiceType:
        return universalLink.serviceTypes ? TargetPage.ServiceType : null;

      case TargetPage.Cart:
      case TargetPage.SignUp:
      case TargetPage.AppUpdate:
      case TargetPage.UserRewards:
      case TargetPage.ReferralProgram:
      case TargetPage.TopSearchedServiceType:
      case TargetPage.TopSearchedVendor:
      case TargetPage.PushNotificationsPermission:
        return universalLink.targetPage;

      default:
        return null;
    }
  }

  public getHomePageUrl(universalLink: UniversalLink): string {
    if (this.shouldBeHandledInSearchResultsPage(universalLink)) {
      return this.platformService.isCordova ? '/vendors/home' : '/vendors/search';
    }

    if (this.shouldBeHandledInAccountPage(universalLink)) {
      return '/account';
    }

    return null;
  }

  public handleUniversalLinkOnTabPage(universalLink: UniversalLink): void {
    if (this.shouldBeHandledInSearchResultsPage(universalLink)) {
      this.handleUniversalLinkOnSearchResultsPage$.next(universalLink);
      return;
    }

    if (this.shouldBeHandledInAccountPage(universalLink)) {
      this.handleUniversalLinkOnAccountPage$.next(universalLink);
      return;
    }
  }

  public getPendingUniversalLinkToBeHandledInSearchResultsPage(): UniversalLink {
    const pendingUniversalLink = this.stateService.state?.pendingUniversalLink;
    return pendingUniversalLink && this.shouldBeHandledInSearchResultsPage(pendingUniversalLink) ? pendingUniversalLink : null;
  }

  public getPendingUniversalLinkToBeHandledInAccountPage(): UniversalLink {
    const pendingUniversalLink = this.stateService.state?.pendingUniversalLink;
    return pendingUniversalLink && this.shouldBeHandledInAccountPage(pendingUniversalLink) ? pendingUniversalLink : null;
  }

  public clearPendingUniversalLink(): void {
    this.stateService.update({ pendingUniversalLink: null });
  }

  public handleDeepLinkUrl(link: string): void {
    void this.overlayService.showLoading().then(() => {
      this.getDataFromUniversalLinkUrl(link).subscribe({
        next: (universalLinkData: UniversalLink) => {
          void this.overlayService.hideLoading();
          const universalLink = this.getUniversalLinkFromLinkData(universalLinkData);

          if (universalLink) {
            this.handleUniversalLink(universalLink);
          }
        },
        error: () => {
          void this.overlayService.hideLoading();
        },
      });
    });
  }

  public shouldBeAttributed(universalLink: UniversalLink): boolean {
    return this.isCampaignLink(universalLink) || this.isReferralProgramLink(universalLink);
  }

  public isModalAdLink(universalLink: UniversalLink): boolean {
    return !this.isReferralProgramLink(universalLink) && universalLink.fromModalAds;
  }

  public isBannerAdLink(universalLink: UniversalLink): boolean {
    return !this.isReferralProgramLink(universalLink) && universalLink.fromBannerAds;
  }

  public isCampaignLink(universalLink: UniversalLink): boolean {
    return !!universalLink.campaignId;
  }

  public isReferralProgramLink(universalLink: UniversalLink): boolean {
    return !!universalLink.affiliateUserId;
  }

  public shouldRedirect(universalLink: UniversalLink): boolean {
    return this.platformService.isBrowser
      ? !!universalLink.fromBannerAds || !!universalLink.fromModalAds
      : !!universalLink.targetPage || !!universalLink.catererId || !!universalLink.orderId;
  }

  public createVendorChatUniversalLink(vendorDetailsParams: VendorChatShareLinkParams): Promise<{ chatId: string; link: string }> {
    const { vendorId, vendorName, vendorDescription, vendorImageUrl } = vendorDetailsParams;
    const chatId = `vendor-${vendorId}-${Date.now()}`;

    const currentUrlSegment = this.navigationService.getUrl({
      queryStringParamsToAdd: {
        chatId,
        chatRequestedByUserId: this.accountService.isLoggedIn() ? this.accountService.userDetails.userId : undefined,
        chatRequestedByUserEmail: this.accountService.isLoggedIn() ? this.accountService.userDetails.email : undefined,
      },
    });

    return this.createBranchLinkUrl({
      title: this.translateService.instant('VENDOR_SHARE.TITLE', { vendorName }) as string,
      description: vendorDescription
        ? (this.translateService.instant('VENDOR_SHARE.DESCRIPTION', { vendorDescription }) as string)
        : (this.translateService.instant('BILBAYT_DESCRIPTION') as string),
      imageUrl: vendorImageUrl,
      websiteUrl: `${this.environmentService.websiteUrl}/${currentUrlSegment}`,
      params: { $web_only: true },
    }).then((link) => ({ link, chatId }));
  }

  private shouldBeHandledInSearchResultsPage(universalLink: UniversalLink): boolean {
    switch (this.getValidTargetPage(universalLink)) {
      case TargetPage.Cart:
      case TargetPage.ServiceType:
      case TargetPage.CatererDetails:
      case TargetPage.MenuItemDetails:
      case TargetPage.MarketingCollectionPage:
      case TargetPage.TopSearchedServiceType:
      case TargetPage.TopSearchedVendor:
      case TargetPage.WishlistDetails:
      case TargetPage.PushNotificationsPermission:
        return true;

      default:
        return false;
    }
  }

  private shouldBeHandledInAccountPage(universalLink: UniversalLink): boolean {
    switch (this.getValidTargetPage(universalLink)) {
      case TargetPage.AppUpdate:
      case TargetPage.SignUp:
      case TargetPage.UserRewards:
      case TargetPage.ReferralProgram:
      case TargetPage.OrderReview:
      case TargetPage.OrderDetails:
        return true;

      default:
        return false;
    }
  }

  private createWishlistUniversalLink(wishlistDetailsParams: WishlistDetailsShareLinkParams): Promise<string> {
    const { wishlistId, wishlistName } = wishlistDetailsParams;
    const country = this.settingsService.getCountry().code;
    const language = this.settingsService.getLanguage().value;

    return this.createBranchLinkUrl({
      title: this.translateService.instant('WISHLIST_SHARE.TITLE', { name: wishlistName }) as string,
      description: this.translateService.instant('WISHLIST_SHARE.DESCRIPTION') as string,
      websiteUrl: `${this.environmentService.websiteUrl}/${country}/${language}/wishlists/${wishlistId}`,
      params: {
        [this.universalLinksConfig.paramName.targetPage]: TargetPage.WishlistDetails,
        [this.universalLinksConfig.paramName.wishlistId]: wishlistId,
      },
    });
  }

  private createVendorDetailsUniversalLink(vendorDetailsParams: VendorDetailsShareLinkParams): Promise<string> {
    const { vendorId, vendorName, vendorDescription, vendorImageUrl, vendorServiceTypes } = vendorDetailsParams;
    const country = this.settingsService.getCountry().code;
    const language = this.settingsService.getLanguage().value;

    return this.createBranchLinkUrl({
      title: this.translateService.instant('VENDOR_SHARE.TITLE', { vendorName }) as string,
      description: vendorDescription
        ? (this.translateService.instant('VENDOR_SHARE.DESCRIPTION', { vendorDescription }) as string)
        : (this.translateService.instant('BILBAYT_DESCRIPTION') as string),
      imageUrl: vendorImageUrl,
      websiteUrl: `${this.environmentService.websiteUrl}/${country}/${language}/caterers/id/${vendorId}`,
      params: {
        [this.universalLinksConfig.paramName.targetPage]: TargetPage.CatererDetails,
        [this.universalLinksConfig.paramName.catererId]: vendorId,
        [this.universalLinksConfig.paramName.serviceTypes]: vendorServiceTypes,
      },
    });
  }

  private createMenuItemUniversalLink(itemDetailsParams: ItemDetailsShareLinkParams): Promise<string> {
    const { vendorId, vendorName, itemId, itemName, itemDescription, itemImageUrl, itemServiceTypes } = itemDetailsParams;
    const country = this.settingsService.getCountry().code;
    const language = this.settingsService.getLanguage().value;

    return this.createBranchLinkUrl({
      title: this.translateService.instant('ITEM_SHARE.TITLE', { itemName, vendorName }) as string,
      description: itemDescription
        ? (this.translateService.instant('ITEM_SHARE.DESCRIPTION', { itemDescription }) as string)
        : (this.translateService.instant('BILBAYT_DESCRIPTION') as string),
      imageUrl: itemImageUrl,
      websiteUrl: `${this.environmentService.websiteUrl}/${country}/${language}/caterers/id/${vendorId}/menuId/${itemId}`,
      params: {
        [this.universalLinksConfig.paramName.targetPage]: TargetPage.MenuItemDetails,
        [this.universalLinksConfig.paramName.catererId]: vendorId,
        [this.universalLinksConfig.paramName.menuItemId]: itemId,
        [this.universalLinksConfig.paramName.serviceTypes]: itemServiceTypes,
      },
    });
  }

  private createOrderDetailsUniversalLink(orderDetailsParams: OrderDetailsShareLinkParams): Promise<string> {
    const { isForNow } = orderDetailsParams;

    return this.createBranchLinkUrl({
      title: this.translateService.instant(isForNow ? 'ORDER_FOR_NOW_SHARE.TITLE' : 'ORDER_FOR_LATER_SHARE.TITLE') as string,
      description: this.translateService.instant(
        isForNow ? 'ORDER_FOR_NOW_SHARE.DESCRIPTION' : 'ORDER_FOR_LATER_SHARE.DESCRIPTION',
      ) as string,
      websiteUrl: this.environmentService.websiteUrl,
    });
  }

  private createBranchLinkUrl(linkParams: ShareLinkParams = null): Promise<string> {
    const params = linkParams?.params || {};
    let websiteUrl = linkParams?.websiteUrl || this.environmentService.websiteUrl;

    const isLoggedIn = this.accountService.isLoggedIn();
    const isReferralProgramEnabled = this.settingsService.isReferralProgramFeatureEnabled();

    if (isLoggedIn && isReferralProgramEnabled) {
      const userId = this.accountService.userDetails.userId;

      websiteUrl = this.getReferralProgramWebsiteUrl(userId, websiteUrl);
      params[this.universalLinksConfig.paramName.affiliateUserId] = userId;
    }

    return this.branchPlugin.createLinkUrl({
      title: linkParams?.title || (this.translateService.instant('BILBAYT') as string),
      description: linkParams?.description || (this.translateService.instant('BILBAYT_DESCRIPTION') as string),
      imageUrl: linkParams?.imageUrl || undefined,
      websiteUrl,
      params,
    });
  }

  private getReferralProgramWebsiteUrl(userId: string, redirectUrl: string): string {
    return `${this.environmentService.websiteUrl}/affiliate/${userId}?redirectUrl=${encodeURIComponent(redirectUrl)}`;
  }
}
