import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

import { IonInput, ViewDidEnter, ViewWillLeave } from '@ionic/angular';

import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LogIn } from '../../core/models/login.model';
import { Register } from '../../core/models/register.model';

import { AccountService } from '../../core/services/account.service';
import { AnalyticsService } from '../../core/services/analytics.service';
import { DateTimeService } from '../../core/services/date-time.service';
import { LoggerService } from '../../core/services/logger.service';
import { ModalService } from '../../core/services/modal.service';
import { OverlayService } from '../../core/services/overlay.service';
import { SettingsService } from '../../core/services/settings.service';
import { PlatformService } from '../../core/services/ssr/platform.service';
import { ValidationService } from '../../core/services/validation.service';

import { AnalyticsConfig, TOKEN_ANALYTICS_CONFIG } from '../../core/config/analytics.config';
import { AppConfig, BACK_BUTTON_HANDLER_PRIORITY, TOKEN_CONFIG } from '../../core/config/app.config';

import { Helpers } from '../../core/helpers';

import { LogInModalPageAnimations } from './log-in-modal.animations';

export const LogInModalPageIdentifier = 'log-in-modal';

declare type FormStep = 'initial' | 'existingUser' | 'newUser' | 'forgotPassword';

@Component({
  selector: 'app-log-in-modal',
  templateUrl: './log-in-modal.page.html',
  styleUrls: ['./log-in-modal.page.scss'],
  animations: [...LogInModalPageAnimations],
  encapsulation: ViewEncapsulation.None,
})
export class LogInModalPage implements OnInit, ViewDidEnter, ViewWillLeave, OnDestroy {
  @ViewChild('emailInputElement')
  public emailInputElement: IonInput;

  @ViewChild('passwordInputElement')
  public passwordInputElement: IonInput;

  @ViewChild('firstNameInputElement')
  public firstNameInputElement: IonInput;

  public userDetailsForm: FormGroup<{
    email: FormControl<string>;
    password: FormControl<string>;
    firstName: FormControl<string>;
    lastName: FormControl<string>;
    phoneCountryCode: FormControl<string>;
    phoneLocalNumber: FormControl<string>;
  }>;
  public submitAttempt: boolean;

  public currentStep: FormStep = 'initial';
  public isLoading: boolean;

  private unregisterBackButtonSubscription: Subscription;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private formBuilder: NonNullableFormBuilder,
    private modalService: ModalService,
    private loggerService: LoggerService,
    private accountService: AccountService,
    private overlayService: OverlayService,
    private dateTimeService: DateTimeService,
    private settingsService: SettingsService,
    private platformService: PlatformService,
    private analyticsService: AnalyticsService,
    private translateService: TranslateService,
    private validationService: ValidationService,
    @Inject(TOKEN_CONFIG) private config: AppConfig,
    @Inject(TOKEN_ANALYTICS_CONFIG) private analyticsConfig: AnalyticsConfig,
  ) {}

  ngOnInit() {
    this.initializeForm();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  ionViewDidEnter() {
    this.focusEmailField();
    this.unregisterBackButtonSubscription = this.platformService.backButton.subscribeWithPriority(BACK_BUTTON_HANDLER_PRIORITY, () =>
      this.onGoBack(),
    );
  }

  ionViewWillLeave() {
    this.unregisterBackButtonSubscription?.unsubscribe();
  }

  public onDismiss(): void {
    void this.dismiss();
  }

  public onGoBack(): void {
    if (this.currentStep === 'forgotPassword') {
      this.currentStep = 'existingUser';
      this.focusPasswordField();
      this.updateRequiredFieldsBasedOnStep();
      return;
    }

    if (this.currentStep === 'existingUser' || this.currentStep === 'newUser') {
      this.currentStep = 'initial';
      this.focusEmailField();
      this.updateRequiredFieldsBasedOnStep();
      return;
    }

    void this.dismiss();
  }

  public onSubmit(formElement: HTMLElement): void {
    if (this.isLoading) {
      return;
    }

    this.submitAttempt = true;

    if (this.userDetailsForm.valid) {
      switch (this.currentStep) {
        case 'initial':
          this.validateEmail();
          break;
        case 'newUser':
          this.handleSignUp();
          break;
        case 'existingUser':
          this.handleSignIn();
          break;
        case 'forgotPassword':
          this.handleForgotPassword();
          break;
      }
    } else {
      this.validationService.focusFirstInvalidField(formElement);
    }
  }

  public onShowForgotPasswordForm(): void {
    this.currentStep = 'forgotPassword';
    this.updateRequiredFieldsBasedOnStep();
  }

  private initializeForm(): void {
    this.submitAttempt = false;

    this.userDetailsForm = this.formBuilder.group({
      email: [''],
      password: [''],
      firstName: [''],
      lastName: [''],
      phoneCountryCode: [this.settingsService.getPhoneNumberCountryCode()],
      phoneLocalNumber: [''],
    });

    this.updateRequiredFieldsBasedOnStep();
  }

  private updateRequiredFieldsBasedOnStep(): void {
    this.submitAttempt = false;

    switch (this.currentStep) {
      case 'initial':
        this.userDetailsForm.controls.email.enable();
        this.userDetailsForm.controls.email.setValidators([Validators.required, this.validationService.emailValidator]);
        this.userDetailsForm.controls.password.clearValidators();
        this.userDetailsForm.controls.firstName.clearValidators();
        this.userDetailsForm.controls.lastName.clearValidators();
        this.userDetailsForm.controls.phoneCountryCode.clearValidators();
        this.userDetailsForm.controls.phoneLocalNumber.clearValidators();
        break;
      case 'newUser':
        this.userDetailsForm.controls.email.disable();
        this.userDetailsForm.controls.password.setValidators([Validators.required, Validators.minLength(this.config.passwordMinLength)]);
        this.userDetailsForm.controls.firstName.setValidators([Validators.required, Validators.maxLength(this.config.nameMaxLength)]);
        this.userDetailsForm.controls.lastName.setValidators([Validators.required, Validators.maxLength(this.config.nameMaxLength)]);
        this.userDetailsForm.controls.phoneCountryCode.setValidators([
          Validators.required,
          Validators.pattern(this.settingsService.getCountry().phoneCountryCodeValidationPattern),
        ]);
        this.userDetailsForm.controls.phoneLocalNumber.setValidators([
          Validators.required,
          Validators.pattern(this.settingsService.getCountry().phoneLocalNumberValidationPattern),
        ]);

        break;
      case 'existingUser':
        this.userDetailsForm.controls.email.disable();
        this.userDetailsForm.controls.password.setValidators([Validators.required, Validators.minLength(this.config.passwordMinLength)]);
        break;
      case 'forgotPassword':
        this.userDetailsForm.controls.email.disable();
        this.userDetailsForm.controls.password.clearValidators();
        this.userDetailsForm.controls.firstName.clearValidators();
        this.userDetailsForm.controls.lastName.clearValidators();
        this.userDetailsForm.controls.phoneCountryCode.clearValidators();
        this.userDetailsForm.controls.phoneLocalNumber.clearValidators();
        break;
    }

    this.userDetailsForm.controls.email.updateValueAndValidity();
    this.userDetailsForm.controls.password.updateValueAndValidity();
    this.userDetailsForm.controls.firstName.updateValueAndValidity();
    this.userDetailsForm.controls.lastName.updateValueAndValidity();
    this.userDetailsForm.controls.phoneCountryCode.updateValueAndValidity();
    this.userDetailsForm.controls.phoneLocalNumber.updateValueAndValidity();

    this.userDetailsForm.controls.email.markAsUntouched();
    this.userDetailsForm.controls.password.markAsUntouched();
    this.userDetailsForm.controls.firstName.markAsUntouched();
    this.userDetailsForm.controls.lastName.markAsUntouched();
    this.userDetailsForm.controls.phoneCountryCode.markAsUntouched();
    this.userDetailsForm.controls.phoneLocalNumber.markAsUntouched();

    this.userDetailsForm.updateValueAndValidity();
  }

  private focusEmailField(): void {
    void this.platformService.wait(50).then(() => this.emailInputElement?.setFocus());
  }

  private focusPasswordField(): void {
    void this.platformService.wait(50).then(() => this.passwordInputElement?.setFocus());
  }

  private focusFirstNameField(): void {
    void this.platformService.wait(50).then(() => this.firstNameInputElement?.setFocus());
  }

  private validateEmail(): void {
    this.isLoading = true;
    Helpers.runObservableWithMinDuration(this.accountService.validateEmail(this.userDetailsForm.getRawValue().email), 500)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ([isNewUser]) => {
          this.isLoading = false;

          if (isNewUser) {
            this.currentStep = 'newUser';
            this.focusFirstNameField();
          } else {
            this.currentStep = 'existingUser';
            this.focusPasswordField();
          }

          this.updateRequiredFieldsBasedOnStep();
        },
        error: (error: HttpErrorResponse) => {
          const errorMessage = this.validationService.getErrorMessage(error);
          this.loggerService.info({
            component: 'SignInSignUpModalPage',
            message: "couldn't check email availability",
            details: { error, messageShownToUser: errorMessage },
          });
          this.overlayService.showToast({ message: errorMessage, showCloseButton: true, type: 'error' });

          this.isLoading = false;
          void this.dismiss();
        },
      });
  }

  private handleSignUp(): void {
    const model = this.getRegisterModelFromForm();

    this.isLoading = true;
    Helpers.runObservableWithMinDuration(this.accountService.register(model), 500)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          // Identify the NEW user in the analytics tools so
          // we can attribute events to his/her account
          void this.analyticsService
            .identifyNewUser()
            .catch((error: unknown) => {
              this.loggerService.info({
                component: 'SignInSignUpModalPage',
                message: "couldn't create alias for user",
                details: { error },
              });
            })
            .then(() => {
              this.trackSignupEvent();
              this.isLoading = false;
              void this.dismiss();
            });
        },
        error: (error: HttpErrorResponse) => {
          const errorMessage = this.validationService.getErrorMessage(error);
          this.loggerService.info({
            component: 'SignInSignUpModalPage',
            message: "couldn't sign up user",
            details: { error, messageShownToUser: errorMessage },
          });
          this.overlayService.showToast({ message: errorMessage, showCloseButton: true, type: 'error' });

          this.isLoading = false;
        },
      });
  }

  private handleSignIn(): void {
    const model = this.getLogInModelFromForm();

    this.isLoading = true;
    Helpers.runObservableWithMinDuration(this.accountService.logIn(model), 500)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          // Identify the user in the analytics tools so
          // we can attribute events to his/her account
          void this.analyticsService
            .identifyExistingUser()
            .catch((error: unknown) =>
              this.loggerService.info({ component: 'SignInSignUpModalPage', message: "couldn't identify user", details: { error } }),
            )
            .then(() => {
              this.trackLoginEvent();
              this.isLoading = false;
              void this.dismiss();
            });
        },
        error: (error: HttpErrorResponse) => {
          const errorMessage = this.validationService.getErrorMessage(error);
          this.loggerService.info({
            component: 'SignInSignUpModalPage',
            message: "couldn't sign in user",
            details: { error, messageShownToUser: errorMessage },
          });

          // At this point we know the email is valid because it was checked in the
          // previous step so if the error is invalid grant then it's because the
          // password is wrong
          const isInvalidPassword = (error?.error as { error: string })?.error === 'invalid_grant' || false;

          if (isInvalidPassword) {
            this.userDetailsForm.get('password').setErrors({ invalidPassword: true });
          } else {
            this.overlayService.showToast({ message: errorMessage, showCloseButton: true, type: 'error' });
          }

          this.isLoading = false;
        },
      });
  }

  private handleForgotPassword(): void {
    this.trackForgotPasswordEvent();

    const email = this.userDetailsForm.getRawValue().email;

    this.isLoading = true;
    Helpers.runObservableWithMinDuration(this.accountService.resetPassword(email), 500)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          void this.dismiss().then(() => {
            this.isLoading = false;

            void this.modalService.showAlert({
              title: this.translateService.instant('LOG_IN_MODAL_PAGE.FORGOT_PASSWORD_SUCCESS_TITLE') as string,
              message: this.translateService.instant('LOG_IN_MODAL_PAGE.FORGOT_PASSWORD_SUCCESS_MESSAGE', { email }) as string,
              buttons: [
                {
                  isPrimary: true,
                  text: this.translateService.instant('LOG_IN_MODAL_PAGE.FORGOT_PASSWORD_SUCCESS_BUTTON') as string,
                },
              ],
            });
          });
        },
        error: (error: HttpErrorResponse) => {
          void this.overlayService.hideLoading().then(() => {
            const errorMessage = this.validationService.getErrorMessage(error);
            this.loggerService.info({
              component: 'SignInSignUpModalPage',
              message: "couldn't send password reset request",
              details: { error, messageShownToUser: errorMessage },
            });
            this.overlayService.showToast({ message: errorMessage, showCloseButton: true, type: 'error' });

            this.isLoading = false;
          });
        },
      });
  }

  private getRegisterModelFromForm(): Register {
    const formValue = this.userDetailsForm.getRawValue();

    return {
      email: formValue.email,
      password: formValue.password,
      firstName: formValue.firstName,
      lastName: formValue.lastName,
      phone: {
        countryCode: formValue.phoneCountryCode,
        localNumber: formValue.phoneLocalNumber,
      },
      country: this.settingsService.getCountry().value,
    };
  }

  private getLogInModelFromForm(): LogIn {
    const formValue = this.userDetailsForm.getRawValue();

    return {
      email: formValue.email,
      password: formValue.password,
    };
  }

  private trackSignupEvent(): void {
    void this.analyticsService.trackEvent({ name: this.analyticsConfig.eventName.signUp });

    // Add the property to the user profile as well
    const signUpDateData = {
      [this.analyticsConfig.mixpanelUserPropertyName.signUpDate]: this.dateTimeService.now.toDate(),
    };
    void this.analyticsService.setUserProperties(signUpDateData);
  }

  private trackLoginEvent(): void {
    void this.analyticsService.trackEvent({ name: this.analyticsConfig.eventName.logIn });
  }

  private trackForgotPasswordEvent(): void {
    void this.analyticsService.trackEvent({ name: this.analyticsConfig.eventName.checkoutPageForgotPassword });
  }

  private dismiss(): Promise<boolean> {
    return this.modalService.dismissModal({ id: LogInModalPageIdentifier });
  }
}
